export interface FundID {
    fundingID: number;
}

export interface FundIDAction {
    type: string;
    payload: FundID | null;
}

const FundSelector = (state : FundID | null = null, action: FundIDAction) : FundID | null => {
    switch (action.type) {
        case 'SELECT_FUND':
            return action.payload;
        default:
            return state;
    }
}

export default FundSelector;