import React from "react";
import { useNavigate } from "react-router-dom";

const InsuranceInfo = () => {
    let navigate = useNavigate();

    return (
        <div className="trace-info">
            <div className="trace-info__title" onClick={()=>navigate(-1)}>
                <h3>뒤로가기</h3>
            </div>
            <div className="trace-info__content">
                <div className="trace-info__content__item">
                    <div className="trace-info__content__item__title">
                        <h4>추적번호</h4>
                    </div>
                    <div className="trace-info__content__item__content">
                        <p>1234567890</p>
                    </div>
                </div>
                <div className="trace-info__content__item">
                    <div className="trace-info__content__item__title">
                        <h4>추적번호</h4>
                    </div>
                    <div className="trace-info__content__item__content">
                        <p>1234567890</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InsuranceInfo;