import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";

import dayjs, { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import icoCopy from "../../../images/ico_copy.png";

import { SeperateTrace, MoneyFormat } from "../../../utils/StringFormat";
import { TextField } from "@mui/material";


const Deposit = () => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);

    const [selectedModalDateFrom, setSelectedModalDateFrom] = useState<Dayjs | null>(dayjs().subtract(2, "w"));
    const [selectedModalDateTo, setSelectedModalDateTo] = useState<Dayjs | null>(dayjs());
    const [selectedDateFrom, setSelectedDateFrom] = useState<Dayjs | null>(dayjs().subtract(2, "w"));
    const [selectedDateTo, setSelectedDateTo] = useState<Dayjs | null>(dayjs());

    const [deposit, setDeposit] = useState<any>();

    let arr: Array<any> = [];
    const [depositHistory, setDepositHistory] = useState(arr);

    const [selectedSort, setSelectedSort] = useState("전체");

    const [selectedModalPeriod, setSelectedModalPeriod] = useState("직접설정");
    const [selectedModalOrder, setSelectedModalOrder] = useState("최신순");
    const [selectedPeriod, setSelectedPeriod] = useState("직접설정");
    const [selectedOrder, setSelectedOrder] = useState("최신순");


    let [showModal, setShowModal] = useState<boolean>(false);

    
    const GetDesposit = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/deposit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userID : user.USER_ID
            })
        })
        const data = await res.json();

        setDeposit(data[0]);
        GetDespositHistory(data[0].DE_ID);
    }

    const GetDespositHistory = async (depoistID : string) => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/deposit/history", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                deID : depoistID
            })
        })
        const data = await res.json();
        
        setDepositHistory(data.sort((a:any, b:any) => b.DH_ID - a.DH_ID));
    }

    const SortDepositHistory = (item : any) => {
        return (item.DEPOSIT_STATUS === selectedSort || selectedSort === "전체") && 
                (dayjs(item.CREATE_DT).diff(selectedDateFrom, "d", true) >= 0 && dayjs(item.CREATE_DT).diff(selectedDateTo, "d", true) <= 0)
    }

    const ShowWarningModal = (e : any) => {
        console.log("날짜 선택 실패");
    }


    const SelectPeriod = (period : string) => {
        if(period === "1개월") {
            setSelectedModalPeriod("1개월");

            setSelectedModalDateFrom(dayjs().subtract(1, "M"));
            setSelectedModalDateTo(dayjs());
        } else if(period === "3개월") {
            setSelectedModalPeriod("3개월");

            setSelectedModalDateFrom(dayjs().subtract(3, "M"));
            setSelectedModalDateTo(dayjs());
        } else if(period === "지난달") {
            setSelectedModalPeriod("지난달");

            setSelectedModalDateFrom(dayjs().subtract(1, "M").startOf("M"));
            setSelectedModalDateTo(dayjs().subtract(1, "M").endOf("M"));
        } else if(period === "직접설정") {
            setSelectedModalPeriod("직접설정");
        }
    }

    // 세팅 저장
    const SaveSortSetting = () => {
        setSelectedPeriod(selectedModalPeriod);
        setSelectedDateFrom(selectedModalDateFrom);
        setSelectedDateTo(selectedModalDateTo);
        setSelectedOrder(selectedModalOrder);

        setShowModal(false);
    }

    // 세팅 저장X
    const CloseModal = () => {
        setSelectedModalPeriod(selectedPeriod);
        setSelectedModalDateFrom(selectedDateFrom);
        setSelectedModalDateTo(selectedDateTo);
        setSelectedModalOrder(selectedOrder);

        setShowModal(false);
    }

    const handleCopyClipBoard = async () => {
        try {
          await navigator.clipboard.writeText(document.getElementById("account")?.innerText!);
          alert('클립보드에 링크가 복사되었습니다.');
        } catch (e) {
          alert('복사에 실패하였습니다');
        }
    }

    useEffect(() => {
        GetDesposit();
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>예치금 계좌</h1>
            </header>
            <section>
                <div className="mt-40">
                    <p className="d-flex flex-center color-gray"><span className="mr-5" id="account">{SeperateTrace(deposit?.VIRTUAL_NUMBER)}</span> <a className="d-inline-block"><img src={icoCopy} onClick={handleCopyClipBoard} alt="" style={{ width: "14px" }}/></a></p>
                </div>
                <div className="my-account pd-side mt-40">
                    <dl>
                        <dt>보유예치금</dt>
                        <dd>{MoneyFormat(deposit?.HOLDING_AMOUNT)}원</dd>
                    </dl>
                    <dl>
                        <dt>출금가능금액</dt>
                        <dd>{MoneyFormat(deposit?.AVAIL_AMOUNT)}원</dd>
                    </dl>
                </div>
                <div className="sort-group mt-20 pd-side">
                    <div>
                        <a className={selectedSort === "전체" ? 'active' : ''} onClick={() => setSelectedSort("전체")}>전체</a>
                        <a className={selectedSort === "입금" ? 'active' : ''} onClick={() => setSelectedSort("입금")}>입금</a>
                        <a className={selectedSort === "출금" ? 'active' : ''} onClick={() => setSelectedSort("출금")}>출금</a>
                        <a className={selectedSort === "펀딩" ? 'active' : ''} onClick={() => setSelectedSort("펀딩")}>펀딩</a>
                    </div>
                    <a className="btn-order" onClick={() => setShowModal(true)}><span>오름차순</span></a>
                </div>

                {(selectedOrder === "최신순" ? depositHistory : [...depositHistory].reverse()).filter((item) => SortDepositHistory(item)).map((item: any) => ( 
                   <div className="pd-side pd-tb-15" key={item.DH_ID}>
                        <div className="d-flex space-between">
                            <p className="medium">{item.TITLE}</p>
                            <p className="bold">{item.DEPOSIT_STATUS == "입금" ? "+ " : "- "}{MoneyFormat(item.DIFF)}원</p>
                        </div>
                        <div className="d-flex space-between">
                            <p className="color-gray fs-12">{item.CREATE_DT.substring(0, 10)}</p>
                            <p className="color-gray fs-12">{MoneyFormat(item.AMOUNT)}원</p>
                        </div>
                    </div>
                ))}

                <ReactModal isOpen={showModal} onRequestClose={CloseModal} className="modal" overlayClassName="overlay" ariaHideApp={false}>
                    <div className="background" onClick={CloseModal}></div>
                    <div className="search-history">
                        <div>
                            <p className="search-tit">조회기간</p>
                            <ul>
                                <li><a className={selectedModalPeriod === "1개월" ? 'active' : ''} onClick={() => SelectPeriod("1개월")}>1개월</a></li>
                                <li><a className={selectedModalPeriod === "3개월" ? 'active' : ''} onClick={() => SelectPeriod("3개월")}>3개월</a></li>
                                <li><a className={selectedModalPeriod === "지난달" ? 'active' : ''} onClick={() => SelectPeriod("지난달")}>지난달</a></li>
                                <li><a className={selectedModalPeriod === "직접설정" ? 'active' : ''} onClick={() => SelectPeriod("직접설정")}>직접설정</a></li>
                            </ul>
                            <div className="search-period">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={selectedModalDateFrom}
                                        disabled={selectedModalPeriod !== "직접설정"}
                                        onChange={(e) => e?.diff(selectedModalDateTo, "d", true)! <= 0 ? setSelectedModalDateFrom(e) : ShowWarningModal(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <p>-</p>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={selectedModalDateTo}
                                        disabled={selectedModalPeriod !== "직접설정"}
                                        onChange={(e) => e?.diff(dayjs(), "d", true)! <= 0 && e?.diff(selectedModalDateFrom, "d", true)! >= 0 ? setSelectedModalDateTo(e) : ShowWarningModal(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            
                            
                            <p className="search-tit">거래내역 정렬</p>
                            <ul>
                                <li><a className={selectedModalOrder === "최신순" ? 'active' : ''} onClick={() => setSelectedModalOrder("최신순")}>최신순</a></li>
                                <li><a className={selectedModalOrder === "과거순" ? 'active' : ''} onClick={() => setSelectedModalOrder("과거순")}>과거순</a></li>
                            </ul>
                        </div>
                        
                        <p className="btn-area"><a className="btn btn-pink" onClick={SaveSortSetting}>확인</a></p>
                    </div>
                </ReactModal>


            </section>
        </div>
    )
}

export default Deposit;