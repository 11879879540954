export interface Wishlist {
    WISHLIST_ID: number;
    USER_ID: number;
    FUNDING_ID: number;
}

export interface WishlistAction {
    type: string;
    payload: Wishlist[] | null;
}

const WishlistReducer = (state : Wishlist[] | null = null, action: WishlistAction) : Wishlist[] | null => {
    switch (action.type) {
        case 'SET_WISHLIST':
            return action.payload;
        default:
            return state;
    }
}

export default WishlistReducer;