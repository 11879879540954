import React, { FC, useCallback, useEffect, useState } from 'react';
import icoKeep from '../../../images/ico_keep.png';
import icoKeepon from '../../../images/ico_keepon.png';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetThumbnail } from '../../../utils/ImageHandler';
import defaultImage from '../../../images/default.png';
import { addComma } from '../../../utils/StringFormat';

export interface PaperPreviewProps {
  FUNDING_ID: number;
  FARM_ID: number;
  PIG_NM: string;
  PIG_ID: number;
  PIG_GENE: number;
  PIG_AGE: number;
  FUND_RATE: number;
  TARGET_AMOUNT: number;
  CURRENT_AMOUNT: number;
  MIN_AMOUNT: number;
  BIDDING_DATE: string;
  START_DT: string;
  END_DT: string;
  IMG_ID: number;

  TRACE_ID: number;
  BIDDING_AMOUNT: number;
}

const PaperPreview: FC<PaperPreviewProps> = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const wishlist = useSelector((state: any) => state.wishlist);
  const [imagePath, setImagePath] = useState<string>('');

  const handleClick = () => {
    dispatch({
      type: 'SELECT_FUND',
      payload: {
        fundingID: props.FUNDING_ID,
      },
    });
    history('/fund/detail/fund');
  };

  useEffect(() => {
    GetThumbnail('TB_PIG', props.PIG_ID).then((res) => setImagePath(res));
  }, []);

  return (
    <div>
      <div className='list-img'>
        <img src={imagePath ? imagePath : defaultImage} alt='' />
      </div>
      <div>
        <div className='list-title'>
          <p className='fs-15 bold'>{props.PIG_NM}</p>
        </div>
        <div className='funding-info'>
          <p>
            <span className='color-gray'>이력번호 </span>
            {props.TRACE_ID}
          </p>
        </div>
        <div className='d-flex space-between'>
          <p className='fs-14 bold'>{props.BIDDING_DATE.substring(0, 10)}</p>
          <p className='fs-14'>
            <span className='color-red'>{addComma(props.BIDDING_AMOUNT)}</span>원
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaperPreview;
