import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconMy01 from "../../images/ico_my01.png"
import iconMy02 from "../../images/ico_my02.png"
import iconMy03 from "../../images/ico_my03.png"
import MyOrderPreview from "./MyOrderPreview";
import { MyReviewOrderPreview, MyReviewPreview } from "./MyReviewPreview";

const $ = require("jquery");

const MyReview = () => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);

    let arr: Array<any> = [];
    let sortedArr: Array<any> = [];

    const [myOrders, setMyOrders] = useState(arr);
    const [sortedOrders, setSortedOrders] = useState(sortedArr);

    const [selectedOrder, setSelectedOrder] = useState("베스트순");

    const GetMyOrder = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/myshopping/order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userID : user.USER_ID
            })
        })
        const data = await res.json();

        setMyOrders(data);
    }

    useEffect(() => {
        $(function () {
            $("#condition-tabs").tabs();
        });

        GetMyOrder();
    }, [])

    const HandleSelectedOrder = (order : any) => {
        setSelectedOrder(order);

        const sortedOrders = [...myOrders];

        if(order === "베스트순") {
            sortedOrders.sort((a:any, b:any) => b.RATE - a.RATE);
        }
        else if(order === "최신순") {
            sortedOrders.sort((a:any, b:any) => new Date(b.UPLOAD_DT).getTime() - new Date(a.UPLOAD_DT).getTime());
        }
        
        setMyOrders(sortedOrders);
    }

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>후기</h1>
            </header>
            <section className="full-sub">
                <div id="condition-tabs" className="tabs condition-tabs">
                <ul>
                    <li><a href="#condition-1">작성 가능한 후기</a></li>
                    <li><a href="#condition-2">작성한 후기</a></li>
                </ul>
                <div id="condition-1">

                    {myOrders.filter((order) => order.REVIEW_ID === null).map((item: any) => (
                        <MyReviewOrderPreview {...item} key={item.ORDER_DETAIL_ID} />
                    ))}

                </div>
                <div id="condition-2">
                    <div className="scroll-tab mt-10">
                        <div id="fixedMenu" className="fixed-menu filter-menu bd-top">
                            <ul>
                                <li><a className={selectedOrder === "베스트순" ? "active" : ""} onClick={() => HandleSelectedOrder("베스트순")}>베스트순</a></li>
                                <li><a className={selectedOrder === "최신순" ? "active" : ""} onClick={() => HandleSelectedOrder("최신순")}>최신순</a></li>
                                <li><a className={selectedOrder === "사진리뷰" ? "active" : ""} onClick={() => HandleSelectedOrder("사진리뷰")}>사진리뷰</a></li>
                            </ul>
                        </div>

                        <div className="farm-detail bd-0">
                            <div className="review-wrap review">
                                {myOrders.filter((order) => order.REVIEW_ID !== null).sort().map((item: any) => (
                                    <MyReviewPreview {...item} key={item.ORDER_DETAIL_ID} show={selectedOrder === "사진리뷰"} />
                                ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>
    )
}

export default MyReview;