import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const CertifyAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector((state: any) => state.user);

    const inputRef = useRef<HTMLInputElement>(null);

    const [buttonColor, setButtonColor] = useState<string>("btn btn-gray");

    let [showModal, setShowModal] = useState<boolean>(false);

    const HandleChange = () => {
        if (inputRef.current?.value === "") {
            setButtonColor("btn btn-gray");
        } else {
            setButtonColor("btn btn-pink");
        }
    };

    const HandleSubmit = async () => {
        if (inputRef.current?.value === "123") {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + "/withdraw/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "userID": user.USER_ID,
                    "bankName": location.state.bankName,
                    "bankNumber": location.state.accountNumber,
                }),
            });

            const data = await response.json();

            navigate("/mybank/withdrawAccount/Complete");
        }
        else {
            setShowModal(true);
            setTimeout(() => { setShowModal(false) }, 2000);
        }
    }

    useEffect(() => {

    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
            </header>
            <section>
                <div className="pd-side mt-15">
                    <p className="fs-20 bold">{location.state.bankName}은행 입금내역을 확인하고 뒤 3자리 숫자를 알려주세요</p>
                    <div className="mt-30">
                        <input type="number" placeholder="-없이 입력해주세요" ref={inputRef} onChange={HandleChange} />
                    </div>
                    <p className="mt-30"><a className={buttonColor} onClick={HandleSubmit}>확인</a></p>
                    <p className="mt-30 medium"><a onClick={() => navigate(-3)}>다른계좌로 시도하기</a></p>
                </div>
            </section>

            <div className="balance-alert" style={{display: showModal ? "block" : "none"}}>
                인증번호가 일치하지 않아요
            </div>
            
        </div>
    )
}

export default CertifyAccount;