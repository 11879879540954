import { Swiper, SwiperSlide } from 'swiper/react';
import arrowRight from '../../../images/arrow_right.png';
import { Navigation, Pagination } from 'swiper';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import styles from './Banner.module.css';
import { kakaoLogout } from '../../../apis/kakao';
import { useEffect, useState } from 'react';

export default function Banner() {
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [noticeList, setNoticeList] = useState(initialValue);
  const [alarm, setAlarm] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    kakaoLogout();
    removeCookie('user');
  };

  const handleLogin = () => {
    navigate('/');
  };

  const handleMoveAlarm = () => {
    navigate('/alarm', { state: { noticeList } });
  };

  const getNoticeList = async () => {
    const response = await fetch(`${server_url}/notice/${cookies.user.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    data.some((notice: any) => notice.IS_READ === 1) ? setAlarm(true) : setAlarm(false);
    setNoticeList(data);
  };

  useEffect(() => {
    getNoticeList();
  }, []);

  return (
    <Swiper
      className='headerSwiper'
      modules={[Navigation, Pagination]}
      pagination={{
        el: '.headerSwiper .swiper-pagination',
        type: 'fraction',
      }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
    >
      <SwiperSlide className='swiper-slide'>
        <div className='d-flex flex-column'>
          <div className={styles.top}>
            <button className={styles.notice} onClick={handleMoveAlarm}>
              {alarm ? <p></p> : ''}
              <img src={icoNotice} alt='알림' />
            </button>
            {cookies.user ? (
              <button className={styles.logout} onClick={handleLogout}>
                로그아웃
              </button>
            ) : (
              <button className={styles.login} onClick={handleLogin}>
                로그인
              </button>
            )}
          </div>
          <p className='fs-20 bold'>
            {cookies.user?.nickname || '사용자'}님, 처음
            <br />
            방문 하셨나요?
          </p>
          <p className='fs-15 pt-20 pb-20' onClick={() => navigate('/guide')}>
            <span className='d-inline-block'>피그팜 첫 이용가이드</span>
            <span className='d-inline-block'>
              <img src={arrowRight} alt='' style={{ width: '14px' }} />
            </span>
          </p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

const server_url = process.env.REACT_APP_SERVER_URL;
const icoNotice = '/images/ico_notice.png';
const initialValue = [{ ALARM_ID: 0, USER_ID: 0, ALARM_TITLE: '', ALARM_CONTENT: '', ALARM_DATE: '', IS_READ: 0 }];
