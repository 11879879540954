import React, { useEffect, useState } from 'react';
import { CartType } from '../../store/CartReducer';
import { GetThumbnail } from '../../utils/ImageHandler';
import imgList from '../../images/img_list.png';
import defaultImage from '../../images/default.png';
import { addComma } from '../../utils/StringFormat';

export default function PaymentCartItem(props: CartType) {
  const [imagePath, setImagePath] = useState<string>('');

  useEffect(() => {
    GetThumbnail('TB_ORDER_PRODUCT', props.PRODUCT_ID).then((res) => setImagePath(res));
  }, []);

  return (
    <div className='product-list'>
      <div className='product-title'>
        <p className='bold'>{props.FARM_NM}</p>
        <p>{addComma(props.DELIVERY_PRICE)}</p>
      </div>
      <div className='product-box'>
        <div className='product-img'>
          <img src={imagePath ? imagePath : defaultImage} alt='' />
        </div>
        <div>
          <p className='product-name'>{props.PRODUCT_NM}</p>
          <p className='order-price'>
            {addComma(props.DISCOUNT_PRICE * props.COUNT)}원<span>｜ 수량 {props.COUNT}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
