import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Delete = () => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    const [buttonColor, setButtonColor] = useState<string>("btn btn-gray mt-40");

    const HandleSubmit = async () => {
        if (buttonColor === "btn btn-pink mt-40") {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + "/mybank/setting/delete", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "userID": user.USER_ID,
                }),
            });

            await response.json();

            removeCookie("user");
            navigate("/");
        }
    };

    const HandleChange = () => {
        (document.getElementById("deleteCheck") as HTMLInputElement).checked ? setButtonColor("btn btn-pink mt-40") : setButtonColor("btn btn-gray mt-40");
    };

    useEffect(() => {

    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>탈퇴하기</h1>
            </header>
            <section>
            <div className="pd-side pb-20">
            <div className="pb-20">
                <p className="fs-15 bold">이용계약의 종료</p>
            </div>
            <div className="gray-box pd-15">
                회원은 언제든지 해지 의사를 통보함으로써 이용계약을 해지할 
                수 있습니다. 다만, 회원은 해지 의사를 통지하기 전에 거래 중
                인 상품의 절차를 완료, 철회, 취소 등을 통하여 종료하여야 하
                며, 보유한 예치금을 모두 인출하여야 합니다.

                이용계약은 회원의 해지 의사가 회사에 도달한 때에 종료됩니
                다.
            </div>
            <div className="gray-box pd-15 mt-20">
                회원의 의사로 이용계약을 해지한 수 회원 재가입, 임의 해지 등
                을 반복적으로 행하여 회사가 제공하는 각종 이벤트 혜택 등 
                제적 이익을 편법으로 수취하거나,
                이 과정에서 명의를 무단으로 사용하는 등의 편법·불법행위가 
                이루어지는 것을 차단하고자, 회사는 이용계약 해지회원이 이용
                계약 해지일로부터 일정기간(1개월 이내) 지난 경우에 한하여 
                재가입이 가능하도록 제한할 수 있습니다.
                회사는 회원이 탈퇴한 후 1년 동안 해당 회원의 아이디, 성명, 탈
                퇴 일자, 탈퇴 사유 등 개인정보를 보관할 수 있습니다.
                이용 계약이 해지되는 경우, 이와 관련하여 발생한 손해는 이용
                계약이 종료된 해당 회원이 책임을 부담하여야 하고, 회사는 일
                체의 책임을 지지 않습니다.
            </div>
            
            <div className="form-checkbox cart-checkbox mb-0 d-flex align-center mt-40">
                <input type="checkbox" id="deleteCheck" className="check" onChange={HandleChange}/>
                <label htmlFor="deleteCheck" className="mr-10"></label>
                <p>안내사항을 모두 확인하였습니다.</p>
            </div>
            <p><a className={buttonColor} onClick={HandleSubmit}>탈퇴 신청하기</a></p>
        </div>
            </section>
        </div>
    )
}

export default Delete;