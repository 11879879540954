export default function FarmInfo(props: any) {
  const career: number = new Date().getFullYear() - Number(props.ESTABLISHED_YEAR);

  return (
    <div className='farm-detail'>
      <h2 className='mb-5'>{props.FARM_NM}</h2>
      <p className='color-gray mb-20'>{props.ADDR1}</p>
      <div>
        <dl className='mb-20'>
          <dt className='color-gray'>경력</dt>
          <dd>{career}년</dd>
        </dl>
        <dl className='mb-20'>
          <dt className='color-gray'>전년생산등급</dt>
          <dd>{props.PREV_YEAR_GRADE}</dd>
        </dl>
        <dl className='mb-20'>
          <dt className='color-gray'>사육중 자료</dt>
          <dd>사진 제공</dd>
        </dl>
        <dl className='mb-20'>
          <dt className='color-gray'>사료</dt>
          <dd>{props.FEED}</dd>
        </dl>
        <dl className='mb-20'></dl>
      </div>
    </div>
  );
}
