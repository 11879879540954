import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetThumbnail } from '../../../../utils/ImageHandler';
import { useCookies } from 'react-cookie';
import { RingVolume } from '@mui/icons-material';
import styles from './FundPreview.module.css';
import { handleFundRate } from '../../../../utils/PercentCalculator';
import { addComma } from '../../../../utils/StringFormat';

interface IWishList {
  WISHLIST_ID: number;
  USER_ID: number;
  FUNDING_ID: number;
}

const ico_keepon = '/images/ico_keepon.png';
const ico_keep = '/images/ico_keep.png';

const server_url = process.env.REACT_APP_SERVER_URL;

export default function FundPreview({ fund, wishList }: any) {
  const [cookies] = useCookies(['user']);
  const [imagePath, setImagePath] = useState('');
  const [dibs, setDibs] = useState(false);

  const navigate = useNavigate();

  const handleFundDetail = () => {
    navigate('/fund/detail/fund', { state: { id: fund.ID, dibs } });
  };

  const handleDibs = async () => {
    try {
      const response = await fetch(`${server_url}/dibs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userID: cookies.user.id,
          fundingID: fund.ID,
          dibs: dibs,
        }),
      });
      const data = await response.json();

      if (data) setDibs(!dibs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetThumbnail('TB_PIG', fund.PIG_ID).then(setImagePath);
    wishList.some((item: IWishList) => item.FUNDING_ID === fund.ID) ? setDibs(true) : setDibs(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className='list-img'>
        <img
          className={styles.thumbnail}
          src={imagePath ? imagePath : '/images/default.png'}
          alt=''
          onClick={handleFundDetail}
        />
        <button className={styles.img_btn} onClick={handleDibs}>
          <img src={dibs ? ico_keepon : ico_keep} alt='찜' />
        </button>
      </div>
      <div>
        <p className='fs-15 bold'>{fund.PIG_NM}</p>
        <div className={styles.funding_info}>
          <p>
            <span className='color-gray'>최소금액 </span>
            <span>{addComma(fund.MIN_AMOUNT)}원</span>
          </p>
          <p>
            <span className='color-gray'>유전지수 </span>
            <span>{fund.PIG_GENE}</span>
          </p>
          <p>
            <span className='color-gray'>나이 </span>
            <span>{fund.PIG_AGE}개월</span>
          </p>
        </div>
        <div className={styles.progress}>
          <div
            className={styles.progress_bar}
            style={{ width: `${handleFundRate(fund.TARGET_AMOUNT, fund.CURRENT_AMOUNT)}%` }}
          />
        </div>
        <div className='d-flex space-between'>
          <p className='fs-14 bold'>
            <strong className='color-red'>{handleFundRate(fund.TARGET_AMOUNT, fund.CURRENT_AMOUNT).toFixed(1)}%</strong>
            달성
          </p>
          <p className='fs-14'>
            <span className='color-red'>97C</span> 남음
          </p>
        </div>
      </div>
    </div>
  );
}
