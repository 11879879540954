import ChatPage from '../components/monitor/ChatPage';
import UnityViewer from '../components/monitor/UnityViewer';
import { io } from 'socket.io-client';

const chat_url = process.env.REACT_APP_SERVER_CHAT_URL;
const socket = io(`${chat_url}`, { path: '/chat' });

interface MonitorProps {
  setActiveLink: (link: string) => void;
  setIsActive: (active: boolean) => void;
}

const Monitor = (props: MonitorProps) => {
  return <UnityViewer>{/* <ChatPage socket={socket} /> */}</UnityViewer>;
};

export default Monitor;
