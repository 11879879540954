import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

interface NavProps {
    activeLink: string;
    isActive: boolean;
}

const Nav: FC<NavProps> = (props) => {

    return (
        <>
        {props.isActive &&
            <nav>
                <Link to={`/home`}
                    className={props.activeLink === "menu1" ? "menu1 active" : "menu1"}>
                    홈</Link>
                <Link to={`/fund`}
                    className={props.activeLink === "menu2" ? "menu2 active" : "menu2"}>
                    펀딩</Link>
                <Link to={`/mybank`}
                    className={props.activeLink === "menu4" ? "menu4 active" : "menu4"}>
                    마이뱅크</Link>
                <Link to={`/unity`}
                    className={props.activeLink === "menu5" ? "menu5 active" : "menu5"}>
                    모니터링</Link>
            </nav>
        }
        </>
    )
}

export default Nav;