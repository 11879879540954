import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'jquery-ui/ui/widgets/tabs.js';
import icoCopy from '../../images/ico_copy.png';
import { MoneyFormat, SeperateTrace } from '../../utils/StringFormat';
import styles from './Mybank.module.css';
import { useCookies } from 'react-cookie';

const $ = require('jquery');

interface FundProps {
  setActiveLink: (link: string) => void;
  setIsActive: (active: boolean) => void;
}

const server_url = process.env.REACT_APP_SERVER_URL;

export default function Mybank(props: FundProps) {
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [deposit, setDeposit] = useState({ VIRTUAL_NUMBER: '0', AVAIL_AMOUNT: '0', BANK_NM: '국민', BANK_NUMBER: '0' });

  const { id, kakao_id, nickname } = cookies.user;
  const navigate = useNavigate();

  // const [userInfo, setUserInfo] = useState<any>();

  const CheckPIN = () => {
    // if (userInfo.PIN === null) {
    //   navigate('/mybank/withdrawAccount/pin/SetPIN');
    // } else {
    //   navigate('/mybank/withdrawAccount/pin/CheckPIN');
    // }
  };

  const getDesposit = async () => {
    const res = await fetch(`${server_url}/deposit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userID: id,
      }),
    });
    const data = await res.json();

    setDeposit(data[0]);
  };

  const handleCopyClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(document.getElementById('account')?.innerText!);
      alert('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      alert('복사에 실패하였습니다');
    }
  };

  useEffect(() => {
    props.setActiveLink('menu4');
    props.setIsActive(true);
    $(function () {
      $('#sub-tabs').tabs();
    });

    getDesposit();

    return () => {
      props.setIsActive(false);
    };
  }, []);

  return (
    <section className='bg-my'>
      <header className='sub'>
        <h1 className='fs-20'>마이뱅크</h1>
        <button onClick={() => navigate('/mybank/setting')} className='btn-setting right'>
          <span>관리</span>
        </button>
      </header>
      <main className='h-100n'>
        <div className='main-wrap'>
          <div className='pd-side'>
            <div id='sub-tabs' className='my-menu'>
              <ul>
                <li onClick={() => navigate('/mybank/notice')}>공지사항</li>
                <li onClick={() => navigate('/mybank/inquiry')}>문의사항</li>
              </ul>
            </div>

            <div className='my-box'>
              <div className={styles.user_info}>
                <p className='bold mr-15'>{nickname}</p>
                <p className={styles.birth}>{'1999.11.26'}</p>
              </div>
              <p>
                <button onClick={() => navigate('/mybank/EditProfile')} className='btn-edit'>
                  <span>수정</span>
                </button>
              </p>
            </div>

            <div className='account-box'>
              <div className='d-flex'>
                <div className='account-img mr-15'></div>
                <div className='grow-3'>
                  <div className='d-flex space-between align-center'>
                    <div>
                      <div>
                        <p className='medium'>예치금 계좌</p>
                        <div className={styles.account_num} id='account'>
                          <span>{SeperateTrace(deposit.VIRTUAL_NUMBER)}</span>
                          <button className='d-inline-block' onClick={handleCopyClipBoard}>
                            <img className={styles.clipboard_img} src={icoCopy} alt='클립보드' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button className='btn-dot' onClick={() => navigate('/mybank/Deposit')}>
                        <span>관리</span>
                      </button>
                    </div>
                  </div>
                  <div className='d-flex space-between mt-20'>
                    <p className='fs-20 bold'>{MoneyFormat(deposit.AVAIL_AMOUNT)}원</p>
                    <button className='btn btn-small btn-rgray medium' onClick={CheckPIN}>
                      출금
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='account-box mt-5'>
              <div className='d-flex align-center'>
                <div className='account-img mr-15'></div>
                <div className='d-flex space-between align-center grow-3'>
                  <p className='medium'>
                    출금계좌를 등록해주세요
                    {/* {deposit.BANK_NM ? deposit?.BANK_NM : '출금계좌를 등록해주세요'} */}
                    {/* {deposit?.BANK_NUMBER ? deposit?.BANK_NUMBER : ''} */}
                  </p>
                  <p>
                    <button className='btn-arrow-right' onClick={() => navigate('/mybank/withdrawAccount/SelectBank')}>
                      <span>등록</span>
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.history_list}>
              <button onClick={() => navigate('/mybank/papers/FundReceipt')}>소유권증서</button>
              <button onClick={() => navigate('/mybank/papers/CancelHistory')}>중도해지내역</button>
              <button onClick={() => navigate('/mybank/papers/AccidentHistory')}>사고내역</button>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}
