export default function Footer() {
  return (
    <footer style={{ paddingTop: '20px', paddingBottom: '80px' }}>
      <div className='footer-wrap' style={{ margin: '20px' }}>
        <div className='footer-info'>
          <p>상호명: 제트플로우</p>
          <p>대표자명: 김유정</p>
          <p>사업자등록번호: 559-81-02173</p>
          <p>통신판매업신고번호: 2023-안양동안-0647호</p>
          <p>사업장주소: 서울특별시 중구 퇴계로36길 2 동국대학교 충무로 영상센터 본관 910호</p>
          <p>전화번호: 010-8735-8414 이메일: hj.kim@zflow.co.kr </p>
          <p>© 2023 ZFLOW. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
