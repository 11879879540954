import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import 'jquery-ui/ui/widgets/tabs.js';

import FAQPreview from "./FAQPreview";

const $ = require("jquery");

const FAQ = () => {
    const navigate = useNavigate();

    let arr: Array<any> = [];
    const [FAQs, setFAQs] = useState(arr);

    const [selectedCategory, setSelectedCategory] = useState("");

    const GetFAQs = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/mybank/faq", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();

        setFAQs(data);
    }

    useEffect(() => {
        GetFAQs();
        setSelectedCategory("전체");
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>자주 묻는 질문</h1>
            </header>
            <section>
                <div className="pd-side">
                    <ul className="q-list">
                        <li onClick={() => setSelectedCategory("전체")}>
                            <a className={selectedCategory === "전체" ? 'active' : ''}>전체</a>
                        </li>
                        <li onClick={() => setSelectedCategory("예치금")}>
                            <a className={selectedCategory === "예치금" ? 'active' : ''}>예치금</a>
                        </li>
                        <li onClick={() => setSelectedCategory("계정")}>
                            <a className={selectedCategory === "계정" ? 'active' : ''}>계정</a>
                        </li>
                        <li onClick={() => setSelectedCategory("펀딩")}>
                            <a className={selectedCategory === "펀딩" ? 'active' : ''}>펀딩</a>
                        </li>
                        <li onClick={() => setSelectedCategory("소비자 보호")}>
                            <a className={selectedCategory === "소비자 보호" ? 'active' : ''}>소비자 보호</a>
                        </li>
                        <li onClick={() => setSelectedCategory("용어")}>
                            <a className={selectedCategory === "용어" ? 'active' : ''}>용어</a>
                        </li>
                        <li onClick={() => setSelectedCategory("입점")}>
                            <a className={selectedCategory === "입점" ? 'active' : ''}>입점</a>
                        </li>
                        <li onClick={() => setSelectedCategory("기타")}>
                            <a className={selectedCategory === "기타" ? 'active' : ''}>기타</a>
                        </li>
                    </ul>
                </div>



                <div>
                    <div className="bd-bottom mt-20">
                        <h2 className="pd-side pd-tb-10 fs-14">{selectedCategory}</h2>
                    </div>

                    {FAQs.filter((item) => item.FAQ_CATEGORY === selectedCategory || selectedCategory === "전체").map((item: any) => (
                        <FAQPreview {...item} key={item.FAQ_ID} />
                    ))}

                    {/* <div>
                        <div className="d-flex space-between align-center pd-22">
                            <div>
                                <p className="medium">Q. 세금구조는 어떻게 되나요?</p>
                            </div>
                            <a   className="btn-detail"><img src={arrowUp} alt="" /></a>
                        </div>
                        <div className="bg-rgray pd-side pd-tb-15 fs-12">
                            <p>내용내용내용내용<br />내용내용내용내용<br />내용내용내용내용</p>
                        </div>
                    </div>

                    <div className="bd-bottom">
                        <div className="d-flex space-between align-center pd-22">
                            <div>
                                <p className="medium">Q. 세금구조는 어떻게 되나요?</p>
                            </div>
                            <a   className="btn-detail"><img src={arrowDown} alt="" /></a>
                        </div>
                    </div>

                    <div>
                        <div className="d-flex space-between align-center pd-22">
                            <div>
                                <p className="medium">Q. 세금구조는 어떻게 되나요?</p>
                            </div>
                            <a   className="btn-detail"><img src={arrowDown} alt="" /></a>
                        </div>
                    </div> */}
                </div>
            </section>
        </div>
    )
}

export default FAQ;