import { useState, useEffect } from 'react';
import { addComma } from '../../../utils/StringFormat';

const server_url = process.env.REACT_APP_SERVER_URL;

export default function PigfarmNow() {
  const [totalBids, setTotalBids] = useState(0);
  const [totalPigs, setTotalPigs] = useState(0);

  const getTotalBids = async () => {
    try {
      const response = await fetch(`${server_url}/bidding/count`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      setTotalBids(data[0].TOTAL);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalPigs = async () => {
    try {
      const response = await fetch(`${server_url}/pigCount`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      setTotalPigs(data[0].TOTAL);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTotalBids();
    getTotalPigs();
  }, []);

  return (
    <div>
      <div className='title-wrap'>
        <h2>피그팜 현황</h2>
      </div>
      <div className='box-wrap'>
        <div className='main-box'>
          <dl>
            <dt>펀딩 참여수</dt>
            <dd>
              <p className='color-red bold fs-23'>{addComma(totalBids)}</p>
            </dd>
          </dl>
          <dl>
            <dt>보유 아기돼지수</dt>
            <dd>
              <p className='color-red bold fs-23'>{addComma(totalPigs)}</p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}
