import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Unity, useUnityContext } from 'react-unity-webgl';
import home from '../../images/menu1.png';
import '../../css/monitor/Unity.css';
import CameraViewer from './CameraViewer';
import Speaker from './Speaker';

export default function UnityViewer({ children }: any) {
  const [popup, setPopup] = useState<boolean>(false);
  const [farmName, setFarmName] = useState<string>('');
  const [farmID, setFarmID] = useState<number>(1);
  const [useSpeaker, setUseSpeaker] = useState<boolean>(false);
  const [popSpeaker, setPopSpeaker] = useState<boolean>(false);

  const navigate = useNavigate();
  const { unityProvider, unload, loadingProgression, isLoaded, sendMessage, addEventListener, removeEventListener } =
    useUnityContext({
      loaderUrl: '/Build/build.loader.js',
      dataUrl: '/Build/build.data',
      frameworkUrl: '/Build/build.framework.js',
      codeUrl: '/Build/build.wasm',
    });

  async function GoBack() {
    await unload();
    navigate(-1);
  }

  const loadingPercentage = Math.round(loadingProgression * 100);

  const handleUnity = useCallback((farmName: string, farmID: number) => {
    setFarmName(farmName);
    setFarmID(farmID);
    setPopup(true);
    setUseSpeaker(false);
  }, []);

  const handleMoveToFarm = (farmID: number, popSpeaker: boolean) => {
    console.log(farmID);
    sendMessage('Game Manager', 'ChangeSceneByReact', farmID);
    setPopup(false);

    if (popSpeaker === true) {
      setUseSpeaker(true);
    } else {
      setUseSpeaker(false);
    }
  };

  useEffect(() => {
    addEventListener('OpenViewer', handleUnity);
    addEventListener('RefreshViewer', () => {
      setUseSpeaker(false);
      setPopSpeaker(false);
    });
    return () => {
      removeEventListener('OpenViewer', handleUnity);
    };
  }, [addEventListener, removeEventListener, handleUnity]);

  return (
    <div style={{ height: '100vh', width: '100%' }} className='container'>
      <img src={home} alt='home button' onClick={GoBack} className='unity-back' />
      {isLoaded === false && (
        <div className='loading-overlay'>
          <p>Loading...({loadingPercentage}%)</p>
        </div>
      )}
      {children}
      {useSpeaker && <Speaker useSpeaker={useSpeaker} />}
      <Unity unityProvider={unityProvider} className='unity' />
      {/* <CameraViewer
        active={popup}
        setActive={setPopup}
        farmName={farmName}
        farmID={farmID}
        handleMoveToFarm={handleMoveToFarm}
        useSpeaker={useSpeaker}
        setUseSpeakr={setUseSpeaker}
        popSpeaker={popSpeaker}
        setPopSpeakr={setPopSpeaker}
        setFarmID={setFarmID}
      /> */}
    </div>
  );
}
