export interface CartType {
    SHOPPING_CART_ID: number;
    PRODUCT_ID: number;
    PRODUCT_NM: string;
    PRICE: number;
    DISCOUNT_PRICE: number;
    DELIVERY_PRICE: number;
    COUNT: number;
    FARM_NM: string;
  }
  
  export interface CartState {
    items: CartType[];
  }
  
  // Define your action types
  enum CartActionTypes {
    ADD_ITEM = 'ADD_ITEM',
    REMOVE_ITEM = 'REMOVE_ITEM',
    INIT_CART = 'INIT_CART',
  }
  
  // Define your action creators
  interface AddItemAction {
    type: CartActionTypes.ADD_ITEM;
    payload: CartType;
  }
  
  interface RemoveItemAction {
    type: CartActionTypes.REMOVE_ITEM;
    payload: number;
  }

  interface InitCartAction {
    type: CartActionTypes.INIT_CART;
    payload: CartType[];
  }
  
  export const addItem = (item: CartType): AddItemAction => ({
    type: CartActionTypes.ADD_ITEM,
    payload: item,
  });
  
  export const removeItem = (id: number): RemoveItemAction => ({
    type: CartActionTypes.REMOVE_ITEM,
    payload: id,
  });

  export const initCart = (cart: CartType[]): InitCartAction => ({
    type: CartActionTypes.INIT_CART,
    payload: cart,
  });
  
  // Define your initial state
  const initialState: CartState = {
    items: [],
  };
  
  // Define your reducer
  const CartReducer = (state: CartState = initialState, action: AddItemAction | RemoveItemAction | InitCartAction): CartState => {
    switch (action.type) {
      case CartActionTypes.ADD_ITEM:
        return {
          ...state,
          items: [...state.items, action.payload],
        };
      case CartActionTypes.REMOVE_ITEM:
        return {
          ...state,
          items: state.items.filter((item) => item.SHOPPING_CART_ID !== action.payload),
        };
      case CartActionTypes.INIT_CART:
        return {
          ...state,
          items: action.payload,
        };
      default:
        return state;
    }
  };

  export default CartReducer;