import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PigPrice = () => {
    const navigate = useNavigate();
    const [buyPrice, setBuyPrice] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [buyDiff, setBuyDiff] = useState(0);
    const [sellDiff, setSellDiff] = useState(0);
    const [buyPercent, setBuyPercent] = useState(0);
    const [sellPercent, setSellPercent] = useState(0);

    const GetPrices = async (trade: string, sex: string, date: string) => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/market/${trade}/${sex}/${date}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        return data;
    }

    useEffect(() => {
        let today = '2023-02-09T00:00:00.000Z';

        GetPrices('BUY', "암", today).then((data) => {
            setBuyPrice(data[0].BUY_PRICE);
            setBuyDiff(data[0].BUY_PRICE - data[1].BUY_PRICE);
            setBuyPercent((data[0].BUY_PRICE - data[1].BUY_PRICE) / data[1].BUY_PRICE * 100);
        });

        GetPrices('SELL', "암", today).then((data) => {
            setSellPrice(data[0].SELL_PRICE);
            setSellDiff(data[0].SELL_PRICE - data[1].SELL_PRICE);
            setSellPercent((data[0].SELL_PRICE - data[1].SELL_PRICE) / data[1].SELL_PRICE * 100);
        });

    }, []);

    return (
        <div>
            <div className="title-wrap">
                <h2>평균 경매 시세 <span>이번주</span></h2>
                <a className="btn-more" onClick={()=>navigate('/market')}>자세히</a>
            </div>
            <div className="box-wrap">
                <div className="main-box">
                    <dl>
                        <dt className="fs-16">살 때</dt>
                        <dd>
                            <p className="border">아기 돼지</p>
                            <p className="fs-21 bold">{buyPrice}만원</p>
                            <p className={buyDiff > 0 ? "fs-12 color-red" : "fs-12 color-blue"}>
                                {buyDiff > 0 ? "▲" : "▼"} {buyDiff}만원 ({buyPercent.toFixed(1)}%)
                            </p>
                        </dd>
                    </dl>
                    <dl>
                        <dt className="fs-16">팔 때</dt>
                        <dd>
                            <p className="border">큰 돼지</p>
                            <p className="fs-21 bold">{sellPrice}만원</p>
                            <p className={sellDiff > 0 ? "fs-12 color-red" : "fs-12 color-blue"}>
                                {sellDiff > 0 ? "▲" : "▼"} {sellDiff}만원 ({sellPercent.toFixed(1)}%)
                            </p>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    )
};

export default PigPrice;