import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import StorePreview from '../previews/StorePreview';
import { useNavigate } from 'react-router-dom';

const server_url = process.env.REACT_APP_SERVER_URL;

export default function StorePromote() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const getProducts = async () => {
    try {
      const response = await fetch(`${server_url}/shopInit`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      setProducts(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <div className='title-wrap'>
        <h2>
          스토어 추천 제품 <span>농장에서 키운 돼지들로만 제작한 신선한 상품들</span>
        </h2>
        <span className='btn-more' onClick={() => navigate('/shop')}>
          더보기
        </span>
      </div>
      <div className='slide-wrap'>
        <Swiper
          className='mySwiper'
          modules={[Pagination]}
          slidesPerView={'auto'}
          spaceBetween={10}
          pagination={{
            el: '.mySwiper .swiper-pagination',
            clickable: true,
          }}
        >
          {products.map((product: any) => (
            <SwiperSlide className='swiper-slide' key={product.PRODUCT_ID}>
              <StorePreview {...product} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
