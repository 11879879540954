import React, { useState, useEffect,  } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import ReactModal from "react-modal";

const StandBy = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector((state: any) => state.user);
    const theme = createTheme({
        palette: {
            primary: {
            main: '#e76b84',
            },
        },
    });

    useEffect(() => {
        setTimeout(()=>{ navigate("/mybank/withdrawAccount/CertifyAccount", {state: {bankName: location.state.bankName, accountNumber: location.state.accountNumber}}) }, 3000);
    }, [])

    return (
        <div>
            <header className="sub">
                {/* <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a> */}
            </header>
            <section>
                <div className="pd-side mt-15">
                    <p className="fs-20 bold">계좌 인증을 위해 1원을 보내볼게요</p>
                    <div className="mt-20 d-flex align-center">
                        <p className="account-img mr-15"><img src="/images/" alt=""/></p>
                        <p className="medium">{location.state.bankName} {location.state.accountNumber}</p>
                    </div>
                </div>
            </section>

            <div className="pop-loading">
                <ThemeProvider theme={theme}>
                    <CircularProgress color="primary" />
                </ThemeProvider>
            </div>
            
        </div>
    )
}

export default StandBy;