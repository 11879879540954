import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowRightBK from "../../../../images/arrow_right_bk.png";

const Term = () => {
    const navigate = useNavigate();

    useEffect(() => {
       
    }, [])

    return (
        <div id="">
          <header className="sub">
          <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
        <h1>이용약관</h1>
    </header>
    
    <section>
        <div className="farm-detail pd-tb-0">
            <p className="fs-15 bold pd-tb-10">필수 약관</p>
            <div className="d-flex space-between align-center pd-tb-15 mb-0">
                <p className="fs-15 medium">서비스 이용약관</p> 
                <a onClick={() => navigate('/mybank/setting/Term/TermService')} className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
            <div className="d-flex space-between align-center pd-tb-15 mb-0">
                <p className="fs-15 medium">개인정보 처리지침</p> 
                <a onClick={() => navigate('/mybank/setting/Term/TermPersonal')} className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
            <div className="d-flex space-between align-center pd-tb-15 mb-0">
                <p className="fs-15 medium">참여자 이용</p> 
                <a onClick={() => navigate('/mybank/setting/Term/TermParticipant')} className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
        </div>
        <div className="pd-side">
            <p className="fs-15 bold pd-tb-10">선택 약관</p>
            <div className="d-flex space-between align-center pd-tb-15 mb-0">
                <p className="fs-15 medium">제휴 마케팅 정보제공</p> 
                <a onClick={() => navigate('/mybank/setting/Term/TermMarketing')} className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
        </div>
        
        
    </section>
        </div>
    )
}

export default Term;