import React from "react";
import { useNavigate } from "react-router-dom";

const Compensation = () => {
    const navigate = useNavigate();

    return (
        <div onClick={()=>navigate(-1)}>뒤로가기</div>
    );
}

export default Compensation;