import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetThumbnail } from '../../utils/ImageHandler';

import defaultImage from '../../images/default.png';
import { addComma } from '../../utils/StringFormat';

export interface OrderDetailPreviewProps {
  ORDER_ID: number;
  ORDER_STATUS: string;
  PRODUCT_ID: number;
  PAY_WITH: string;
  FARM_NM: string;
  PRODUCT_NM: string;
  QUANTITY: number;
  DISCOUNT_PRICE: number;
  DELIVERY_PRICE: number;
  ORIGINAL_AMOUNT: number;
  DELIVERY_AMOUNT: number;
  DISCOUNT_AMOUNT: number;
  TOTAL_AMOUNT: number;
  CREATE_DT: string;
  REVIEW_ID: number;
}

const MyOrderDetailPreview: FC<OrderDetailPreviewProps> = (props) => {
  const navigate = useNavigate();

  const [imagePath, setImagePath] = useState<string>('');
  const [buttonColor, setButtonColor] = useState<string>('btn btn-gray color-black fs-12');

  useEffect(() => {
    GetThumbnail('TB_ORDER_PRODUCT', props.PRODUCT_ID).then((res) => setImagePath(res));

    if (props.ORDER_STATUS === '구매확정' && props.REVIEW_ID === null) setButtonColor('btn btn-pink fs-12');
  }, []);

  const HandleClick = () => {
    if (buttonColor.includes('btn-pink'))
      navigate('/review/rate', { state: { orderDetail: props, imagePath: imagePath } });
  };

  return (
    <div className='farm-detail pd-0'>
      <div className='inner order-list'>
        <div className='product-list bd-0'>
          <p className='bold'>{props.ORDER_STATUS}</p>
          <div className='product-box pd-0 mt-10'>
            <div className='product-img'>
              <img src={imagePath ? imagePath : defaultImage} alt='' />
            </div>
            <div>
              <p className='fs-12'>{props.FARM_NM}</p>
              <p className='fs-15 medium'>{props.PRODUCT_NM}</p>
              <p className='order-price'>
                {addComma(props.DISCOUNT_PRICE * props.QUANTITY)}원<span>｜ 수량 {props.QUANTITY}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='pd-side'>
        <a className={buttonColor} onClick={HandleClick}>
          후기쓰기
        </a>
      </div>
      <div className='pd-side pd-tb-10 d-flex space-between'>
        <p className='color-gray'>배송비</p>
        <p>{addComma(props.DELIVERY_PRICE)}원</p>
      </div>
    </div>
  );
};

export default MyOrderDetailPreview;
