import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RequestBlock from "./RequestBlock";

const RequestList = () => {
    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    const [funds, setFunds] = useState<any[]>([]);

    const GetFund = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/deposit/fund`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "userID": user.USER_ID,
            }),
        })
        const data = await res.json();

        setFunds(data);
    }

    useEffect(() => {
        GetFund();
        
    }, [])

    return(
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>입금 대기</h1>
            </header>
            <section>
                <div className="list-group list-line pd-22">
                    {funds?.map((fund: any, index: any) => (
                        <RequestBlock {...fund} key={index} />
                    ))}
                </div>
            </section>
        </div>
    )
}

export default RequestList;