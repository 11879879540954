import React, { useState, useEffect, useRef } from 'react';
import { loadPaymentWidget, PaymentWidgetInstance } from '@tosspayments/payment-widget-sdk';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MobileFormat, addComma } from '../../../utils/StringFormat';
import { FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PaymentCartItem from '../PaymentCartItem';
import { CartType } from '../../../store/CartReducer';
import ReactModal from 'react-modal';
import { nanoid } from 'nanoid';
import { useCookies } from 'react-cookie';
import styles from './Payment.module.css';
import { fetchWithErrorHandling } from '../../../utils/fetchWithErrorHandling';

const selector = '#payment-widget';
const clientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq';
const customerKey = 'YbX2HuSlsC9uVJW6NMRMj';

export default function Payment() {
  const [cookies] = useCookies(['user']);
  const [message, setMessage] = useState('');
  let [address, setAddress] = useState<any>();
  const [accordionBuyer, setAccordionBuyer] = useState(true);
  const [showTermModal, setShowTermModal] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(7);
  const [termContent, setTermContent] = useState('');

  const customerName = useRef<HTMLInputElement>(null);
  const customerMobile = useRef<HTMLInputElement>(null);
  const customerEmail = useRef<HTMLInputElement>(null);
  const customerEmailAt = useRef<HTMLSelectElement>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bill = useSelector((state: any) => state.bill);
  const cart = useSelector((state: any) => state.cart);

  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);

  useEffect(() => {
    (async () => {
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey);
      paymentWidget.renderPaymentMethods(selector, bill?.TotalAmount);
      paymentWidgetRef.current = paymentWidget;
    })();
  }, [bill.TotalAmount]);

  useEffect(() => {
    GetTermContent();
  }, [selectedTerm]);

  useEffect(() => {
    GetUserAddress();
  }, []);

  return (
    <>
      <header className='sub'>
        <a className='btn-prev' onClick={() => navigate(-1)}>
          <span>이전</span>
        </a>
        <h1>주문/결제</h1>
      </header>

      <section>
        <Adress />
        <OpenAccordionBuyer />
        <CloseAccordionBuyer />
        <div className='shop-detail'>
          <div className='d-flex space-between align-center pd-22'>
            <p className='fs-18 bold'>주문상품</p>
            <div className='d-flex align-center'>
              <button>
                <img className={styles.arrow_img} src={arrowDown} alt='아래 화살표' />
              </button>
            </div>
          </div>
          <div className='inner'>
            {cart.items.map((item: CartType) => (
              <PaymentCartItem {...item} key={item.SHOPPING_CART_ID} />
            ))}
          </div>
        </div>
        <div id='payment-widget' />
        <div className='shop-detail'>
          <div className='d-flex space-between align-center pd-22'>
            <p className='fs-18 bold'>결제금액</p>
          </div>
          <div className='price-list'>
            <dl>
              <dt>총 상품금액</dt>
              <dd>{addComma(bill?.OriginalAmount)}원</dd>
            </dl>
            <dl>
              <dt>총 배송비</dt>
              <dd>{addComma(bill?.DeliveryAmount)}원</dd>
            </dl>
            <dl>
              <dt>총 할인금액</dt>
              <dd>-{addComma(bill?.DiscountAmount)}원</dd>
            </dl>
            <dl className='total-price'>
              <dt>최종 결제 금액</dt>
              <dd>{addComma(bill?.TotalAmount)}원</dd>
            </dl>
          </div>
        </div>

        <UserTerm />

        <div className='product-more pd-22'>
          <button
            className='btn btn-pink bold'
            onClick={async () => {
              dispatch({
                type: 'SET_CUSTOMER',
                payload: {
                  customerName: customerName.current?.value,
                  customerMobile: customerMobile.current?.value,
                  customerEmail: customerEmail.current?.value + '@' + customerEmailAt.current?.value,
                  customerMessage: message,
                  addrID: address.ADDR_ID,
                  orderName: MakeOrderName(),
                },
              });

              const paymentWidget = paymentWidgetRef.current;

              try {
                await paymentWidget?.requestPayment({
                  orderId: nanoid(),
                  orderName: MakeOrderName(),
                  customerName: customerName.current?.value,
                  successUrl: `${window.location}/success`,
                  failUrl: `${window.location}/fail`,
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {addComma(bill?.TotalAmount)}원 결제하기
          </button>
        </div>
        <TermDetailModal />
      </section>
    </>
  );

  function MakeOrderName() {
    const otherItemsCount = cart.items.length - 1;
    const firstItemName = cart.items[0].PRODUCT_NM;

    const orderName = otherItemsCount > 0 ? `${firstItemName} 외 ${otherItemsCount}건` : firstItemName;

    return orderName;
  }

  async function GetUserAddress() {
    let res = await fetch(process.env.REACT_APP_SERVER_URL + `/address/default/${cookies.user.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let data = await res.json();
    setAddress(data[0]);
  }

  function Adress() {
    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent) => {
      setMessage(event.target.value);
    };

    if (!address) {
      address = {
        ADDR_NM: '배송지를 추가해주세요',
        ROAD_ADDR: '',
        ADDRESS: '',
        NAME_TO: '',
        MOBILE: '',
      };
    }

    return (
      <div className='shop-detail'>
        <div className='d-flex space-between align-center pd-22'>
          <p className='fs-18 bold'>배송지</p>
          <button className='fs-15 bold color-red' onClick={() => navigate('/shop/ship')}>
            변경
          </button>
        </div>
        <div className={styles.address}>
          <span>제트플로우</span>
          <button className='btn-address'>기본배송지</button>
        </div>
        <div className='address-title'>
          <p className='fs-18 d-inline-block mr-10'>{address.ADDR_NM}</p>
        </div>

        <div className='inner'>
          <p className='color-gray'>
            {cookies.user.nickname} {MobileFormat(address.MOBILE)}
          </p>
          <div>
            <FormControl fullWidth>
              <Select value={message} onChange={handleChange} displayEmpty>
                <MenuItem value=''>배송시 요청사항을 선택해주세요</MenuItem>
                <MenuItem value='경비실에 맡겨주세요'>경비실에 맡겨주세요</MenuItem>
                <MenuItem value='소화전에 넣어주세요'>소화전에 넣어주세요</MenuItem>
                <MenuItem value='문 앞에 두고 사진을 보내주세요'>문 앞에 두고 사진을 보내주세요</MenuItem>
                <MenuItem value='배송 전에 문자주세요'>배송 전에 문자주세요</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }

  function OpenAccordionBuyer() {
    if (!accordionBuyer) return null;

    return (
      <div className='shop-detail'>
        <div className='d-flex space-between align-center pd-22'>
          <p className='fs-18 bold'>주문자</p>
          <div className={styles.orderer}>
            <p className='d-inline-block'>{address.KAKAO_NICK} </p>
            <p className='d-inline-block'>{MobileFormat(address.MOBILE)} </p>
            <button onClick={() => setAccordionBuyer(false)}>
              <img className={styles.arrow_img} src={arrowUp} alt='위 화살표' />
            </button>
          </div>
        </div>
        <div className={styles.inner}>
          <dl>
            <dt>이름</dt>
            <dd>
              <input type='text' ref={customerName} />
            </dd>
          </dl>
          <dl>
            <dt>전화번호</dt>
            <dd>
              <input type='text' placeholder={"'-' 제외하고 입력"} ref={customerMobile} />
            </dd>
          </dl>
          <dl>
            <dt>이메일</dt>
            <dd>
              <div className='email-wrap'>
                <input type='text' className='input-email' ref={customerEmail} />
                <p className='email-at'>@</p>
              </div>
              <div>
                <select className={styles.select_email} name='' id='' ref={customerEmailAt}>
                  <option value=''>선택해주세요.</option>
                  <option value='naver.com'>naver.com</option>
                  <option value='google.com'>google.com</option>
                  <option value='nate.com'>nate.com</option>
                  <option value='icloud.com'>icloud.com</option>
                  <option value='daum.net'>daum.net</option>
                  <option value='hanmail.net'>hanmail.net</option>
                </select>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    );
  }

  function CloseAccordionBuyer() {
    if (accordionBuyer) return null;
    if (!address) return null;

    return (
      <div className='shop-detail'>
        <div className='d-flex space-between align-center pd-22'>
          <p className='fs-18 bold'>주문자</p>
          <div className={styles.orderer}>
            <p className='d-inline-block'>{address.KAKAO_NICK} </p>
            <p className='d-inline-block'>{MobileFormat(address.MOBILE)} </p>
            <button onClick={() => setAccordionBuyer(true)}>
              <img className={styles.arrow_img} src={arrowDown} alt='아래 화살표' />
            </button>
          </div>
        </div>
      </div>
    );
  }

  function UserTerm() {
    let [agreeAll, setAgreeAll] = useState(false);
    let [agree1, setAgree1] = useState(false);
    let [agree2, setAgree2] = useState(false);

    function changeAgreeAll(e: any) {
      setAgreeAll(e.currentTarget.checked);
      setAgree1(e.currentTarget.checked);
      setAgree2(e.currentTarget.checked);
    }

    function changeAgree1(e: any) {
      setAgree1(e.currentTarget.checked);
      if (!e.currentTarget.checked) setAgreeAll(false);
      if (agree2) setAgreeAll(true);
    }

    function changeAgree2(e: any) {
      setAgree2(e.currentTarget.checked);
      if (!e.currentTarget.checked) setAgreeAll(false);
      if (agree1) setAgreeAll(true);
    }

    function showTerm(id: number) {
      setSelectedTerm(id);
      setShowTermModal(true);
    }

    return (
      <div className='payment-agree'>
        <div className='d-flex space-between pd-22'>
          <div>
            <input type='checkbox' id='check1' className='check1' checked={agreeAll} onChange={changeAgreeAll} />
            <label htmlFor='check1'></label>
            <p className='fs-18 bold label'>[필수] 아래 내용에 모두 동의합니다.</p>
          </div>
          <div className='d-flex align-center'>
            <button>
              <img className={styles.arrow_img} src={arrowUp} alt='위 화살표' />
            </button>
          </div>
        </div>
        <div className='agree-inner'>
          <div>
            <div className='form-checkbox'>
              <input type='checkbox' id='check2' className='check2' checked={agree1} onChange={changeAgree1} />
              <label htmlFor='check2'></label>
              <p className='label'>[필수] 개인정보 제 3자 제공</p>
              <a onClick={() => showTerm(7)}>보기</a>
            </div>
            <div className='form-checkbox'>
              <input type='checkbox' id='check3' className='check2' checked={agree2} onChange={changeAgree2} />
              <label htmlFor='check3'></label>
              <p className='label'>[필수] 개인정보 수집 및 이용</p>
              <a onClick={() => showTerm(8)}>보기</a>
            </div>
          </div>
        </div>
        <div className='agree-check'>
          <p>본인은 14세 이상이며, 주문 내용을 확인하였습니다.</p>
          <p className='mt-20'>
            (주)피그팜은 통신판매중개자로 거래 당사자가 아니므로, 판매자가 등록한 상품정보 및 거래 등에 대해 책임을 지지
            않습니다. (단. (주)피그팜이 판매자로 등록 판매한 상품은 판매자로서 책임을 부담합니다.)
          </p>
        </div>
      </div>
    );
  }

  function TermDetailModal() {
    return (
      <ReactModal
        isOpen={showTermModal}
        onRequestClose={() => setShowTermModal(false)}
        className='modal'
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        overlayClassName={'Overlay'}
      >
        <section className='join' style={{ height: '100vh', backgroundColor: '#fff' }}>
          <div className='h-100'>
            {selectedTerm === 7 ? <h1 className='fs-20'>개인정보 제 3자 제공</h1> : ''}
            {selectedTerm === 8 ? <h1 className='fs-20'>개인정보 수집 및 이용</h1> : ''}
            <div className='terms'>{termContent}</div>
            <div className='btn-area'>
              <div className='btn btn-pink' onClick={() => setShowTermModal(false)}>
                확인
              </div>
            </div>
          </div>
        </section>
      </ReactModal>
    );
  }

  async function GetTermContent() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = await fetchWithErrorHandling(`/term/${selectedTerm}`, options);
    setTermContent(data[0].CONTENT);
  }
}

const arrowUp = '/images/arrow_up.png';
const arrowDown = '/images/arrow_down.png';
