import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowRightBK from "../../../../images/arrow_right_bk.png";
const TermMarketing = () => {
    const navigate = useNavigate();

    const GetInquires = async () => {
        let term = '제휴 마케팅 정보제공';
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/mybank/setting/term/${term}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        (document.getElementById("term_content") as HTMLInputElement).innerText = data[0].CONTENT;
    };

    useEffect(() => {
        GetInquires();
    }, [])

    return (
        <div id="">
          <header className="sub">
          <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
        <h1>선택 이용약관</h1>
    </header>
    
    <section>
        <div className="farm-detail pd-tb-0">
            <p className="fs-15 bold pd-tb-10">제휴 마케팅 정보제공</p>
            <div className="d-flex space-between align-center pd-tb-15 mb-0">
                <p className="fs-15 medium" id="term_content">내용내용</p> 
            </div>
        </div>
    </section>
        </div>
    )
}

export default TermMarketing;