import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';

import './css/default.css';
import './css/font.css';
import './css/custom.css';
import './css/swiper-bundle.min.css';

import Login from './screens/Login/Login';
import Oauth from './screens/Oauth/Oauth';
import Privacy from './screens/Privacy';
import Nav from './components/Nav';
import Guide from './components/home/popups/Guide';
import Alarm from './components/home/popups/Alarm';
import Home from './screens/Home';
import Fund from './screens/Fund/Fund';
import Shop from './screens/Shop';
import Monitor from './screens/Monitor';
import Mybank from './screens/Mybank/Mybank';
import FundDetail from './screens/FundDetail/FundDetail';
import FarmDetail from './screens/FarmDetail/FarmDetail';
import NewsDetail from './components/home/popups/NewsDetail/NewsDetail';
import WishList from './components/fund/wishlist/WishList';
import FundingEnd from './components/fund/FundingEnd';
import ShopDetail from './components/shop/ShopDetail/ShopDetail';
import ReviewList from './components/shop/ReviewList';
import Inquery from './components/shop/Inquery';
import InqueryNotice from './components/shop/InqueryNotice';
import Cart from './components/shop/Cart';
import MarketPrice from './components/home/popups/MarketPrice';
import TraceInfo from './components/fund/fundDetail/TraceInfo';
import InsuranceInfo from './components/fund/fundDetail/InsuranceInfo';
import MybankNotice from './components/mybank/notice/Notice';
import Inquiry from './components/mybank/inquiry/Inquiry';
import CustomerService from './components/mybank/inquiry/customerService/CustomerService';
import FAQ from './components/mybank/inquiry/faq/FAQ';
import MyShopping from './components/shop/MyShopping';
import MyOrder from './components/shop/MyOrder';
import MyOrderDetail from './components/shop/MyOrderDetail';
import MyReview from './components/shop/MyReview';
import RateReview from './components/shop/RateReview';
import CommentReview from './components/shop/CommentReview';
import Compensation from './components/fund/farmDetail/Compensation';
import Setting from './components/mybank/setting/setting';
import CompanyInfo from './components/mybank/setting/companyInfo/CompanyInfo';
import Alert from './components/mybank/setting/alert/Alert';
import Term from './components/mybank/setting/Term/term';
import TermService from './components/mybank/setting/Term/TermService/TermService';
import TermPersonal from './components/mybank/setting/Term/TermPersonal/TermPersonal';
import TermParticipant from './components/mybank/setting/Term/TermParticipant/TermParticipant';
import TermMarketing from './components/mybank/setting/Term/TermMarketing/TermMarketing';
import License from './components/mybank/setting/license/License';
import LicenseDetail from './components/mybank/setting/license/LicenseDetail';
import Delete from './components/mybank/setting/delete/Delete';
import EditProfile from './components/mybank/editprofile/edirprofile';
import Deposit from './components/mybank/deposit/Deposit';
import SelectBank from './components/mybank/withdrawAccount/SelectBank';
import InputAccountNumber from './components/mybank/withdrawAccount/InputAccountNumber';
import StandBy from './components/mybank/withdrawAccount/StandBy';
import CertifyAccount from './components/mybank/withdrawAccount/CertifyAccount';
import Complete from './components/mybank/withdrawAccount/Complete';
import CheckPIN from './components/mybank/withdrawAccount/pin/CheckPIN';
import SetPIN from './components/mybank/withdrawAccount/pin/SetPIN';
import SetConfirmPIN from './components/mybank/withdrawAccount/pin/SetConfirmPIN';
import FundReceipt from './components/mybank/papers/FundReceipt';
import CancelHistory from './components/mybank/papers/CancelHistory';
import AccidentHistory from './components/mybank/papers/AccidentHistory';
import PigDetail from './components/fund/farmDetail/PigDetail';
import PINcheck from './components/fund/PINcheck';
import ShippingList from './components/shop/ShippingList';
import Payment from './components/shop/Payment/Payment';
import UnityViewer from './components/monitor/UnityViewer';
import ShippingAdd from './components/shop/ShippingAdd';
import ShippingEdit from './components/shop/ShippingEdit';
import CameraViewer from './components/monitor/CameraViewer';
import PaymentSuccess from './components/shop/PaymentSuccess';
import PaymentFail from './components/shop/PaymentFail';
import RequestList from './components/mybank/RequestList';
import FundingProcess from './components/fund/FundingProcess/FundingProcess';

function App() {
  const [activeLink, setActiveLink] = useState('menu1');
  const [isActive, setIsActive] = useState(false);

  const pageProps = {
    setActiveLink,
    setIsActive,
  };

  return (
    <div className='main-wrap'>
      <Routes>
        <Route path='/' element={<Login />}></Route>
        <Route path='/oauth' element={<Oauth />}></Route>
        <Route path='/privacy' element={<Privacy />}></Route>

        <Route path='/guide' element={<Guide />}></Route>
        <Route path='/alarm' element={<Alarm />}></Route>
        <Route path='/market' element={<MarketPrice />}></Route>
        <Route path='/news/detail' element={<NewsDetail />}></Route>

        <Route path='/home' element={<Home {...pageProps} />}></Route>
        <Route path='/fund' element={<Fund {...pageProps} />}></Route>
        <Route path='/shop' element={<Shop {...pageProps} />}></Route>
        <Route path='/mybank' element={<Mybank {...pageProps} />}></Route>
        <Route path='/unity' element={<Monitor {...pageProps} />}></Route>

        <Route path='/mybank/RequestList' element={<RequestList />}></Route>
        <Route path='/mybank/notice' element={<MybankNotice />}></Route>
        <Route path='/mybank/inquiry' element={<Inquiry />}></Route>
        <Route path='/mybank/inquiry/service' element={<CustomerService />}></Route>
        <Route path='/mybank/inquiry/faq' element={<FAQ />}></Route>
        <Route path='/myshopping' element={<MyShopping />}></Route>
        <Route path='/myshopping/order' element={<MyOrder />}></Route>
        <Route path='/myshopping/order/detail' element={<MyOrderDetail />}></Route>
        <Route path='/myreview/' element={<MyReview />}></Route>
        <Route path='/review/rate' element={<RateReview />}></Route>
        <Route path='/review/comment' element={<CommentReview />}></Route>

        <Route path='/fund/detail/fund' element={<FundDetail />}></Route>
        <Route path='/fund/detail/farm' element={<FarmDetail />}></Route>
        <Route path='/fund/process' element={<FundingProcess />}></Route>
        <Route path='/fund/wishlist' element={<WishList />}></Route>
        <Route path='/fund/end' element={<FundingEnd />}></Route>
        <Route path='/shop/detail' element={<ShopDetail />}></Route>
        <Route path='/reviews' element={<ReviewList />}></Route>
        <Route path='/inquery' element={<Inquery />}></Route>
        <Route path='/inquery/notice' element={<InqueryNotice />}></Route>
        <Route path='/cart' element={<Cart />}></Route>
        <Route path='/trace' element={<TraceInfo />}></Route>
        <Route path='/insurance' element={<InsuranceInfo />}></Route>
        <Route path='/compensate' element={<Compensation />}></Route>
        <Route path='/shop/ship' element={<ShippingList />}></Route>
        <Route path='/shop/payment' element={<Payment />}></Route>
        <Route path='/shop/payment/success' element={<PaymentSuccess />}></Route>
        <Route path='/shop/payment/fail' element={<PaymentFail />}></Route>
        <Route path='/shop/ship/add' element={<ShippingAdd />}></Route>
        <Route path='/shop/ship/edit' element={<ShippingEdit />}></Route>

        <Route path='/mybank/setting' element={<Setting />}></Route>
        <Route path='/mybank/setting/CompanyInfo' element={<CompanyInfo />}></Route>
        <Route path='/mybank/setting/Alert' element={<Alert />}></Route>
        <Route path='/mybank/setting/Term' element={<Term />}></Route>
        <Route path='/mybank/setting/Term/TermService' element={<TermService />}></Route>
        <Route path='/mybank/setting/Term/TermPersonal' element={<TermPersonal />}></Route>
        <Route path='/mybank/setting/Term/TermParticipant' element={<TermParticipant />}></Route>
        <Route path='/mybank/setting/Term/TermMarketing' element={<TermMarketing />}></Route>
        <Route path='/mybank/setting/License' element={<License />}></Route>
        <Route path='/mybank/setting/LicenseDetail' element={<LicenseDetail />}></Route>
        <Route path='/mybank/setting/Delete' element={<Delete />}></Route>
        <Route path='/mybank/EditProfile' element={<EditProfile />}></Route>
        <Route path='/mybank/Deposit' element={<Deposit />}></Route>
        <Route path='/mybank/withdrawAccount/SelectBank' element={<SelectBank />}></Route>
        <Route path='/mybank/withdrawAccount/InputAccountNumber' element={<InputAccountNumber />}></Route>
        <Route path='/mybank/withdrawAccount/StandBy' element={<StandBy />}></Route>
        <Route path='/mybank/withdrawAccount/CertifyAccount' element={<CertifyAccount />}></Route>
        <Route path='/mybank/withdrawAccount/Complete' element={<Complete />}></Route>
        <Route path='/mybank/withdrawAccount/pin/CheckPIN' element={<CheckPIN />}></Route>
        <Route path='/mybank/withdrawAccount/pin/SetPIN' element={<SetPIN />}></Route>
        <Route path='/mybank/withdrawAccount/pin/SetConfirmPIN' element={<SetConfirmPIN />}></Route>
        <Route path='/mybank/papers/FundReceipt' element={<FundReceipt />}></Route>
        <Route path='/mybank/papers/CancelHistory' element={<CancelHistory />}></Route>
        <Route path='/mybank/papers/AccidentHistory' element={<AccidentHistory />}></Route>

        <Route path='/farm/pig' element={<PigDetail />}></Route>
        <Route path='/pin' element={<PINcheck />}></Route>
      </Routes>
      <Nav activeLink={activeLink} isActive={isActive}></Nav>
    </div>
  );
}

export default App;
