import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';

const rest_api_key = process.env.REACT_APP_REST_API_KEY;
const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
const kakao_auth_url = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

export default function Login() {
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.user) navigate('/home');
  }, []);

  const handleLogin = async () => {
    window.location.href = kakao_auth_url;
  };

  return (
    <section className={styles.container}>
      <div className={styles.login}>
        <h1>로그인</h1>
        <img src='/images/login_pig.jpg' alt='돼지 이미지' />
        <button className={styles.kakao_btn} onClick={handleLogin}>
          <img src='/images/kakao_login_medium_wide.png' alt='카카오 이미지' />
        </button>
        <button className={styles.privacy_btn} onClick={() => navigate('/privacy')}>
          개인정보 처리방침
        </button>
      </div>
    </section>
  );
}
