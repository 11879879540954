import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import arrowRightBK from "../../../images/arrow_right_bk.png";

const Setting = () => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    useEffect(() => {
        console.log(cookies);
    }, [])

    const LogoutHandler = () => {
        removeCookie("user");
        navigate('/');
    }

    return (
        <div id="">
            <header className="sub">
            <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
        <h1>설정</h1>
    </header>
    
    <section>
        <div className="farm-detail pd-tb-0">
            <div className="d-flex space-between align-center pd-tb-15 mb-0" onClick={() => navigate('/mybank/setting/CompanyInfo')}>
                <p className="fs-15 medium">회사소개</p> 
                <a className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
            <div className="d-flex space-between align-center pd-tb-15 mb-0" onClick={() => navigate('/mybank/setting/Alert')}>
                <p className="fs-15 medium">알림</p> 
                <a className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
        </div>
        <div className="farm-detail pd-tb-0">
            <div className="d-flex space-between align-center pd-tb-15 mb-0" onClick={() => navigate('/mybank/setting/Term')}>
                <p className="fs-15 medium">이용약관</p> 
                <a className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
            <div className="d-flex space-between align-center pd-tb-15 mb-0" onClick={() => navigate('/mybank/setting/License')}>
                <p className="fs-15 medium">오픈소스 라이선스</p> 
                <a className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
            <div className="d-flex space-between align-center pd-tb-15 mb-0" onClick={() => {}}>
                <p className="fs-15 medium">앱 버전정보</p> 
                <p className="color-gray">1.1.1</p>
            </div>
        </div>
        <div className="pd-side">
            <div className="d-flex space-between align-center pd-tb-15 mb-0" onClick={LogoutHandler}>
                <p className="fs-15 medium">로그아웃</p> 
                <a className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
            <div className="d-flex space-between align-center pd-tb-15 mb-0" onClick={() => navigate('/mybank/setting/Delete')}>
                <p className="fs-15 medium">회원탈퇴</p> 
                <a className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
        </div>
        
        
    </section>
    
        </div>
    )
}

export default Setting;