import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithErrorHandling } from '../../../../utils/fetchWithErrorHandling';
import styles from './NewsDetail.module.css';
import { formatDate } from '../../../../utils/formatDate';

const NewsDetail = () => {
  const [newsData, setNewsData] = useState<any>();

  const navigate = useNavigate();
  const location = useLocation();

  const { news_id, thumbnail } = location.state;

  const getNewsData = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = await fetchWithErrorHandling(`/news/${news_id}`, options);
    setNewsData(data[0]);
  };

  const URLhandler = () => {
    if (newsData?.NEWS_URL) {
      window.open(newsData?.NEWS_URL);
    }
  };

  const ShareHandler = () => {
    if (navigator.share) {
      navigator.share({
        title: '기록하며 성장하기',
        text: 'Hello World',
        url: 'https://shinsangeun.github.io',
      });
    } else {
      alert('공유하기가 지원되지 않는 환경 입니다.');
    }
  };

  useEffect(() => {
    getNewsData();
  }, []);

  return (
    <div>
      <header className={styles.container}>
        <button className='btn-prev left' onClick={() => navigate(-1)} />
        <h1>피그팜 뉴스</h1>
        <button className='btn-share' onClick={ShareHandler} />
      </header>
      <section>
        <div className='img-area'>
          <img src={thumbnail ? thumbnail : defaultImage} alt='' />
        </div>
        <div className='news-wrap'>
          <p className='fs-18 bold'>{newsData?.NEWS_TITLE}</p>
          <p className='fs-11 color-gray text-right'>{formatDate(newsData?.CREATE_DT)}</p>
          <p className='medium mt-20'>{newsData?.NEWS_ARTICLE} </p>
        </div>
        <div className='btn-area' onClick={URLhandler}>
          <button className={newsData?.NEWS_URL ? 'btn btn-pink' : 'btn btn-gray'}>본문 전체보기</button>
        </div>
      </section>
    </div>
  );
};

export default NewsDetail;

const defaultImage = '/images/default.png';
