import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";



const Alert = () => {

    
    const [setting1, setSetting1] = useState(false);
    const [setting2, setSetting2] = useState(false);
    const [setting3, setSetting3] = useState(false);
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const GetInquires = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/mybank/setting/alert/${user.USER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        
        setSetting1(data[0].APP_PUSH);
        setSetting2(data[0].EVENT_PUSH);
        setSetting3(data[0].MARKETING);
    };

    const SetInquires = async () => {
        let push_1 = 0, push_2 = 0, push_3 = 0;
        if((document.getElementById("APP_PUSH") as HTMLInputElement).checked)
            push_1 = 1;
        if((document.getElementById("EVENT_PUSH") as HTMLInputElement).checked)
            push_2 = 1;
        if((document.getElementById("MARKETING") as HTMLInputElement).checked)
            push_3 = 1;
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/mybank/setting/updatealert/${user.USER_ID}/${push_1}/${push_2}/${push_3}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        console.log(data);
    };

    useEffect(() => {
        GetInquires();
    }, [])

    const ToggleShow = (e: any) => {
        if(e.target.id == "APP_PUSH") {
            setSetting1(!setting1);
        }
        else if(e.target.id == "EVENT_PUSH") {
            setSetting2(!setting2);
        }
        else if(e.target.id == "MARKETING") {
            setSetting3(!setting3);
        }
        SetInquires();
    }

    return (
        <div id="">
         <header className="sub">
         <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
        <h1>앱 푸시 알림</h1>
    </header>
    <section>
        <div className="pd-side mt-10">
            <div className="d-flex space-between align-center pd-tb-10 mb-0">
                <p className="fs-15 medium">앱 푸시 알림</p> 
                <div>
                    <input type="checkbox" id="APP_PUSH" className="toggleInput" hidden checked={setting1} onChange={ToggleShow}  ></input> 

                    <label htmlFor="APP_PUSH" className="toggleSwitch">
                      <span className="toggleButton"></span>
                    </label>
                </div>
            </div>
            <div className="d-flex space-between align-center pd-tb-10 mb-0">
                <p className="fs-15 medium">이벤트 푸시 알림</p> 
                <div>
                    <input type="checkbox" id="EVENT_PUSH" className="toggleInput" hidden checked={setting2} onChange={ToggleShow}></input> 

                    <label htmlFor="EVENT_PUSH" className="toggleSwitch">
                      <span className="toggleButton"></span>
                    </label>
                </div>
            </div>
            <div className="d-flex space-between align-center pd-tb-10 mb-0">
                <p className="fs-15 medium">제휴 마케팅 정보제공</p> 
                <div>
                    <input type="checkbox" id="MARKETING" className="toggleInput" hidden checked={setting3} onChange={ToggleShow}></input> 

                    <label htmlFor="MARKETING" className="toggleSwitch">
                      <span className="toggleButton"></span>
                    </label>
                </div>
            </div>
        </div>
    </section>
        </div>
    )
}

export default Alert;