import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import starPoint from '../../images/star_point.png';

import { GetImages, GetThumbnail } from '../../utils/ImageHandler';
import defaultImage from '../../images/default.png';
import { addComma } from '../../utils/StringFormat';

export interface ReviewPreviewProps {
  ORDER_ID: number;
  ORDER_STATUS: string;
  PRODUCT_ID: number;
  PAY_WITH: string;
  FARM_NM: string;
  PRODUCT_NM: string;
  QUANTITY: number;
  DISCOUNT_PRICE: number;
  ORIGINAL_AMOUNT: number;
  DELIVERY_AMOUNT: number;
  DISCOUNT_AMOUNT: number;
  TOTAL_AMOUNT: number;
  CREATE_DT: string;
  REVIEW_ID: number;
  REVIEW_TEXT: string;
  RATE: number;
  UPLOAD_DT: string;

  show: boolean;
}

export const MyReviewOrderPreview: FC<ReviewPreviewProps> = (props) => {
  const navigate = useNavigate();

  const [imagePath, setImagePath] = useState<string>('');

  useEffect(() => {
    GetThumbnail('TB_ORDER_PRODUCT', props.PRODUCT_ID).then((res) => setImagePath(res));
  }, []);

  return (
    <div className='farm-detail pd-0'>
      <div className='inner order-list'>
        <div className='product-list bd-0'>
          <div className='product-box pd-0 mt-10'>
            <div className='product-img'>
              <img src={imagePath ? imagePath : defaultImage} alt='' />
            </div>
            <div>
              <p className='fs-12'>{props.FARM_NM}</p>
              <p className='fs-15 medium'>{props.PRODUCT_NM}</p>
              <p className='order-price'>
                {addComma(props.DISCOUNT_PRICE * props.QUANTITY)}원<span>｜ 수량 {props.QUANTITY}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='pd-side'>
        <a
          className='btn btn-gray color-black fs-12'
          onClick={() => navigate('/review/rate', { state: { orderDetail: props, imagePath: imagePath } })}
        >
          후기쓰기
        </a>
      </div>
    </div>
  );
};

export const MyReviewPreview: FC<ReviewPreviewProps> = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  let arr: Array<any> = [];
  const [imagePath, setImagePath] = useState(arr);

  useEffect(() => {
    GetImages('TB_REVIEW', props.REVIEW_ID).then((res) => setImagePath(res));
  }, []);

  return (props.show && imagePath.length > 0) || !props.show ? (
    <div>
      <p>{user.KAKAO_NICK}</p>
      <div className='d-flex mb-5'>
        <div className='star-wrap'>
          <img src={props.RATE > 0 ? starPoint : ''} alt='' style={{ width: '14px' }} />
          <img src={props.RATE > 1 ? starPoint : ''} alt='' style={{ width: '14px' }} />
          <img src={props.RATE > 2 ? starPoint : ''} alt='' style={{ width: '14px' }} />
          <img src={props.RATE > 3 ? starPoint : ''} alt='' style={{ width: '14px' }} />
          <img src={props.RATE > 4 ? starPoint : ''} alt='' style={{ width: '14px' }} />
        </div>
        <p className='fs-12 color-gray'>{props.UPLOAD_DT.substring(0, 10)}</p>
      </div>
      <div className='d-flex'>
        <p className='mr-10'>
          <img
            src={imagePath[0] ? process.env.REACT_APP_SERVER_URL + imagePath[0].SERVER_IMG_PATH : ''}
            alt=''
            style={{ width: '59px' }}
          />
        </p>
        <p>{props.REVIEW_TEXT}</p>
      </div>
    </div>
  ) : (
    <></>
  );
};
