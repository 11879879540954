import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleFundRate } from '../../../../utils/PercentCalculator';
import { useCookies } from 'react-cookie';
import styles from './Floating.module.css';
import { addComma } from '../../../../utils/StringFormat';
import { fetchWithErrorHandling } from '../../../../utils/fetchWithErrorHandling';

export default function Floating({ fundInfo: { ID, TARGET_AMOUNT, CURRENT_AMOUNT }, isDibs, fundInfo }: any) {
  const [cookies] = useCookies(['user']);
  const [userNumber, setUserNumber] = useState(0);
  const [dibs, setDibs] = useState(isDibs);

  const navigate = useNavigate();

  const handleFund = () => {
    navigate('/fund/process', { state: { fundInfo } });
  };

  const handleDibs = async () => {
    try {
      const response = await fetch(`${server_url}/dibs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userID: cookies.user.id,
          fundingID: ID,
          dibs: dibs,
        }),
      });
      const data = await response.json();

      if (data) setDibs(!dibs);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserNumber = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = await fetchWithErrorHandling(`/bidding/count/${ID}`, options);
    setUserNumber(data[0].UNIQUE_USERS);
  };

  useEffect(() => {
    getUserNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ID]);

  return (
    <div className='floating'>
      <div className='percent'>
        <div className='d-flex space-between'>
          <p className='fs-18 bold'>
            달성률 <span className='color-red'>{handleFundRate(TARGET_AMOUNT, CURRENT_AMOUNT).toFixed(1)}%</span>
          </p>
          <p className='fs-18 medium'>{addComma(userNumber)}명</p>
        </div>
        <div className='bar-bg'>
          <div className='bar' style={{ width: `${handleFundRate(TARGET_AMOUNT, CURRENT_AMOUNT)}%` }}></div>
        </div>
      </div>
      <div className={styles.btns}>
        <button className={styles.img_btn} onClick={handleDibs}>
          <img src={dibs ? ico_keepon : ico_keep} alt='찜' />
        </button>
        <button className={styles.fund_btn} onClick={handleFund}>
          펀딩하기
        </button>
      </div>
    </div>
  );
}

const ico_keepon = '/images/ico_keepon.png';
const ico_keep = '/images/ico_keep.png';

const server_url = process.env.REACT_APP_SERVER_URL;
