export interface Bill {
    OriginalAmount: number;
    DiscountAmount: number;
    DeliveryAmount: number;
    TotalAmount: number;
}

export interface BillAction {
    type: string;
    payload: Bill | null;
}

const BillReducer = (state : Bill | null = null, action: BillAction) : Bill | null => {
    switch (action.type) {
        case 'SET_BILL':
            return action.payload;
        default:
            return state;
    }
}

export default BillReducer;