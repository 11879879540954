import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useLocation, useNavigate } from 'react-router-dom';
import FarmInfo from '../../components/fund/farmDetail/FarmInfo';
import FarmerStory from '../../components/fund/farmDetail/FarmerStory';
import FarmFund from '../../components/fund/farmDetail/FarmFund';
import { GetImages } from '../../utils/ImageHandler';
import styles from './FarmDetail.module.css';

const arrowLeft = '/images/arrow_left.png';
const arrowRightBk = '/images/arrow_right_bk.png';

export default function FarmDetail() {
  const [imgInfo, setImgInfo] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const { farm, fundingInfo } = location.state;

  useEffect(() => {
    GetImages('TB_FARM', farm.FARM_ID).then((res) => setImgInfo(res));
  }, []);

  return (
    <section>
      <header className='sub funding'>
        <button className={styles.prev_btn} onClick={() => navigate(-1)}>
          <img src={arrowLeft} alt='왼쪽 화살표' />
        </button>
        <Swiper
          className='headerSwiper'
          modules={[Navigation, Pagination]}
          pagination={{
            el: '.headerSwiper .swiper-pagination',
            type: 'fraction',
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
        >
          {imgInfo.map((img: any) => (
            <SwiperSlide className='swiper-slide' key={img.IMG_ID}>
              <img src={img.SERVER_IMG_PATH} alt='농장 사진' />
            </SwiperSlide>
          ))}
          <div className='swiper-pagination' />
        </Swiper>
      </header>
      <main>
        <div>
          <FarmInfo {...farm} />
          <FarmerStory {...farm} />

          <div className='farm-detail'>
            <div className='d-flex space-between'>
              <p className='fs-18 bold'>사고시 보장내용</p>
              <button className='btn-detail'>
                <img src={arrowRightBk} alt='오른쪽 화살표' />
              </button>
            </div>
            <p>부상, 질병, 폐사 등 일반적 사고 및 법정 전염병에 대한 보상</p>
            <div className='border fs-18 bold text-center pd-10 mt-10' onClick={() => navigate('/compensate')}>
              가축재해보험+농가보증
            </div>
          </div>

          <div className='farm-detail'>
            <div className='d-flex space-between align-center'>
              <h2>상품</h2>
              <p className='fs-16 medium'>
                총 <span className='color-red'>{fundingInfo.length}</span>마리
              </p>
            </div>
            {fundingInfo.map((item: any) => (
              <FarmFund {...item} key={item.ID} />
            ))}
          </div>
        </div>
      </main>
    </section>
  );
}
