import React, { useState, useEffect,  } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PINKeypad from "../../../PINKeypad";



const SetPIN = () => {
    const navigate = useNavigate();

    const [pin, setPin] = React.useState("");

    const handleNumberClick = (num: number) => {
        setPin(pin + num.toString());
    }
    const handleDeleteClick = () => {
        setPin(pin.substring(0, pin.length - 1));
    }
    const handleClearClick = () => {
        setPin("");
    }

    const keypadProps = {
        onNumberClick: handleNumberClick,
        onClearClick: handleClearClick,
        onDeleteClick: handleDeleteClick,
    }
    useEffect(() => {

    }, [])

    useEffect(() => {
        if(pin.length === 6){
            navigate('/mybank/withdrawAccount/pin/SetConfirmPIN', {state: {pin: pin}});
        }
    }, [pin])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
            </header>
            <section className="h-100n d-flex flex-column space-between">
                <div className="pd-side mt-15">
                    <p className="fs-20 bold">출금과 거래에 사용될<br/> 비밀번호를 등록해주세요</p>
                </div>
                <div className="form-passowrd">
                    <input type="password" className={pin.length >= 1 ? "enter": ""} />
                    <input type="password" className={pin.length >= 2 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 3 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 4 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 5 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 6 ? "enter": ""}/>
                </div>
                <div>
                    <PINKeypad {...keypadProps} />

                    {/* <div className="keypad">
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>6</div>
                        <div>7</div>
                        <div>8</div>
                        <div>9</div>
                        <div>최대</div>
                        <div>0</div>
                        <div><img src="/images/arrow_left.png" alt="" style={{width: "19px"}}/></div>
                    </div> */}
                </div>
                
            </section>
        </div>
    )
}

export default SetPIN;