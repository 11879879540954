import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import NoticePreview from './NoticePreview';

import arrowUp from "../../../images/arrow_up.png";
import arrowDown from "../../../images/arrow_down.png";

const Notice = () => {
    const navigate = useNavigate();

    let visibleArr: Array<any> = [];
    let arr: Array<any> = [];
    const [notices, setNotices] = useState(arr);


    const GetFund = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/mybank/notice", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();

        setNotices(data);
    }

    useEffect(() => {
        GetFund();
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>공지사항</h1>
            </header>
            <section>
                <div>
                    {notices.map((item: any) => (
                        <NoticePreview {...item} key={item.NOTICE_ID} />
                    ))}
                </div>
            </section>
        </div>
    )
}

export default Notice;