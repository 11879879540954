import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './FundInfo.module.css';
import { addComma } from '../../../../utils/StringFormat';
import { formatDate } from '../../../../utils/formatDate';

// interface FundInfoProps {
//   PIG_NM: string;
//   PIG_AGE: number;
//   PIG_GENE: string;
//   PIG_WEIGHT: number;
//   MIN_AMOUNT: number;
//   TARGET_AMOUNT: number;
//   PIGLET_COST: number;
//   BREED_COST: number;
// }

const icoInfo = '/images/ico_info.png';
const arrowDown = '/images/arrow_down.png';
const arrowUp = '/images/arrow_up.png';

export default function FundInfo({ fundInfo }: any) {
  const {
    PIG_NM,
    PIG_AGE,
    PIG_WEIGHT,
    PIG_BREED,
    PIG_SEX,
    MIN_AMOUNT,
    TARGET_AMOUNT,
    PIGLET_COST,
    BREED_COST,
    BREEDING_DT,
    PIG_INJECTION,
  } = fundInfo;

  const [isHide, setIsHide] = useState(true);

  const handleHide = () => {
    setIsHide(!isHide);
  };

  return (
    <div className='farm-detail'>
      <h2>{PIG_NM}</h2>
      <div className='grow'>
        <p className='medium'>
          <span>{PIG_AGE}개월</span> ｜ <span>{PIG_WEIGHT}kg</span>
        </p>
        <p className='fs-12 color-gray'>{PIG_INJECTION?.split('-').join('.')} 측정</p>
      </div>
      <div className='quality'>
        <dl>
          <dt>블루라벨</dt>
          <dd id='bluelabel' data-tooltip-content='피그팜이 직접 관리하는 농장입니다.'>
            <button className={styles.tooltip_btn}>
              <img src={icoInfo} alt='Tooltip 이미지' />
              <Tooltip anchorId='bluelabel' />
            </button>
          </dd>
        </dl>
        <dl>
          <dt>{PIG_SEX}컷</dt>
          <dd id='handon' data-tooltip-content={`${PIG_BREED} 품중의 국내산 ${PIG_SEX}컷 돼지입니다.`}>
            <button className={styles.tooltip_btn}>
              <img src={icoInfo} alt='Tooltip 이미지' />
              <Tooltip anchorId='handon' />
            </button>
          </dd>
        </dl>
      </div>
      <div className='price'>
        <dl>
          <dt>최소 금액</dt>
          <dd className='fs-15 bold color-red'>{addComma(MIN_AMOUNT)}원</dd>
        </dl>
        <dl>
          <dt>펀딩 금액</dt>
          <dd className='fs-15 bold color-red'>
            {addComma(TARGET_AMOUNT)}원
            <button className='ico btn-up'>
              <img src={isHide ? arrowDown : arrowUp} alt='화살표' onClick={handleHide} />
            </button>
          </dd>
          {isHide ? (
            ''
          ) : (
            <dd className='w-100p'>
              <div className='cost-detail'>
                <dl>
                  <dt>아기돼지 비용</dt>
                  <dd>{addComma(PIGLET_COST)}원</dd>
                </dl>
                <dl>
                  <dt>사육 비용</dt>
                  <dd>{addComma(BREED_COST)}원</dd>
                </dl>
                <dl>
                  <dt>사육 시작일</dt>
                  <dd>{formatDate(BREEDING_DT)}</dd>
                </dl>
                <p>아기돼지 비용과 사육비용을 모두 펀딩할 수 있는 직영농장 상품입니다.</p>
              </div>
            </dd>
          )}
        </dl>
      </div>
    </div>
  );
}
