import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetThumbnail } from "../../../utils/ImageHandler";
import defaultImage from "../../../images/default.png";

const Guide = () => {
    const navigate = useNavigate();
    const [imagePath, setImagePath] = useState<string>("");

    useEffect(() => {
        GetThumbnail("GUIDE", 1).then((res) => setImagePath(res));
    }, []);

    return (
        <div className="h-100">
            <img src={imagePath ? imagePath : defaultImage} alt="loading..." style={{width: "100%", height: "100%"}} onClick={()=>navigate(-1)}/>   
        </div>
    )
}

export default Guide;