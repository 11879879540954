import React, { FC, useState, useEffect } from 'react';
import imgList from '../../images/img_list.png';
import icoPut from '../../images/ico_put.png';
import star from '../../images/star.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetThumbnail } from '../../utils/ImageHandler';
import defaultImage from '../../images/default.png';
import { addComma } from '../../utils/StringFormat';

interface ShopPreviewProps {
  PRODUCT_ID: number;
  FARM_ID: number;
  FARM_NM: string;
  PRODUCT_NM: string;
  PRICE: number;
  CATEGORY: string;
  DISCOUNT_RATE: number;
  DISCOUNT_PRICE: number;
  DELIVERY_PRICE: number;
  RATE: number;
}

const ShopPreview: FC<ShopPreviewProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const [imagePath, setImagePath] = useState<string>('');
  const [reviewCount, setReviewCount] = useState<number>(0);

  const handleClick = () => {
    navigate('/shop/detail', { state: { productID: props.PRODUCT_ID } });
  };

  const AddToCart = async () => {
    const res = await fetch(process.env.REACT_APP_SERVER_URL + '/cart/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productID: props.PRODUCT_ID,
        farmID: props.FARM_ID,
        userID: user.USER_ID,
      }),
    });
    const data = await res.json();
  };

  const GetReviews = async () => {
    const res = await fetch(process.env.REACT_APP_SERVER_URL + `/reviews/${props.PRODUCT_ID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await res.json();
    setReviewCount(data.length);
  };

  useEffect(() => {
    GetThumbnail('TB_ORDER_PRODUCT', props.PRODUCT_ID).then((res) => setImagePath(res));
    GetReviews();
  }, []);

  return (
    <div>
      <div className='list-img'>
        <img src={imagePath ? imagePath : defaultImage} alt='' onClick={handleClick} />
        <a className='ico-put' onClick={AddToCart}>
          <img src={icoPut} alt='' />
        </a>
      </div>
      <div>
        <div className='text-left'>
          <p className='bold'>{props.FARM_NM}</p>
          <p className='color-black ellipsis-2'>{props.PRODUCT_NM}</p>
          <p className='color-black bold'>
            <strong className='fs-14 color-red'>{props.DISCOUNT_RATE}%</strong>{' '}
            <span className='fs-15'>{addComma(props.DISCOUNT_PRICE)}원</span>
          </p>
          <p>
            <span className='ico-star'>
              <img src={star} alt='' />
            </span>
            <span>{props.RATE}</span>｜<span>{addComma(reviewCount)}건</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShopPreview;
