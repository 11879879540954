export const SeperateTrace = (trace: string) => {
  if (!trace) return;
  const firstPart = trace.slice(0, 4);
  const secondPart = trace.slice(4, 8);
  const thirdPart = trace.slice(8, 12);
  return `${firstPart} ${secondPart} ${thirdPart}`;
};

export function MobileFormat(value: string | undefined) {
  if (typeof value === 'undefined') return null;

  if (value === null) return null;

  if (value.length === 11) {
    return value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  return value;
}

export const MoneyFormat = (value: string | undefined) => {
  if (value === undefined) {
    return 0;
  } else {
    return parseInt(value).toLocaleString('ko-KR');
  }
};

export const addComma = (num: number) => {
  return num ? num.toLocaleString('ko-KR') : 0;
};
