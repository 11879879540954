import { combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from "redux-persist/lib/storage/session";
import { composeWithDevTools } from "redux-devtools-extension"; // 개발자 도구

import { FundReducer } from "./FundReducer";
import { FarmReducer } from "./FarmReducer";
import FundSelector from "./FundSelector";
import FarmSelector from "./FarmSelector";
import UserReducer from "./UserReducer";
import DepositReducer from "./DepositReducer";
import WishlistReducer from "./WishlistReducer";
import BiddingReducer from "./BiddingReducer";
import ShopSelector from "./ShopSelector";
import NewsReducer from "./NewsReducer";
import QuestionReducer from "./QuestionReducer";
import CartReducer from "./CartReducer";
import BillReducer from "./BillReducer";
import CustomerReducer from "./CustomerReducer";

// config 작성
const persistConfig = {
    key: "root", // localStorage key 
    storage: storageSession, // localStorage
    whitelist: [ "fund", "farm", "fundSelect", "farmSelect", "user",
                 "deposit", "wishlist", "bidding", "shopSelect",
                 "news", "question", "cart", "bill", "customer" ], // target (reducer name)
}

const rootReducer = combineReducers({
    fund: FundReducer,
    farm: FarmReducer,
    fundSelect: FundSelector,
    farmSelect: FarmSelector,
    user: UserReducer,
    deposit: DepositReducer,
    wishlist: WishlistReducer,
    bidding: BiddingReducer,
    shopSelect: ShopSelector,
    news: NewsReducer,
    question: QuestionReducer,
    cart: CartReducer,
    bill: BillReducer,

    customer: CustomerReducer
});

export default persistReducer(persistConfig, rootReducer);