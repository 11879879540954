import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import LicensePreview from './LicensePreview';

import arrowUp from "../../../images/arrow_up.png";
import arrowDown from "../../../images/arrow_down.png";

const LicenseDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();

    let arr: Array<any> = [];
    const [license, setLicense] = useState<any>();


    const GetLicenseDetail = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/mybank/setting/license/${location.state?.licenseID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();

        setLicense(data[0]);
    }

    useEffect(() => {
        GetLicenseDetail();
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>오픈소스 라이선스</h1>
            </header>
            <section>
                <div className="mt-30 pd-side">
                    <p className="fs-16 bold">{license?.LICENSE_TITLE}</p>
                    <p className="mt-20">{license?.LICENSE_VERSION}</p>
                    <p className="mt-10">{license?.LICENSE_CONTENT}</p>
                </div>
            </section>
        </div>
    )
}

export default LicenseDetail;