import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Keypad from "../Keypad";

const PINcheck = () => {
    const navigate = useNavigate();
    const [pin, setPin] = React.useState("");
    const user = useSelector((state: any) => state.user);
    const [userPIN, setUserPIN] = React.useState("");

    const GetUserInfo = async () => {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + `/user/${user.USER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
        const data = await response.json();
        setUserPIN(data[0].PIN);
    }
    const NumberClick = (number: number) => {
        setPin(pin + number.toString());
    }

    const MaxClick = () => {
    }

    const ClearClick = () => {
        setPin("");
    }

    useEffect(() => {
        GetUserInfo();
    }, [])

    useEffect(() => {
        if(pin.length === 6){
            if(pin === userPIN){
                navigate("/fund/end");
            }else{
                alert("비밀번호가 일치하지 않습니다.");
                setPin("");
            }
        }
    }, [pin])

    return(
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
            </header>
            <section className="h-100n d-flex flex-column space-between">
                <div className="pd-side mt-15">
                    <p className="fs-20 bold ml-10">간편 비밀번호를 입력해주세요</p>
                </div>
                <div className="form-passowrd">
                    <input type="password" className={pin.length >= 1 ? "enter": ""} />
                    <input type="password" className={pin.length >= 2 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 3 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 4 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 5 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 6 ? "enter": ""}/>
                </div>
                <div>
                    <Keypad onNumberClick={NumberClick} onClearClick={ClearClick} onMaxClick={MaxClick} showMax={false}/>
                </div>
            </section>
        </div>
    )
}

export default PINcheck;
