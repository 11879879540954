export interface Customer {
    customerName: string;
    customerMobile: string;
    customerEmail: string;
}

export interface CustomerAction {
    type: string;
    payload: Customer | null;
}

const CustomerReducer = (state : Customer | null = null, action: CustomerAction) : Customer | null => {
    switch (action.type) {
        case 'SET_CUSTOMER':
            return action.payload;
        default:
            return state;
    }
}

export default CustomerReducer;