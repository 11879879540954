import { useState, useEffect } from 'react';
import FundBlock from './FundBlock';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const WishList = () => {
  const navigate = useNavigate();
  const [funds, setFunds] = useState<any[]>([]);
  const [cookies] = useCookies(['wishList']);

  const getFund = async () => {
    const res = await fetch(process.env.REACT_APP_SERVER_URL + `/fund/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await res.json();

    const wishlistIds = cookies.wishList.map((item: any) => item.FUNDING_ID);
    const filteredFunds = data.filter((fund: any) => wishlistIds.includes(fund.ID));
    setFunds(filteredFunds);
  };

  useEffect(() => {
    getFund();
  }, []);

  return (
    <div>
      <header className='sub'>
        <a className='btn-prev' onClick={() => navigate(-1)}>
          <span>이전</span>
        </a>
        <h1>찜목록</h1>
      </header>
      <section>
        <div className='list-group list-line pd-22'>
          {funds?.map((fund: any) => (
            <FundBlock {...fund} key={fund.ID} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default WishList;
