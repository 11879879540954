import pig from '../../images/pig.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { addComma } from '../../utils/StringFormat';
import { handleFundRate } from '../../utils/PercentCalculator';

export default function FundingEnd() {
  const location = useLocation();
  const navigate = useNavigate();

  const { fundInfo, number, deposit } = location.state;

  const fundRate = handleFundRate(fundInfo.TARGET_AMOUNT, fundInfo.CURRENT_AMOUNT);

  // const handleBidding = async () => {
  //   const response = await fetch(process.env.REACT_APP_SERVER_URL + '/bid', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       userID: user.USER_ID,
  //       fundingID: bidding.FUNDING_ID,
  //       amount: bidding.BIDDING_AMOUNT,
  //       count: bidding.BIDDING_COUNT,
  //     }),
  //   });
  //   const data = await response.json();
  //   dispatch({ type: 'SET_FUND', payload: data });
  //   navigate(`/fund/detail/fund`, { replace: true });
  // };

  // const CreateDeposit = async () => {
  //   const response = await fetch(process.env.REACT_APP_SERVER_URL + '/deposit/create', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  // userID: user.USER_ID,
  // amount: bidding.BIDDING_AMOUNT,
  // fundID: bidding.FUNDING_ID,
  //   }),
  // });
  //   await response.json();
  //   navigate('/fund/detail/fund', { replace: true });
  // };

  const handleMoveFundDetail = () => {
    navigate('/fund', {
      state: { category: 'all' },
    });
  };

  return (
    <div>
      <section className='h-100n'>
        <div className='d-flex flex-column space-between h-100 text-center'>
          <div className='pd-22'>
            <p className='mb-30 fs-15 medium'>피그팜</p>
            <div
              className='circle-progress mb-30'
              style={{ background: `linear-gradient(to bottom, #585C65 ${100 - fundRate}%, #E76B84 ${fundRate}%` }}
            >
              <div>
                <img src={pig} alt='임시 돼지' />
              </div>
            </div>
            <p className='mb-30 fs-15 medium'>{fundInfo.PIG_NM}</p>
            <p className='mb-10 fs-25 bold color-red'>펀딩 완료</p>
            <p className='mb-30 fs-25'>
              {Number(number)}C {addComma(Number(number) * fundInfo.MIN_AMOUNT)}원
            </p>
            <p className='mb-20 fs-15 medium'>은행: {account.bank}</p>
            <p className='mb-20 fs-15 medium'>계좌번호: {account.account}</p>
            <p className='mb-20 fs-15 medium'>예금주: {account.name}</p>
            <p className='mb-20 fs-15 medium'>금액: {addComma(Number(number) * fundInfo.MIN_AMOUNT)}원</p>
          </div>
          <p className='medium color-gray'>
            지금부터 12시간 이내에 입금해주세요.
            <br /> 입금이 확인되지 않을 경우 펀딩이 취소됩니다.
          </p>
          <div className='btn-area'>
            <button className='btn btn-pink' onClick={handleMoveFundDetail}>
              확인
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

const account = {
  bank: '신한',
  account: '110-123-456789',
  name: '제트플로우',
};
