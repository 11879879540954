import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import NewsPreview from '../previews/NewsPreview/NewsPreview';

const server_url = process.env.REACT_APP_SERVER_URL;

export default function News() {
  const [news, setNews] = useState([]);

  const getNews = async () => {
    try {
      const response = await fetch(`${server_url}/news`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      setNews(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <div>
      <div className='title-wrap'>
        <h2>피그팜 뉴스</h2>
      </div>
      <div className='slide-wrap'>
        <Swiper
          className='mySwiper'
          modules={[Pagination]}
          slidesPerView={'auto'}
          spaceBetween={10}
          pagination={{
            el: '.mySwiper .swiper-pagination',
            clickable: true,
          }}
        >
          {news.map((item: any) => (
            <SwiperSlide className='swiper-slide' key={item.NEWS_ID}>
              <NewsPreview {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
