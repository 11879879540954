import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CompanyInfo = () => {
    const navigate = useNavigate();

    useEffect(() => {

    }, [])

    return (
        <div id="">
          <header className="sub">
          <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
        <h1>회사소개</h1>
    </header>
    
    <section>
        <div className="mt-30 pd-side">
            <p className="fs-16 bold">주식회사 제트플로우</p>
            <p className="mt-20">대표: 김혜진</p>
            <p className="mt-10">주소: 경기도 안양시 시민대로230 D동 3452호</p>
            <p className="mt-10">사업자등록번호: 559-81-02173</p>
            <p className="mt-10">통신판매업신고번호: 제 8888-서울중구-8888호</p>
        </div>
        
        
    </section>
        </div>
    )
}

export default CompanyInfo;