import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import FundingPreview from '../previews/FundingPreview/FundingPreview';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

interface NewFundingProps {
  funds: any[];
}

export default function NewFunding({ funds }: NewFundingProps) {
  const navigate = useNavigate();

  const moveFund = () => {
    navigate('/fund', {
      state: { category: 'new' },
    });
  };

  return (
    <div>
      <div className='title-wrap'>
        <h2>신규 펀딩라인</h2>
        <span className='btn-more' onClick={moveFund}>
          더보기
        </span>
      </div>
      <div className='slide-wrap'>
        <Swiper
          className='mySwiper'
          modules={[Pagination]}
          slidesPerView={'auto'}
          spaceBetween={10}
          pagination={{
            el: '.mySwiper .swiper-pagination',
            clickable: true,
          }}
        >
          {funds.map((fund: any) => (
            <SwiperSlide key={fund.ID} className='swiper-slide'>
              <FundingPreview {...fund} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
