import React, { useState, useEffect,  } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import PaperPreview from "./PaperPreview";

const AccidentHistory = () => {
    const navigate = useNavigate();

    let arr: Array<any> = [];
    const [accidentHistory, setAccidentHistory] = useState(arr);

    const user = useSelector((state: any) => state.user);

    const GetReceipts = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/fund/receipts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "userID": user.USER_ID,
            }),
        })
        const data = await res.json();

        setAccidentHistory(data.filter((data : any) => data.BIDDING_STATUS === "사고"));
    }

    useEffect(() => {
        GetReceipts();
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>사고내역</h1>
            </header>
            <section>
                {accidentHistory.length > 0 ?
                    <div className="list-group list-line list-padding">

                        {accidentHistory.map((item: any) => (
                            <PaperPreview {...item} key={item.BIDDING_ID} />
                        ))}
                    </div> :
                    <div className="mt-40">
                        <p className="fs-15 bold color-gray text-center">발행된 현금영수증이 없습니다</p>
                    </div>
                }
            </section>
        </div>
    )
}

export default AccidentHistory;