import React, { FC, useEffect, useState } from 'react';
import icoKeepon from '../../../images/ico_keepon.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetThumbnail } from '../../../utils/ImageHandler';
import defaultImage from '../../../images/default.png';
import { addComma } from '../../../utils/StringFormat';
import { handleFundRate } from '../../../utils/PercentCalculator';

interface FundBlockProps {
  FUNDING_ID: number;
  PIG_ID: number;
  PIG_NM: string;
  PIG_AGE: number;
  PIG_GENE: string;
  MIN_AMOUNT: number;
  FUND_RATE: number;
  TARGET_AMOUNT: number;
  CURRENT_AMOUNT: number;
}

const FundBlock: FC<FundBlockProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imagePath, setImagePath] = React.useState<string>('');
  const user = useSelector((state: any) => state.user);

  const HandleClick = () => {
    dispatch({
      type: 'SELECT_FUND',
      payload: {
        fundingID: props.FUNDING_ID,
      },
    });
    navigate('/fund/detail/fund');
  };

  const HandleRibbon = async () => {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + '/ribbon', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userID: user.USER_ID,
        fundingID: props.FUNDING_ID,
        ribbon: true,
      }),
    });
    const data = await response.json();
    dispatch({ type: 'SET_WISHLIST', payload: data });
  };

  useEffect(() => {
    GetThumbnail('TB_PIG', props.PIG_ID).then((res) => setImagePath(res));
  }, []);

  return (
    <div>
      <div className='list-img' onClick={HandleClick}>
        <img style={{ aspectRatio: '1 / 1' }} src={imagePath ? imagePath : defaultImage} alt='' />
        <a className='ico-keep'>
          <img src={icoKeepon} alt='' />
        </a>
      </div>
      <div>
        <div className='list-title'>
          <p className='fs-15 bold'>{props.PIG_NM}</p>
          <a className='ico-keep' onClick={HandleRibbon}>
            <img src={icoKeepon} alt='' />
          </a>
        </div>
        <div className='funding-info' onClick={HandleClick}>
          <p>
            <span className='color-gray'>최소금액</span> {addComma(props.MIN_AMOUNT)}원
          </p>
          |
          <p>
            <span className='color-gray'>유전지수</span> {props.PIG_GENE}
          </p>
          |
          <p>
            <span className='color-gray'>나이</span> {props.PIG_AGE}개월
          </p>
        </div>
        <div className='progress' onClick={HandleClick}>
          <div
            className='progress-bar'
            style={{ width: `${handleFundRate(props.TARGET_AMOUNT, props.CURRENT_AMOUNT)}%` }}
          ></div>
        </div>
        <div className='d-flex space-between'>
          <p className='fs-14 bold'>
            <strong className='color-red'>
              {handleFundRate(props.TARGET_AMOUNT, props.CURRENT_AMOUNT).toFixed(1)}%
            </strong>
            달성
          </p>
          <p className='fs-14'>
            <span className='color-red'>97C</span> 남음
          </p>
        </div>
      </div>
    </div>
  );
};

export default FundBlock;
