import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'jquery-ui/ui/widgets/tabs.js';

import InquiryPreview from './InquiryPreview';

import icoFile from '../../../../images/ico_file.png';
import arrowUp from '../../../../images/arrow_up.png';
import arrowDown from '../../../../images/arrow_down.png';
import { useCookies } from 'react-cookie';

const $ = require('jquery');

const CustomerService = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);
  const user = cookies.user;

  const selectRef = useRef<HTMLSelectElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [buttonColor, setButtonColor] = useState<string>('btn btn-gray mb-15');

  let arr: Array<any> = [];
  const [inquires, setInquires] = useState(arr);

  const HandleSubmit = async () => {
    if (buttonColor === 'btn btn-pink mb-15') {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + '/mybank/inquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userID: user.id,
          category: selectRef.current?.value,
          title: inputRef.current?.value,
          content: textAreaRef.current?.value,
        }),
      });

      const data = await response.json();
      navigate(-1);
    }
  };

  const GetInquires = async () => {
    const res = await fetch(process.env.REACT_APP_SERVER_URL + `/mybank/inquiry/${user.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await res.json();

    setInquires(data);
  };

  useEffect(() => {
    $(function () {
      $('#condition-tabs').tabs();
    });

    GetInquires();
  }, []);

  const HandleChange = () => {
    if (inputRef.current?.value === '' || textAreaRef.current?.value === '') {
      setButtonColor('btn btn-gray mb-15');
    } else {
      setButtonColor('btn btn-pink mb-15');
    }
  };

  return (
    <div>
      <header className='sub'>
        <a className='btn-prev' onClick={() => navigate(-1)}>
          <span>이전</span>
        </a>
        <h1>고객센터</h1>
      </header>
      <section className='h-100n'>
        <div id='condition-tabs' className='tabs condition-tabs h-100'>
          <ul>
            <li>
              <a href='#condition-1'>문의하기</a>
            </li>
            <li>
              <a href='#condition-2'>문의내역</a>
            </li>
          </ul>
          {/* <!-- faq --> */}
          <div id='condition-1' className='h-100'>
            <div className='pd-22 full-sub'>
              <div>
                <div className='input-query'>
                  <select name='' id='' ref={selectRef}>
                    <option value='상품 관련 문의'>상품 관련 문의</option>
                    <option value='펀딩하기 관련 문의'>펀딩하기 관련 문의</option>
                  </select>
                  <input
                    type='text'
                    placeholder='제목을 입력해주세요. (20자 이내)'
                    className='text mt-15'
                    ref={inputRef}
                    onChange={HandleChange}
                  />
                  <textarea
                    name=''
                    id=''
                    ref={textAreaRef}
                    cols={30}
                    rows={10}
                    placeholder='내용을 입력해주세요.'
                    onChange={HandleChange}
                  ></textarea>
                </div>
                <div className='input-file'>
                  <input type='text' placeholder='파일첨부' className='text mt-15' />
                  <a>
                    <img src={icoFile} alt='' />
                  </a>
                </div>
              </div>
              <div>
                <a className={buttonColor} onClick={HandleSubmit}>
                  문의하기
                </a>
              </div>
            </div>
          </div>
          {/* <!-- //faq -->
            
                    <!-- 상품문의내역 --> */}
          <div id='condition-2' className='h-100'>
            {inquires.map((item: any) => (
              <InquiryPreview {...item} key={item.INQUIRY_ID} />
            ))}

            {/* <div>
                            <div className="d-flex space-between align-center pd-22">
                                <div>
                                    <p className="fs-10 color-gray">2022-11-11</p>
                                    <p className="mt-10">[펀딩하기 관련 문의] 제목 입력</p>
                                </div>
                                <a   className="btn-detail"><img src={arrowUp} alt="" /></a>
                            </div>
                            <div>
                                <div className="bg-rgray pd-side pd-tb-15 fs-12 bd-bottom">
                                    <p className="fs-12 bold color-gray">배송관련 문의</p>
                                    <p className="mt-10 color-gray">내용내용내용내용<br />내용내용내용내용<br />내용내용내용내용</p>
                                </div>
                                <div className="bg-rgray pd-side pd-tb-15 fs-12">
                                    <p className="bold">답변</p>
                                    <p className="mt-10">내용내용내용내용<br />내용내용내용내용<br />내용내용내용내용</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="d-flex space-between align-center pd-22">
                                <div>
                                    <p className="fs-10 color-gray">2022-11-11</p>
                                    <p className="mt-10">[펀딩하기 관련 문의] 제목 입력</p>
                                </div>
                                <a   className="btn-detail"><img src={arrowDown} alt="" /></a>
                            </div>
                        </div>

                        <div>
                            <div className="d-flex space-between align-center pd-22">
                                <div>
                                    <p className="fs-10 color-gray">2022-11-11</p>
                                    <p className="mt-10">[펀딩하기 관련 문의] 제목 입력</p>
                                </div>
                                <a   className="btn-detail"><img src={arrowDown} alt="" /></a>
                            </div>
                        </div> */}

            {/* <!--  문의내역없을경우  --> */}
            <div className='h-100n d-flex align-center space-around' style={{ display: 'none' }}>
              <p className='fs-15 bold'>문의 내역이 없습니다</p>
            </div>
            {/* <!--  문의내역없을경우  --> */}
          </div>
          {/* <!-- //상품문의내역 --> */}
        </div>
      </section>
    </div>
  );
};

export default CustomerService;
