import React, { useState, useEffect,  } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import complete from "../../../images/complet.png"


const Complete = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector((state: any) => state.user);


    useEffect(() => {

    }, [])

    return (
        <div>
            <header className="login">
            </header>
            <section className="join">
                <div className="h-100">
                    <div className="d-flex flex-column space-between complet">
                        <img src={complete} alt=""/>
                        <p className="text-center">계좌연결이<br/>완료되었습니다!</p>
                    </div>
                </div>
                <button className="btn btn-pink" onClick={() => navigate(-5)}>확인</button>
            </section>
        </div>
    )
}

export default Complete;