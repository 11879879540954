import { useEffect, useState } from 'react';
import Banner from '../components/home/Banner/Banner';
import HotFunding from '../components/home/areas/HotFunding';
import News from '../components/home/areas/News';
import NewFunding from '../components/home/areas/NewFunding';
import EndFunding from '../components/home/areas/EndFunding';
import PigfarmNow from '../components/home/areas/PigfarmNow';
import PigPrice from '../components/home/areas/PigPrice';
import StorePromote from '../components/home/areas/StorePromote';
import Footer from '../components/Footer';
import { fetchWithErrorHandling } from '../utils/fetchWithErrorHandling';
import { useCookies } from 'react-cookie';

interface HomeProps {
  setActiveLink: (link: string) => void;
  setIsActive: (isActive: boolean) => void;
}

export default function Home(props: HomeProps) {
  const [cookies, setCookie] = useCookies(['user', 'wishList']);
  const [hotFunds, setHotFunds] = useState([]);
  const [newFunds, setNewFunds] = useState([]);
  const [endFunds, setEndFunds] = useState([]);

  const getHotFund = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = await fetchWithErrorHandling('/fund/hot', options);
    setHotFunds(data);
  };

  const getNewFund = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = await fetchWithErrorHandling('/fund/new', options);
    setNewFunds(data);
  };

  const getEndFund = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = await fetchWithErrorHandling('/fund/end', options);
    setEndFunds(data);
  };

  const getWishList = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = await fetchWithErrorHandling(`/wishlist?userID=${cookies.user.id}`, options);
    setCookie('wishList', data);
  };

  useEffect(() => {
    props.setActiveLink('menu1');
    props.setIsActive(true);
    getHotFund();
    getNewFund();
    getEndFund();
    getWishList();

    return () => {
      props.setIsActive(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <header className='main'>
        <Banner />
      </header>
      <main>
        <div className='main-wrap'>
          <HotFunding funds={hotFunds} />
          <NewFunding funds={newFunds} />
          <EndFunding funds={endFunds} />
          <PigfarmNow />
          <PigPrice />
          <News />
          <StorePromote />
        </div>
      </main>
      <Footer />
    </section>
  );
}
