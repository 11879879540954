import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import Floating from '../../components/fund/fundDetail/Floating/Floating';
import FundInfo from '../../components/fund/fundDetail/FundInfo/FundInfo';
import FarmInfo from '../../components/fund/fundDetail/FarmInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import { PolarAngleAxis, PolarGrid, Radar, RadarChart } from 'recharts';
import { LabelList } from 'recharts';
import { Tooltip } from 'react-tooltip';
import { GetImages } from '../../utils/ImageHandler';
import defaultImage from '../../../images/default.png';
import styles from './FundDetail.module.css';

const server_url = process.env.REACT_APP_SERVER_URL;
const icoInfo = '/images/ico_info.png';
const arrowRightBk = '/images/arrow_right_bk.png';

export default function FundDetail() {
  const [fundInfo, setFundInfo] = useState({
    PIG_ID: 0,
    ID: 0,
    FUND_COMMENT: '',
    TRACE_ID: '',
    PREDICT_GRADE: '',
    PREDICT_PROFIT: '',
  });
  const [images, setImages] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isShow, setIsShow] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const { id, dibs } = location.state;

  const getFund = async () => {
    const response = await fetch(`${server_url}/fund/detail/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    setFundInfo(data[0]);
    handleGraphData(data[0]);
  };

  const seperateTrace = (trace: string) => {
    if (!trace) return;
    const firstPart = trace.slice(0, 4);
    const secondPart = trace.slice(4, 8);
    const thirdPart = trace.slice(8, 12);
    return `${firstPart} ${secondPart} ${thirdPart}`;
  };

  const toggleShow = () => {
    setIsShow(!isShow);
  };

  const handleGraphData = (data: any) => {
    const arr: any = [
      {
        subject: '냉도체중',
        A: data.PIG_CARCASS,
        fullMark: 100,
      },
      {
        subject: '등지방두께',
        A: data.PIG_THICK,
        fullMark: 100,
      },
      {
        subject: '근내지방도',
        A: data.PIG_MARBLING,
        fullMark: 100,
      },
      {
        subject: 'Geography',
        A: 45,
        fullMark: 100,
      },
    ];

    setGraphData(arr);
  };

  const renderCustomizedLabel = (props: any) => {
    const { x, y, value } = props;

    return (
      <g>
        <text x={x} y={y} fontSize='1.1em' fill='blue' textAnchor='middle' dominantBaseline='middle'>
          {value}
        </text>
      </g>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getFund();

    return () => {
      setIsShow(false);
    };
  }, []);

  useEffect(() => {
    GetImages('TB_PIG', fundInfo.PIG_ID).then((response) => {
      setImages(response);
    });
  }, [fundInfo]);

  return (
    <section className={styles.container}>
      <header className='sub funding'>
        <span className='btn-prev left' onClick={() => navigate(-1)}>
          <span>이전</span>
        </span>
        <Swiper
          className='headerSwiper'
          modules={[Navigation, Pagination]}
          pagination={{
            el: '.headerSwiper .swiper-pagination',
            type: 'fraction',
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
        >
          {images.map(({ IMG_ID, SERVER_IMG_PATH }) => (
            <SwiperSlide className={styles.swiper_img} key={IMG_ID}>
              <img src={SERVER_IMG_PATH} alt='돼지 이미지' />
            </SwiperSlide>
          ))}
          <div className='swiper-pagination'></div>
        </Swiper>
      </header>
      <main>
        <div>
          <FundInfo fundInfo={fundInfo} />
          <div className='farm-detail'>
            <h2>핵심 포인트</h2>
            <div style={{ height: '100px', background: '#E8E9EA', padding: '27px 22px' }}>
              <p>{fundInfo.FUND_COMMENT}</p>
            </div>
          </div>

          <div className='farm-detail'>
            <h2>
              유전지수
              <button className='ico' id='gene' data-tooltip-content='돼지의 등급입니다.'>
                <img className={styles.tooltip_img} src={icoInfo} alt='툴팁 아이콘' />
                <Tooltip anchorId='gene' />
              </button>
            </h2>
            <div ref={ref} style={{ width: '100%', height: '300px' }}>
              {graphData.length > 0 && (
                <RadarChart
                  outerRadius={90}
                  width={ref.current?.offsetWidth}
                  height={ref.current?.offsetHeight}
                  data={graphData}
                >
                  <PolarGrid />
                  <PolarAngleAxis dataKey='subject' />
                  <Radar dataKey='A' stroke='#8884d8' fill='#8884d8' fillOpacity={0.6}>
                    <LabelList dataKey='A' position='insideEnd' content={renderCustomizedLabel} />
                  </Radar>
                </RadarChart>
              )}
            </div>
            <div className='unit-num'>
              <dl>
                <dt>축산물이력번호</dt>
                <dd onClick={() => navigate('/trace')}>
                  {seperateTrace(fundInfo.TRACE_ID)}
                  <button className='btn-detail'>
                    <img src={arrowRightBk} alt='' />
                  </button>
                </dd>
              </dl>
            </div>
          </div>

          <div className='farm-detail'>
            <div className='d-flex space-between align-center'>
              <h2>예상 결과</h2>
              <div>
                <label className='toggle' htmlFor='myToggle'>
                  <input className='toggle__input' name='' type='checkbox' id='myToggle' onClick={toggleShow} />
                  <div className='toggle__fill'></div>
                </label>
              </div>
            </div>
            <div>
              <dl className='space-between mb-10'>
                <dt className='color-black'>예상 등급</dt>
                {isShow ? (
                  <dd className='color-blue'>{fundInfo.PREDICT_GRADE}</dd>
                ) : (
                  <dd className='color-gray'>숨김</dd>
                )}
              </dl>
              <dl className='space-between'>
                <dt className='color-black'>예상 수익률</dt>
                {isShow ? (
                  <dd className='color-blue'>{fundInfo.PREDICT_PROFIT}%</dd>
                ) : (
                  <dd className='color-gray'>숨김</dd>
                )}
              </dl>
            </div>
          </div>
          <FarmInfo {...fundInfo} />
          <div className='d-flex space-between pd-22 bd-bottom' onClick={() => navigate('/insurance')}>
            <p className='fs-18 bold'>사고시 보장내용</p>
            <button className='btn-detail'>
              <img src={arrowRightBk} alt='' />
            </button>
          </div>
          <div className='d-flex space-between pd-22 bd-bottom' />
          <div className='d-flex space-between pd-22 bd-bottom' />
        </div>
      </main>
      <footer>
        <Floating fundInfo={fundInfo} isDibs={dibs} />
      </footer>
    </section>
  );
}
