import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SelectBank = () => {
    const navigate = useNavigate();

    useEffect(() => {

    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
            </header>
            <section>
                <div className="pd-side mt-15">
                    <p className="fs-20 bold">연결할 은행을 선택해주세요</p>
                    <p className="medium color-gray mt-10 ">예치금을 출금할 개인계좌를 등록합니다</p>
                </div>
                <ul className="bank-list">
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "NH농협"}})}>NH농협</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "우리"}})}>우리</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "신한"}})}>신한</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "KB국민"}})}>KB국민</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "하나"}})}>하나</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "씨티"}})}>씨티</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "IBK기업"}})}>IBK기업</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "케이뱅크"}})}>케이뱅크</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "카카오뱅크"}})}>카카오뱅크</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "새마을"}})}>새마을</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "부산"}})}>부산</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "경남"}})}>경남</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "광주"}})}>광주</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "전북"}})}>전북</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "신협"}})}>신협</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "SC제일"}})}>SC제일</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "KDB산업"}})}>KDB산업</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "대구"}})}>대구</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "제주"}})}>제주</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "우체국"}})}>우체국</a></li>
                    <li><a onClick={() => navigate('/mybank/withdrawAccount/InputAccountNumber', {state: {bankName: "수협"}})}>수협</a></li>
                </ul>
            </section>
        </div>
    )
}

export default SelectBank;