const server_url = process.env.REACT_APP_SERVER_URL;

export const fetchWithErrorHandling = async (path: string, options: any) => {
  try {
    const response = await fetch(`${server_url}${path}`, options);

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch error:', error);
  }
};
