import { useEffect, useState } from 'react';
import { GetThumbnail } from '../../../utils/ImageHandler';
import { useNavigate } from 'react-router-dom';
import defaultImage from '../../../images/default.png';
import { addComma } from '../../../utils/StringFormat';

interface FarmFundProps {
  PIG_ID: number;
  FUND_NM: string;
  TARGET_AMOUNT: number;
  FUND_STATUS: string;
}

export default function FarmFund(props: FarmFundProps) {
  const [imgPath, setImgPath] = useState('');

  const navigate = useNavigate();

  const moveFarmPig = () => {
    navigate('/farm/pig', { state: { pigID: props.PIG_ID } });
  };

  useEffect(() => {
    GetThumbnail('TB_PIG', props.PIG_ID).then((res) => setImgPath(res));
  }, []);

  return (
    <div className='d-flex space-between align-center' onClick={moveFarmPig}>
      <div className='d-flex space-between align-center'>
        <p className='mr-10'>
          <img src={imgPath ? imgPath : defaultImage} alt='' style={{ width: '59px' }} />
        </p>
        <div className='list-title'>
          <p className='fs-15 bold'>{props.FUND_NM}</p>
          <p>{addComma(props.TARGET_AMOUNT)}원</p>
        </div>
      </div>
      <div data-brackets-id='1848' className='keyword d-block'>
        <p data-brackets-id='1849'>{props.FUND_STATUS}</p>
      </div>
    </div>
  );
}
