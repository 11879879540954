import React, { FC, useEffect, useState } from "react";
import icoKeepon from "../../images/ico_keepon.png";
import { addComma } from "../../utils/StringFormat";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetThumbnail } from "../../utils/ImageHandler";
import defaultImage from "../../images/default.png";

interface RequestBlockProps {
    FUNDING_ID: number;
    PIG_ID: number;
    PIG_NM: string;
    PIG_AGE: number;
    PIG_GENE: string;
    MIN_AMOUNT: number;
    FUND_RATE: number;
    AMOUNT: number;
    EXPIRED_DT : string;
}

const RequestBlock : FC<RequestBlockProps> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [imagePath, setImagePath] = React.useState<string>("");

    const HandleClick = () => {
        dispatch({
            type: "SELECT_FUND",
            payload: {
                fundingID: props.FUNDING_ID,
            }
        });
        navigate("/fund/detail/fund");
    };

    useEffect(() => {
        GetThumbnail("TB_PIG", props.PIG_ID).then((res) => setImagePath(res));
    }, []);

    return(
        <div>
            <div className="list-img" onClick={HandleClick}> 
                <img src={imagePath ? imagePath : defaultImage} alt="" />
                <a className="ico-keep"><img src={icoKeepon} alt="" /></a>
            </div>
            <div>
                <div className="list-title">
                    <p className="fs-15 bold">{props.PIG_NM}</p>
                </div>
                <div className="funding-info" onClick={HandleClick}>
                    <p><span className="color-gray">요청금액</span> {addComma(props.AMOUNT)}원</p>
                    <p><span className="color-gray">유전지수</span> {props.PIG_GENE}</p>
                    <p><span className="color-gray">나이</span> {props.PIG_AGE}개월</p>
                </div>
                <div className="progress" onClick={HandleClick}>
                    <div className="progress-bar" style={{width: `${props.FUND_RATE}%`}}></div>
                </div>
                <div className="d-flex space-between">
                    <p className="fs-14 bold"><strong className="color-red"></strong> 입금기한</p>
                    <p className="fs-14"><span className="color-red">날짜</span> {props.EXPIRED_DT.substring(0,10)}</p>
                    <p className="fs-14"><span className="color-red">시간</span> {props.EXPIRED_DT.substring(11,19)}</p>
                </div>
            </div>
        </div>
    )
}

export default RequestBlock;