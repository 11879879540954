import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import bigStar from '../../images/big_star.png';
import bigStarGray from '../../images/big_star_gray.png';

const RateReview = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);
  const location = useLocation();

  const [buttonColor, setButtonColor] = useState<string>('btn btn-gray mb-20');

  const [rate, setRate] = useState(0);

  const HandleSetRate = (rate: number) => {
    setRate(rate);
    setButtonColor('btn btn-pink mb-20');
  };

  useEffect(() => {}, []);

  return (
    <div className='review-div'>
      <header className='sub'>
        <a className='btn-close' onClick={() => navigate(-1)}>
          <span>닫기</span>
        </a>
      </header>
      <section className='one-page'>
        <div className='review-page'>
          <div>
            <p>
              <img
                src={process.env.REACT_APP_SERVER_URL + location.state.imagePath}
                alt=''
                style={{ width: '105px' }}
              />
            </p>
            <p className='mt-10'>{location.state.orderDetail.PRODUCT_NM}</p>
            <p className='fs-20 bold mt-20'>이 상품 어땠나요?</p>
            <div className='mt-20'>
              <img src={rate > 0 ? bigStar : bigStarGray} alt='' onClick={() => HandleSetRate(1)} />
              <img src={rate > 1 ? bigStar : bigStarGray} alt='' onClick={() => HandleSetRate(2)} />
              <img src={rate > 2 ? bigStar : bigStarGray} alt='' onClick={() => HandleSetRate(3)} />
              <img src={rate > 3 ? bigStar : bigStarGray} alt='' onClick={() => HandleSetRate(4)} />
              <img src={rate > 4 ? bigStar : bigStarGray} alt='' onClick={() => HandleSetRate(5)} />
            </div>
          </div>
        </div>
        <div>
          <a
            className={buttonColor}
            onClick={() => {
              rate > 0
                ? navigate('/review/comment', { state: { orderDetail: location.state.orderDetail, rate: rate } })
                : console.log('별점을 설정해주세요');
            }}
          >
            다음
          </a>
        </div>
      </section>
    </div>
  );
};

export default RateReview;
