import React, { useState, useEffect,  } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PINKeypad from "../../../PINKeypad";



const CheckPIN = () => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);

    const [pin, setPin] = React.useState("");

    const handleNumberClick = (num: number) => {
        setPin(pin + num.toString());
    }
    const handleDeleteClick = () => {
        setPin(pin.substring(0, pin.length - 1));
    }
    const handleClearClick = () => {
        setPin("");
    }

    const keypadProps = {
        onNumberClick: handleNumberClick,
        onClearClick: handleClearClick,
        onDeleteClick: handleDeleteClick,
    }
    useEffect(() => {

    }, [])

    useEffect(() => {
        if(pin.length === 6){
            if(pin === user.PIN){
                navigate(-1);
            }
            else {
                alert("비밀번호가 일치하지 않습니다.");
                setPin("");
            }
        }
    }, [pin])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
            </header>
            <section className="h-100n d-flex flex-column space-between">
                <div className="pd-side mt-15">
                    <p className="fs-20 bold">출금과 거래에 사용될<br/> 비밀번호를 입력해주세요</p>
                </div>
                <div className="form-passowrd">
                    <input type="password" className={pin.length >= 1 ? "enter": ""} />
                    <input type="password" className={pin.length >= 2 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 3 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 4 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 5 ? "enter": ""}/>
                    <input type="password" className={pin.length >= 6 ? "enter": ""}/>
                </div>
                <div>
                    <PINKeypad {...keypadProps} />
                </div>
                
            </section>
        </div>
    )
}

export default CheckPIN;