import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GetThumbnail } from '../../../../utils/ImageHandler';
import styles from './FarmPreview.module.css';

export interface FarmPreviewProps {
  FARM_ID: number;
  FARM_NM: string;
  REGION: string;
  ESTABLISHED_YEAR: string;
  PREV_YEAR_GRADE: string;
}

const server_url = process.env.REACT_APP_SERVER_URL;

export default function FarmPreview(props: FarmPreviewProps) {
  const [fundingStatus, setFundingStatus] = useState(false);
  const [fundingInfo, setFundingInfo] = useState([]);
  const [imgPath, setImgPath] = useState('');
  const [tags, setTags] = useState([]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/fund/detail/farm', { state: { farm: props, fundingInfo } });
  };

  const getFarmTag = async () => {
    const response = await fetch(`${server_url}/tag/${props.FARM_ID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    setTags(data);
  };

  const getFunding = async () => {
    const response = await fetch(`${server_url}/check/farmfunding/${props.FARM_ID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    if (data.length > 0) setFundingStatus(true);
    else setFundingStatus(false);
    setFundingInfo(data);
  };

  useEffect(() => {
    GetThumbnail('TB_FARM', props.FARM_ID).then(setImgPath);
    getFarmTag();
    getFunding();
  }, []);

  return (
    <div className={styles.farm_box} onClick={handleClick}>
      {fundingStatus && <p className={styles.funding_tag}>펀딩중</p>}
      <div className={styles.farm_img}>
        <img src={imgPath ? imgPath : '/images/default.png'} alt='' />
      </div>
      <div className='farm-box-wrap'>
        <p className='title'>{props.FARM_NM}</p>
        <div className='farm-info'>
          <p>
            펀딩 중 <span className='color-red'>{fundingInfo.length}</span> 마리
          </p>
          <p>
            경력 <span className='color-red'>{new Date().getFullYear() - Number(props.ESTABLISHED_YEAR)}년</span> ·
            작년생산등급 <span className='color-gray'>{props.PREV_YEAR_GRADE}</span>
          </p>
        </div>
        <div className={styles.keyword}>
          {tags.map((tag: any) => (
            <p key={tag.TAG_ID}>{tag.TAG_NM}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
