import { useEffect, useState } from 'react';
import FundPreview from './FundPreView/FundPreview';
import { useLocation } from 'react-router-dom';
import { Category } from '@mui/icons-material';
import { useCookies } from 'react-cookie';

interface FundViewProps {
  category: string;
}

export default function FundView({ category }: FundViewProps) {
  const [cookies] = useCookies(['user']);
  const [funds, setFunds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [view, setView] = useState('grid');
  const [wishList, setWishList] = useState([]);
  // const [sortValue, setSortValue] = useState('ascPrice');

  // const location = useLocation();

  const getFund = async () => {
    try {
      const response = await fetch(`${server_url}/fund/${selectedCategory}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      setFunds(data);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const sortValue = e.target.value;
  //   const sortedFund = [...funds];

  //   // if (sortValue === 'descRate') {
  //   //   sortedFund.sort((a, b) => b.FUND_RATE - a.FUND_RATE);
  //   // } else if (sortValue === 'ascRate') {
  //   //   sortedFund.sort((a, b) => a.FUND_RATE - b.FUND_RATE);
  //   // } else if (sortValue === 'descGene') {
  //   //   sortedFund.sort((a, b) => b.PIG_GENE - a.PIG_GENE);
  //   // } else if (sortValue === 'ascGene') {
  //   //   sortedFund.sort((a, b) => a.PIG_GENE - b.PIG_GENE);
  //   // } else if (sortValue === 'descPrice') {
  //   //   sortedFund.sort((a, b) => b.MIN_AMOUNT - a.MIN_AMOUNT);
  //   // } else if (sortValue === 'ascPrice') {
  //   //   sortedFund.sort((a, b) => a.MIN_AMOUNT - b.MIN_AMOUNT);
  //   // }
  //   // setFunds(sortedFund);
  // };

  const getWishList = async () => {
    try {
      const response = await fetch(`${server_url}/wishlist?userID=${cookies.user.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      setWishList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const category = e.currentTarget.value;
    setSelectedCategory(category);
  };

  const ViewChangeHandler = () => {
    if (view === 'grid') setView('list');
    else setView('grid');
  };

  useEffect(() => {
    getFund();
    // if (location.state) {
    //   setSelectedCategory(location.state.category);
    // }
  }, [selectedCategory]);

  useEffect(() => {
    getWishList();
  }, []);

  return (
    <div id='subtab-1'>
      <div className='sort-group'>
        <div>
          <button
            key={'all'}
            value={'all'}
            className={selectedCategory === 'all' ? 'active' : ''}
            onClick={handleCategoryChange}
          >
            전체
          </button>
          <button
            key={'hot'}
            value={'hot'}
            className={selectedCategory === 'hot' ? 'active' : ''}
            onClick={handleCategoryChange}
          >
            인기
          </button>
          <button
            key={'new'}
            value={'new'}
            className={selectedCategory === 'new' ? 'active' : ''}
            onClick={handleCategoryChange}
          >
            신규
          </button>
          <button
            key={'end'}
            value={'end'}
            className={selectedCategory === 'end' ? 'active' : ''}
            onClick={handleCategoryChange}
          >
            마감임박
          </button>
        </div>
        <button className={view === 'grid' ? 'btn-list' : 'btn-block'} onClick={ViewChangeHandler}>
          <span>리스트정렬</span>
        </button>
      </div>
      {/* <div className='sort-select'>
        <select name='sort' id='sort' title='dasdsa' defaultValue={sortValue} onChange={handleSortChange}>
          <option key='descRate' value='descRate'>
            달성률 높은순
          </option>
          <option key='ascRate' value='ascRate'>
            달성률 낮은순
          </option>
          <option key='descGene' value='descGene'>
            유전지수 높은순
          </option>
          <option key='ascGene' value='ascGene'>
            유전지수 낮은순
          </option>
          <option key='descPrice' value='descPrice'>
            높은 가격순
          </option>
          <option key='ascPrice' value='ascPrice'>
            낮은 가격순
          </option>
        </select>
      </div> */}

      <div className={view === 'grid' ? 'list-group list-block' : 'list-group list-line'}>
        {funds.map((fund: any) => (
          <FundPreview key={fund.ID} fund={fund} wishList={wishList} />
        ))}
      </div>
    </div>
  );
}

const server_url = process.env.REACT_APP_SERVER_URL;
