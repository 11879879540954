import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import arrowUp from "../../../images/arrow_up.png";
import arrowDown from "../../../images/arrow_down.png";

import arrowRightBK from "../../../../images/arrow_right_bk.png";


export interface LicensePreviewProps {
    LICENSE_ID: number;
    LICENSE_TITLE: string;
    LICENSE_VERSION: string;
    LICENSE_CONTENT: string;
}

const LicensePreview: FC<LicensePreviewProps> = (props) => {
    const navigate = useNavigate();

    // const [isActive, setActive] = useState(false);

    // const handleClick = () => {
    //     setActive(!isActive);
    // }

    const ClickHandler= () => {
        navigate('/mybank/setting/LicenseDetail', {state: {licenseID: props.LICENSE_ID}})
    }


    useEffect(() => {
        return () => {
        }

    }, []);
    return (
        <div className="mb-20 ml-25 mr-25">
            <div className="d-flex space-between align-center pd-15 bg-gray" onClick={ClickHandler}>
                <div>
                    <p className="fs-15 medium">{props.LICENSE_TITLE}</p>
                    <p className="mt-5 color-gray">{props.LICENSE_VERSION}</p>
                </div>
                <a className="btn-detail"><img src={arrowRightBK} alt=""/></a>
            </div>
            {/* <div className="d-flex space-between align-center pd-22" onClick={handleClick}>
                <div>
                    <p className="fs-10 color-gray">{props.NOTICE_DATE.substring(0, 10)}</p>
                    <p className="mt-10">{props.NOTICE_TITLE}</p>
                </div>
                <a className="btn-detail"><img src={isActive ? arrowUp : arrowDown} alt="" /></a>
            </div>
            <div className="bg-rgray pd-side pd-tb-15 fs-12" style={{ display: isActive ? "block" : "none" }}>
                <p>{props.NOTICE_CONTENT}</p>
            </div> */}
        </div>
    )
}

export default LicensePreview;