import FarmPreview from './FarmPreview/FarmPreview';
import { useEffect, useState } from 'react';

const server_url = process.env.REACT_APP_SERVER_URL;

export default function FarmView() {
  const [selectedCategory, setSelectedCategory] = useState('전체');
  const [farms, setFarms] = useState([]);

  const getFarms = async () => {
    try {
      const response = await fetch(
        `${server_url}/farmInit?category=${selectedCategory === '전체' ? '' : selectedCategory}`,
        {
          method: 'GET',
        }
      );
      const data = await response.json();

      setFarms(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFarms();
  }, [selectedCategory]);

  return (
    <div id='subtab-2'>
      <div className='sort-group'>
        <div>
          <button
            key='전체'
            className={selectedCategory === '전체' ? 'active' : ''}
            onClick={() => setSelectedCategory('전체')}
          >
            전체
          </button>
          <button
            key='경기'
            className={selectedCategory === '경기' ? 'active' : ''}
            onClick={() => setSelectedCategory('경기')}
          >
            경기
          </button>
          <button
            key='강원'
            className={selectedCategory === '강원' ? 'active' : ''}
            onClick={() => setSelectedCategory('강원')}
          >
            강원
          </button>
          <button
            key='경상'
            className={selectedCategory === '경상' ? 'active' : ''}
            onClick={() => setSelectedCategory('경상')}
          >
            경상
          </button>
          <button
            key='전라'
            className={selectedCategory === '전라' ? 'active' : ''}
            onClick={() => setSelectedCategory('전라')}
          >
            전라
          </button>
        </div>
      </div>
      {farms.map((farm: any) => (
        <FarmPreview {...farm} key={farm.FARM_ID} />
      ))}
    </div>
  );
}
