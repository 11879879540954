import React from "react";
import arrowLeft from "../images/arrow_left.png";

type Props = {
    onNumberClick: (number: number) => void;
    onMaxClick: () => void;
    onClearClick: () => void;
    showMax: boolean;
};

const Keypad: React.FC<Props> = (props) => {

    return (
        <div className="keypad">
            <div onClick={() => props.onNumberClick(1)}>1</div>
            <div onClick={() => props.onNumberClick(2)}>2</div>
            <div onClick={() => props.onNumberClick(3)}>3</div>
            <div onClick={() => props.onNumberClick(4)}>4</div>
            <div onClick={() => props.onNumberClick(5)}>5</div>
            <div onClick={() => props.onNumberClick(6)}>6</div>
            <div onClick={() => props.onNumberClick(7)}>7</div>
            <div onClick={() => props.onNumberClick(8)}>8</div>
            <div onClick={() => props.onNumberClick(9)}>9</div>
            <div onClick={() => props.onMaxClick()}>최대</div>
            <div onClick={() => props.onNumberClick(0)}>0</div>
            <div onClick={() => props.onClearClick()}><img src={arrowLeft} alt="" style={{ width: "19px" }} /></div>
        </div>
    )
}

export default Keypad;