import React, { useState, useEffect,  } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import arrowRightBK from "../../../images/arrow_right_bk.png";
import icoKeep from "../../../images/ico_keep.png";
import icoKeepon from "../../../images/ico_keepon.png";
import imgFunding from "../../../images/img_funding.png";

import PaperPreview from "./PaperPreview";

const CancelHistory = () => {
    const navigate = useNavigate();

    let arr: Array<any> = [];
    const [cancelHistory, setCancelHistory] = useState(arr);

    const user = useSelector((state: any) => state.user);

    const GetReceipts = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/fund/receipts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "userID": user.USER_ID,
            }),
        })
        const data = await res.json();

        setCancelHistory(data.filter((data : any) => data.BIDDING_STATUS === "해지"));
    }

    useEffect(() => {
        GetReceipts();
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>중도해지내역</h1>
            </header>
            <section className="h-100n">
                {cancelHistory.length > 0 ?
                    <div className="list-group list-line list-padding">
                        {cancelHistory.map((item: any) => (
                            <PaperPreview {...item} key={item.BIDDING_ID} />
                        ))}
                    </div> :

                    <div className="mt-40">
                        <p className="fs-15 bold color-gray text-center">중도해지내역이 없습니다</p>
                    </div>
                }
            </section>
        </div>
    )
}

export default CancelHistory;