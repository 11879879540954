import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LicensePreview from './LicensePreview';

import arrowUp from "../../../images/arrow_up.png";
import arrowDown from "../../../images/arrow_down.png";

const License = () => {
    const navigate = useNavigate();

    let arr: Array<any> = [];
    const [licenses, setLicenses] = useState(arr);


    const GetLicense = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/mybank/setting/license", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();

        setLicenses(data);
    }

    useEffect(() => {
        GetLicense();
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>오픈소스 라이선스</h1>
            </header>
            <section>
                <div>
                    {licenses.map((item: any) => (
                        <LicensePreview {...item} key={item.LICENSE_ID} />
                    ))}
                </div>
            </section>
        </div>
    )
}

export default License;