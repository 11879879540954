import { useState, useEffect } from "react";
import axios from "axios";
import pig from "../../images/pig.png";
import { useNavigate } from "react-router-dom";
import { addComma } from "../../utils/StringFormat";
import { useSelector } from "react-redux";

export default function PaymentSuccess() {
    const navigate = useNavigate();
    const paymentKey = new URL(window.location.href).searchParams.get("paymentKey");
    const orderId = new URL(window.location.href).searchParams.get("orderId");
    const amount = new URL(window.location.href).searchParams.get("amount");
    const customer = useSelector((state: any) => state.customer);
    const bill = useSelector((state: any) => state.bill);
    const user = useSelector((state: any) => state.user);
    const cart = useSelector((state: any) => state.cart);

    useEffect(() => {
        ConfirmPayment();
        InsertOrderTable();
        RemoveCartItems();
    }, []);

    return (
        <div>
            <section className="h-100n">
                <div className="d-flex flex-column space-between h-100 text-center">
                    <div className="pd-22">
                        <p className="mb-30 fs-15 medium">{""}</p>
                        <div className="circle-progress mb-30" style={{background: "linear-gradient(to bottom, #585C65 50%, #E76B84 50%"}}>
                            <div>
                                <img src={pig} alt="" />
                            </div>
                        </div>
                        <p className="mb-30 fs-15 medium">{customer.orderName}</p>
                        <p className="mb-10 fs-25 bold color-red">결제 완료</p>
                        <p className="fs-25">{addComma(parseInt(amount!))}원</p>
                        
                    </div>
                    <p className="medium color-gray">쇼핑 {'>'} 마이페이지에서<br /> 구매내역을 확인할 수 있습니다.</p>
                    <div className="btn-area">
                        <button className="btn btn-pink" onClick={()=>navigate('/shop')}>확인</button>
                    </div>
                </div>
            </section>
        </div>
    )

    async function RemoveCartItems() {

        const url = process.env.REACT_APP_SERVER_URL + '/shop/removeCartItems';
        const headers = {
            'Content-Type': 'application/json',
        };
        const data = {
            SHOPPING_CART_ID: cart.items.map((item: any) => item.SHOPPING_CART_ID),
        };

        axios.post(url, data, { headers: headers })
        .then((res) => {
            console.log("removeCartItems", res);
        })
        .catch((err) => {
            console.log(err);
        });

    }

    async function ConfirmPayment() {
        const url = 'https://api.tosspayments.com/v1/payments/confirm';
        const headers = {
            'Authorization': 'Basic dGVzdF9za196WExrS0V5cE5BcldtbzUwblgzbG1lYXhZRzVSOg==',
            'Content-Type': 'application/json',
        };
        const data = {
            paymentKey: paymentKey,
            amount: amount,
            orderId: orderId,
        };

        axios.post(url, data, { headers: headers })
        .then((res) => {
            console.log("confirmPayment", res);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    async function InsertOrderTable () {
        const url = process.env.REACT_APP_SERVER_URL + '/shop/insertOrderTable';
        const headers = {
            'Content-Type': 'application/json',
        };
        const data = {
            USER_ID: user.USER_ID,
            ADDR_ID: customer.addrID,
            ORIGINAL_AMOUNT: bill.OriginalAmount,
            DELIVERY_AMOUNT: bill.DeliveryAmount,
            DISCOUNT_AMOUNT: bill.DiscountAmount,
            TOTAL_AMOUNT: bill.TotalAmount,
            ORDER_NAME: customer.orderName,
            CUSTOMER_NAME: customer.customerName,
            CUSTOMER_MOBILE: customer.customerMobile,
            CUSTOMER_EMAIL: customer.customerEmail,
            CUSTOMER_MESSAGE: customer.customerMessage,
            CART: cart.items,
        };

        axios.post(url, data, { headers: headers })
        .then((res) => {
            console.log("InsertOrderTable", res);
        })
        .catch((err) => {
            console.log(err);
        });
    }

}