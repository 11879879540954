import React, { FC, useEffect } from "react";

interface FarmInfoProps {
    FARM_ID: number;
    FARM_NM: string;
    ADDR1: string;
    ESTABLISHED_YEAR: string;
    PREV_YEAR_GRADE: string;
    FEED: string;
    SIZE: number;
    AVAIL_PIGS: number;
}

const FarmInfo: FC<FarmInfoProps> = (props) => {
    const [tags, setTags] = React.useState<any[]>([]);

    const GetFarmTag = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/tag/${props.FARM_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();
        setTags(data);
    }

    useEffect(() => {
        GetFarmTag();
    }, []);

    return(
        <div>
            <div className="farm-detail">
                <h2 className="mb-5">{props.FARM_NM}</h2>
                <p className="mb-10">{props.ADDR1}</p>
                <div className="keyword">
                    {tags.map((tag, index) => (
                        <p key={index}>{tag.TAG_NM}</p>
                    ))}
                </div>
                <div>
                    <dl className="mb-20">
                        <dt className="color-gray">경력</dt>
                        <dd>{props.ESTABLISHED_YEAR}년</dd>
                    </dl>
                    <dl className="mb-20">
                        <dt className="color-gray">전년생산등급</dt>
                        <dd>{props.PREV_YEAR_GRADE}</dd>
                    </dl>
                    <dl className="mb-20">
                        <dt className="color-gray">사육중 자료</dt>
                        <dd>사진 제공</dd>
                    </dl>
                    <dl>
                        <dt className="color-gray">사료</dt>
                        <dd>{props.FEED}</dd>
                    </dl>
                </div>
            </div>
            <div className="farm-detail d-flex bold fs-15">
                <dl className="w-50p">
                    <dt className="color-black">농장규모</dt>
                    <dd className="color-red">{props.SIZE}㎡</dd>
                </dl>
                <dl className="w-50p">
                    <dt className="color-black">사육가능</dt>
                    <dd className="color-red">{props.AVAIL_PIGS}마리</dd>
                </dl>
            </div>
    </div>
    )
}

export default FarmInfo;