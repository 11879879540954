import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isPasswordValid } from "../../../utils/PasswordValidator";
import arrowRightBK from "../../../images/arrow_right_bk.png";
import checkOn from "../../../images/check_on.png";
import checkOff from "../../../images/check_off.png";

const EditProfile = () => {
    const navigate = useNavigate();

    
    const user = useSelector((state: any) => state.user);
    

    let arr: Array<any> = [];
    const [UserInfo, setUserInfo] = useState<any>();
    const [UserName, setUserName] = useState<any>();
    const [UserPhone, setUserPhone] = useState<any>();
    const [UserEmail, setUserEmail] = useState<any>();
    const [UserEasyPass, setUserEasyPass] = useState<any>();
    const [UserPass, setUserPass] = useState<any>();
    const [UserPassCorrect, setUserPassCorrect] = useState<any>();

    const [passwordTestResult, setPasswordTestResult] = React.useState<any>({
        message: "",
        isValid: false,
        hasEight: false,
        hasNumber: false,
        hasEnglish: false
    });

    const GetUserInfo = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/mybank/editprofile/${user.USER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        //setUserInfo(data[0]);
        setUserName(data[0].KAKAO_NICK);

        if(data[0].MOBILE != null)
            setUserPhone(data[0].MOBILE);
        else
            setUserPhone("");
        
        if(data[0].EMAIL != null)
            setUserEmail(data[0].EMAIL);
        else
            setUserEmail("");
        
        if(data[0].EASY_PASS != null)
            setUserEasyPass(data[0].EASY_PASS);
        else
            setUserEasyPass("");

        if(data[0].PASSWD != null)
            setUserPass(data[0].PASSWD);
        else
            setUserPass("");

        setUserPassCorrect("");
    }

    useEffect(() => {
        GetUserInfo();
    }, [])
    const inputRef = useRef<HTMLInputElement>(null);

    const HandleChange = (e: any) => {
        console.log(e.target.value);
        if(e.target.id == "name")
            setUserName(e.target.value);
        else if(e.target.id == "Phone")
            setUserPhone(e.target.value);
        else if(e.target.id == "Email")
            setUserEmail(e.target.value);
        else if(e.target.id == "EasyPass")
            setUserEasyPass(e.target.value);
        else if(e.target.id == "Pass")
        {
            setPasswordTestResult(isPasswordValid(e.target.value));
            
            setUserPass(e.target.value);
        }
        else if(e.target.id == "PassCorrect")
        {
            setUserPassCorrect(e.target.value);
        }
        
    }

    return (
        <div id="">
            <header className="sub shopsub">
                <div className="top">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                    <h1>프로필 편집</h1>
                    <a href="#" className="fs-15 medium">저장</a>
                </div>
            </header>
                
    <section>
        <div className="form-group mt-20 pd-side">
            <label htmlFor="">이름</label>
            <input type="text" value={UserName} id="name" className="text mt-15" ref={inputRef} onChange={HandleChange}></input>
        </div>
        <div className="form-group mt-20 pd-side">
            <label htmlFor="">전화번호</label>
            <input type="number" value={UserPhone} id="Phone" className="text mt-15" ref={inputRef} onChange={HandleChange}></input>
        </div>
        <div className="form-group mt-20 pd-side">
            <label htmlFor="">이메일</label>
            <input type="text" value={UserEmail} id="Email" className="text mt-15" ref={inputRef} onChange={HandleChange}></input>
        </div>
        <div className="form-group mt-20 pd-side">
            <label htmlFor="">간편 비밀번호</label>
            <input type="password" value={UserEasyPass} id="EasyPass" className="text mt-15" ref={inputRef} onChange={HandleChange}></input>
        </div>
        <div className="form-group mt-20 pd-side">
            <label htmlFor="">계정 비밀번호</label>
            <input type="password" value={UserPass} id="Pass" className="text mt-15" ref={inputRef} onChange={HandleChange}></input>
            <div className="input-info d-flex pd-tb-10">
                <p className={passwordTestResult.hasEnglish ? "color-black" : ""}>영문포함 <img src={passwordTestResult.hasEnglish ? checkOn : checkOff} alt="" /></p>
                <p className={passwordTestResult.hasNumber ? "color-black" : ""}>숫자포함 <img src={passwordTestResult.hasNumber ? checkOn : checkOff} alt="" /></p>
                <p className={passwordTestResult.hasEight ? "color-black" : ""}>8~20자 이내 <img src={passwordTestResult.hasEight ? checkOn : checkOff} alt="" /></p>
            </div>
        </div>
        
        <div className="form-group mt-20 pd-side">
            <label htmlFor="">계정 비밀번호 확인</label>
            <input type="password" value={UserPassCorrect} id="PassCorrect" className="text mt-15" ref={inputRef} onChange={HandleChange}></input>
            
            <div className="input-info d-flex pd-tb-10">
                <p>비밀번호 일치 <img src="/images/check_off.png" alt=""/></p>
            </div>
        </div>
    </section>
        </div>
    )
}

export default EditProfile;