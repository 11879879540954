const hasEnglish = new RegExp("^(?=.*[a-z])");
const hasNumber = new RegExp("^(?=.*[0-9])");

export interface PasswordTestResult {
    message: string;
    isValid: boolean;
    hasEight: boolean;
    hasNumber: boolean;
    hasEnglish: boolean;
}

export const isPasswordValid = (password: string): PasswordTestResult => {
    const PasswordTestResult: PasswordTestResult = {
        message: "",
        isValid: true,
        hasEight: true,
        hasNumber: true,
        hasEnglish: true
    };

    if(!hasEnglish.test(password)) {
        PasswordTestResult.message = "Password must contain at least 1 english character";
        PasswordTestResult.isValid = false;
        PasswordTestResult.hasEnglish = false;
    }

    if(!hasNumber.test(password)) {
        PasswordTestResult.message = "Password must contain at least 1 number";
        PasswordTestResult.isValid = false;
        PasswordTestResult.hasNumber = false;
    }

    if(password.length < 8) {
        PasswordTestResult.message = "Password must be at least 8 characters";
        PasswordTestResult.isValid = false;
        PasswordTestResult.hasEight = false;
    }

    return PasswordTestResult;
}