export interface Deposit {
    DE_ID: number;
    USER_ID: number;
    VIRTUAL_ACCOUNT_NM: string;
    VIRUTAL_NUMBER: string;
    BANK_NM: string;
    BANK_NUMBER: string;
    CREATE_DT: string;
    HOLDING_AMOUNT: number;
    AVAIL_AMOUNT: number;
}

export interface DepositAction {
    type: string;
    payload: Deposit | null;
}

const DepositReducer = (state : Deposit | null = null, action: DepositAction) : Deposit | null => {
    switch (action.type) {
        case 'SET_DEPOSIT':
            return action.payload;
        default:
            return state;
    }
}

export default DepositReducer;