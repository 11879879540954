import { useLocation, useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../../utils/formatDate';

export default function Alarm() {
  const navigate = useNavigate();
  const location = useLocation();

  const { noticeList } = location.state;

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <header className='sub'>
        <button className='btn-prev' onClick={handleGoBack}>
          <span>이전</span>
        </button>
        <h1>알림</h1>
      </header>
      <div>
        {noticeList.map((notice: any) => (
          <div className={notice.IS_READ === 0 ? 'notice-list' : 'notice-list bg-gray'} key={notice.ALARM_ID}>
            <div>
              {notice.ALARM_TITLE} <span>{formatDateTime(notice.ALARM_DATE)}</span>
            </div>
            <p>{notice.ALARM_CONTENT}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
