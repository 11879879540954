import React, { FC, useState, useEffect } from "react";
import starPoint from "../../images/star_point.png";
import { GetThumbnail } from "../../utils/ImageHandler";
import defaultImage from "../../images/default.png";

interface ReviewPreviewProps {
    REVIEW_ID: number;
    KAKAO_NICK: string;
    REVIEW_TEXT: string;
    UPLOAD_DT: string;
    RATE: number;
}

const ReviewPreview: FC<ReviewPreviewProps> = (props) => {
    const [imagePath, setImagePath] = useState<string>("");

    useEffect(() => {
        GetThumbnail("TB_REVIEW", props.REVIEW_ID).then((res) => setImagePath(res));
    }, []);

    return (
        <div>
            <p>{props.KAKAO_NICK}</p>
            <div className="d-flex mb-5">
                <div className="star-wrap">
                    <img src={starPoint} alt="" style={{width: "14px"}}/>
                    <img src={starPoint} alt="" style={{width: "14px"}}/>
                    <img src={starPoint} alt="" style={{width: "14px"}}/>
                    <img src={starPoint} alt="" style={{width: "14px"}}/>
                    <img src={starPoint} alt="" style={{width: "14px"}}/>
                </div>
                <p className="fs-12 color-gray">{props.UPLOAD_DT.substring(0, 10)}</p>
            </div>
            <div className="d-flex">
                <p className="mr-10"><img src={imagePath ? imagePath : defaultImage} alt="" style={{width: "59px"}} /></p>
                <p>{props.REVIEW_TEXT}</p>
            </div>
        </div>
    );
};

export default ReviewPreview;