import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import arrowRightBK from "../../../images/arrow_right_bk.png";


const Inquiry = () => {
    const navigate = useNavigate();

    useEffect(() => {

    }, [])

    return (
        <div id="">
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
                <h1>문의사항</h1>
            </header>
            <section>
                <div className="d-flex space-between align-center pd-side pd-tb-15" onClick={() => navigate('/mybank/inquiry/service')}>
                    <div>
                        <p className="fs-15 medium">1:1 문의</p>
                    </div>
                    <a className="btn-detail"><img src={arrowRightBK} alt="" /></a>
                </div>
                <div className="d-flex space-between align-center pd-side pd-tb-15" onClick={() => navigate('/mybank/inquiry/faq')}>
                    <div>
                        <p className="fs-15 medium">자주 묻는 질문</p>
                    </div>
                    <a className="btn-detail"><img src={arrowRightBK} alt="" /></a>
                </div>
            </section>
        </div>
    )
}

export default Inquiry;