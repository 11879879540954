import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import more from '../../images/more.png';

import { GetThumbnail } from '../../utils/ImageHandler';

import defaultImage from '../../images/default.png';
import { addComma } from '../../utils/StringFormat';

export interface OrderPreviewProps {
  ORDER_ID: number;
  ORDER_STATUS: string;
  PRODUCT_ID: number;
  PAY_WITH: string;
  FARM_NM: string;
  PRODUCT_NM: string;
  QUANTITY: number;
  DISCOUNT_PRICE: number;
  ORIGINAL_AMOUNT: number;
  DELIVERY_AMOUNT: number;
  DISCOUNT_AMOUNT: number;
  TOTAL_AMOUNT: number;
  CREATE_DT: string;
}

const MyOrderPreview: FC<OrderPreviewProps> = (props) => {
  const navigate = useNavigate();

  const [imagePath, setImagePath] = useState<string>('');

  useEffect(() => {
    GetThumbnail('TB_ORDER_PRODUCT', props.PRODUCT_ID).then((res) => setImagePath(res));
  }, []);

  return (
    <div className='farm-detail pd-0'>
      <div className='order-list-title d-flex space-between'>
        <p className='bold'>{props.CREATE_DT.substring(0, 10)}</p>
        <p className='fs-12 lh-14'>
          <a onClick={() => navigate('/myshopping/order/detail', { state: { orderID: props.ORDER_ID } })}>
            주문상세 <img src={more} alt='' style={{ width: '14px' }} className='vertical-top' />
          </a>
        </p>
      </div>
      <div className='inner order-list'>
        <div className='product-list bd-0'>
          <p className='bold'>{props.ORDER_STATUS}</p>
          <div className='product-box pd-0 mt-10'>
            <div className='product-img'>
              <img src={imagePath ? imagePath : defaultImage} alt='' />
            </div>
            <div>
              <p className='fs-12'>{props.FARM_NM}</p>
              <p className='fs-15 medium'>{props.PRODUCT_NM}</p>
              <p className='order-price'>
                {addComma(props.DISCOUNT_PRICE * props.QUANTITY)}원<span>｜ 수량 {props.QUANTITY}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrderPreview;
