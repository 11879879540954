import React, { FC, useCallback, useEffect, useState } from "react";

import arrowUp from "../../../../images/arrow_up.png";
import arrowDown from "../../../../images/arrow_down.png";

export interface FAQPreviewProps {
    FAQ_TITLE: string;
    FAQ_CONTENT: string;
    isActive: boolean;
}

const FAQPreview: FC<FAQPreviewProps> = (props) => {
    const [isActive, setActive] = useState(false);

    const handleClick = () => {
        setActive(!isActive);
    }

    useEffect(() => {
        return () => {
        }

    }, []);
    return (
        <div className="bd-bottom">
            <div className="d-flex space-between align-center pd-22" onClick={handleClick}>
                <div>
                    <p className="medium">Q. {props.FAQ_TITLE}</p>
                </div>
                <a className="btn-detail"><img src={isActive ? arrowUp : arrowDown} alt="" /></a>
            </div>
            <div className="bg-rgray pd-side pd-tb-15 fs-12" style={{ display: isActive ? "block" : "none" }}>
                <p>{props.FAQ_CONTENT}</p>
            </div>
        </div >
    )
}

export default FAQPreview;