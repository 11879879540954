export interface Farm {
    ACCIDENTCOMMENT: string;
    ADDR1: string;
    ADDR2: string;
    CAREER : string;
    CREATE_DT: string;
    EMAIL: string;
    ESTABLISHED_YEAR: string;
    FARMER_ID: number;
    FARMER_NM: string;
    FARM_ID: number;
    FARM_NM: string;
    FEED: string;
    HELLO: string;
    HP: string;
    INTRODUCE_COMMENT: string;
    MOBILE: string;
    PREV_YEAR_GRADE: string;
    REGION: string;
    SIZE: string;
    TB_FARMcol: string;
    UPDATE_DT: string;
    USE_YN: string;
    YOUTUBE: string;
}

export interface FarmAction {
    type: string;
    payload: Farm[] | null;
}

export const FarmReducer = (state: Farm[] | null = null, action: FarmAction) : Farm[] | null => {
    switch (action.type) {
        case 'SET_FARM':
            return action.payload;
        default:
            return state;
    }
}