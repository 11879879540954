const kakao_url = 'https://kauth.kakao.com';
const kakao_user_info_api = 'https://kapi.kakao.com';

const client_id = process.env.REACT_APP_REST_API_KEY;
const logout_redirect_uri = process.env.REACT_APP_LOGOUT_REDIRECT_URI;

interface IKakaoTokenData {
  client_id: string;
  redirect_uri: string;
  code: string;
}

export const getKakaoToken = async (data: IKakaoTokenData) => {
  const { client_id, redirect_uri, code } = data;

  try {
    const response = await fetch(`${kakao_url}/oauth/token`, {
      method: 'POST',
      headers: { 'Content-type': 'application/x-www-form-urlencoded;charset=utf-8' },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: client_id,
        redirect_uri: redirect_uri,
        code: code,
      }),
    });
    const data = await response.json();

    return data.access_token;
  } catch (error) {
    console.log(error);
  }
};

export const getKakaoUserInfo = async (accessToken: string) => {
  try {
    const response = await fetch(`${kakao_user_info_api}/v2/user/me`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
  }
};

// export const kakaoLogout = async (accessToken: string) => {
//   try {
//     const response = await fetch(`${kakao_api}/v1/user/logout`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });
//     const data = await response.json();

//     return data;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const kakaoLogout = async () => {
  try {
    const response = await fetch(
      `${kakao_url}/oauth/logout?client_id=${client_id}&logout_redirect_uri=${logout_redirect_uri}`,
      {
        method: 'GET',
      }
    );

    return response.status;
  } catch (error) {
    console.log(error);
  }
};
