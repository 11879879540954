import React, { FC } from "react";
import imgMember from "../../../images/img_member.png";
import Youtube from "react-youtube";

interface FarmerStoryProps {
    FARM_ID: number;
    FARM_NM: string;
    FARMER_NM: string;
    HELLO: string;
    INTRODUCE_COMMENT: string;
    YOUTUBE: string;
}

const FarmerStory: FC<FarmerStoryProps> = (props) => {

    let opts = {
        height: '315',
        width: '100%',
    }

    return(
        <div className="farm-detail">
            <div className="d-flex">
                <p className="mr-10">
                    <img src={imgMember} alt="" style={{ width: "93px"}} />
                </p>
                <div>
                    <p>{props.FARMER_NM}</p>
                    <p>한우 마이스터</p>
                    <p>{props.HELLO}</p>
                </div>
            </div>
            <div className="intro mt-20">
                <p>{props.INTRODUCE_COMMENT}</p>
            </div>
            <div className="mt-20" >
                <Youtube opts={opts} videoId={props.YOUTUBE.substring(props.YOUTUBE.length -11)} />
            </div>
        </div>
    )

}

export default FarmerStory;