import React, { FC, useCallback, useEffect, useState } from 'react';

import arrowUp from '../../../images/arrow_up.png';
import arrowDown from '../../../images/arrow_down.png';

export interface NoticePreviewProps {
  NOTICE_TITLE: string;
  NOTICE_CONTENT: string;
  NOTICE_DATE: string;
  isActive: boolean;
}

const NoticePreview: FC<NoticePreviewProps> = (props) => {
  const [isActive, setActive] = useState(false);

  const handleClick = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className='bd-bottom'>
      <div className='d-flex space-between align-center pd-22' onClick={handleClick}>
        <div>
          <p className='fs-10 color-gray'>{props.NOTICE_DATE.substring(0, 10)}</p>
          <p className='mt-10'>{props.NOTICE_TITLE}</p>
        </div>
        <a className='btn-detail'>
          <img src={isActive ? arrowUp : arrowDown} alt='' />
        </a>
      </div>
      <div className='bg-rgray pd-side pd-tb-15 fs-12' style={{ display: isActive ? 'block' : 'none' }}>
        {props.NOTICE_CONTENT.replace(/\\n/g, '\n')
          .split('\n')
          .map((line, idx) => (
            <p key={idx}>
              {line}
              <br />
            </p>
          ))}
      </div>
    </div>
  );
};

export default NoticePreview;
