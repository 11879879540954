import React, { FC, useCallback, useEffect, useState } from "react";

import arrowUp from "../../../../images/arrow_up.png";
import arrowDown from "../../../../images/arrow_down.png";

export interface InquiryPreviewProps {
    INQUIRY_CATEGORY: string;
    INQUIRY_TITLE: string;
    INQUIRY_CONTENT: string;
    INQUIRY_DATE: string;
    isActive: boolean;
}

const InquiryPreview: FC<InquiryPreviewProps> = (props) => {
    const [isActive, setActive] = useState(false);

    const handleClick = () => {
        setActive(!isActive);
    }

    useEffect(() => {
        return () => {
        }

    }, []);
    return (
        <div className="bd-bottom">
            <div className="d-flex space-between align-center pd-22" onClick={handleClick}>
                <div>
                    <p className="fs-10 color-gray">2022-11-11</p>
                    <p className="mt-10">[{props.INQUIRY_CATEGORY}] {props.INQUIRY_TITLE}</p>
                </div>
                <a className="btn-detail"><img src={isActive ? arrowUp : arrowDown} alt="" /></a>
            </div>
            <div style={{ display: isActive ? "block" : "none" }}>
                <div className="bg-rgray pd-side pd-tb-15 fs-12 bd-bottom">
                    <p className="fs-12 bold color-gray">배송관련 문의</p>
                    <p className="mt-10 color-gray">{props.INQUIRY_CONTENT}</p>
                </div>
                <div className="bg-rgray pd-side pd-tb-15 fs-12">
                    <p className="bold">답변</p>
                    <p className="mt-10">내용내용내용내용<br />내용내용내용내용<br />내용내용내용내용</p>
                </div>
            </div>
        </div>
    )
}

export default InquiryPreview;