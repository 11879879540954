import React, { FC, useEffect } from 'react';
import emptyImage from '../../../images/img.png';
import star from '../../../images/star.png';
import { GetThumbnail } from '../../../utils/ImageHandler';
import defaultImage from '../../../images/default.png';
import { addComma } from '../../../utils/StringFormat';

interface StorePreviewProps {
  PRODUCT_ID: number;
  FARM_NM: string;
  PRODUCT_NM: string;
  DISCOUNT_PRICE: number;
  DISCOUNT_RATE: number;
  RATE: number;
}

const StorePreview: FC<StorePreviewProps> = (props) => {
  const [imagePath, setImagePath] = React.useState<string>('');
  const [reviewcount, setReviewCount] = React.useState<number>(0);

  const GetReviews = async () => {
    const res = await fetch(process.env.REACT_APP_SERVER_URL + `/reviews/${props.PRODUCT_ID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await res.json();
    setReviewCount(data.length);
  };

  useEffect(() => {
    GetThumbnail('TB_ORDER_PRODUCT', props.PRODUCT_ID).then((res) => setImagePath(res));
    GetReviews();
  }, []);

  return (
    <div className='flex-column'>
      <div>
        <img src={imagePath ? imagePath : defaultImage} alt='' />
      </div>
      <div className='text-left'>
        <p className='slide-title fs-14'>{props.FARM_NM}</p>
        <p className='color-black ellipsis-2'>{props.PRODUCT_NM}</p>
        <p className='color-black bold'>
          <strong className='fs-15 color-red'>{props.DISCOUNT_RATE}%</strong>
          <span className='fs-15 ml-10'>{addComma(props.DISCOUNT_PRICE)}원</span>
        </p>
        <p>
          <span className='ico-star'>
            <img src={star} alt='' />
          </span>
          <span>{props.RATE}</span>｜<span>{addComma(reviewcount)}건</span>
        </p>
      </div>
    </div>
  );
};

export default StorePreview;
