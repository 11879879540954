import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'jquery-ui/ui/widgets/tabs.js';
import { LineChart } from "recharts";
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const $ = require("jquery");

const MarketPrice = () => {
    const navigate = useNavigate();
    const [isRender, setIsRender] = useState<boolean>(false);
    const [buyMalePrices, setBuyMalePrices] = useState<any[]>([]);
    const [buyFemalePrices, setBuyFemalePrices] = useState<any[]>([]);
    const [sellMalePrices, setSellMalePrices] = useState<any[]>([]);
    const [sellFemalePrices, setSellFemalePrices] = useState<any[]>([]);

    const [malePrice, setMalePrice] = useState<number>(0);
    const [femalePrice, setFemalePrice] = useState<number>(0);

    const [maleDiff, setMaleDiff] = useState<number>(0);
    const [femaleDiff, setFemaleDiff] = useState<number>(0);
    const [malePercent, setMalePercent] = useState<number>(0);
    const [femalePercent, setFemalePercent] = useState<number>(0);

    const [buyorsell, setBuyOrSell] = useState<string>("BUY");
    const [yearormonthorweek, setYearOrMonthOrWeek] = useState<string>("YEAR");

    const MergeTwoArray = (a: any[], b: any[]) => {
        interface oneBlock {
            malePrice: number;
            femalePrice: number;
            date: string;
        }

        let result: oneBlock[] = [];

        for (let i = 0; i < a.length; i++) {
            if (buyorsell === "BUY") {
                result.push({
                    malePrice: a[i].BUY_PRICE,
                    femalePrice: b[i].BUY_PRICE,
                    date: a[i].CREATE_DT.substring(0, 10)
                })
            }
            else {
                result.push({
                    malePrice: a[i].SELL_PRICE,
                    femalePrice: b[i].SELL_PRICE,
                    date: a[i].CREATE_DT.substring(0, 10)
                })
            }
        }

        return result;
    }

    const GetPrices = async (trade: string, sex: string, date: string) => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/market/${trade}/${sex}/${date}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        return data;
    }

    useEffect(() => {
        $(function() {
            $("#condition-tabs, #period-tab-1, #period-tab-2").tabs();
        });
        
        let today = '2023-02-09T00:00:00.000Z';

        GetPrices('BUY', "암", today).then((data) => {
            setBuyFemalePrices(data);
            setFemalePrice(data[0].BUY_PRICE);
            setFemaleDiff(data[0].BUY_PRICE - data[3].BUY_PRICE);
            setFemalePercent((data[0].BUY_PRICE - data[3].BUY_PRICE) / data[3].BUY_PRICE * 100);
            setIsRender(true)
        });
        GetPrices('BUY', "수", today).then((data) => {
            setBuyMalePrices(data);
            setMalePrice(data[0].BUY_PRICE);
            setMaleDiff(data[0].BUY_PRICE - data[3].BUY_PRICE);
            setMalePercent((data[0].BUY_PRICE - data[3].BUY_PRICE) / data[3].BUY_PRICE * 100);
            setIsRender(true)
        });
        GetPrices('SELL', "암", today).then((data) => {
            setSellFemalePrices(data);
        });
        GetPrices('SELL', "수", today).then((data) => {
            setSellMalePrices(data);
        });

    }, []);

    useEffect(() => {
        if(isRender){
            RenderPrice();
        }
    }, [buyorsell, yearormonthorweek]);

    const RenderPrice = () => {
        let malePrice = 0;
        let femalePrice = 0;
        let maleDiff = 0;
        let femaleDiff = 0;
        let malePercent = 0;
        let femalePercent = 0;
        
        if (buyorsell === "BUY") {
            femalePrice = buyFemalePrices[0].BUY_PRICE;
            malePrice = buyMalePrices[0].BUY_PRICE;
            if (yearormonthorweek === "YEAR") {
                maleDiff = buyMalePrices[0].BUY_PRICE - buyMalePrices[3].BUY_PRICE;
                femaleDiff = buyFemalePrices[0].BUY_PRICE - buyFemalePrices[3].BUY_PRICE;
                malePercent = maleDiff / buyMalePrices[3].BUY_PRICE * 100;
                femalePercent = femaleDiff / buyFemalePrices[3].BUY_PRICE * 100;
            } else if (yearormonthorweek === "MONTH") {
                maleDiff = buyMalePrices[0].BUY_PRICE - buyMalePrices[2].BUY_PRICE;
                femaleDiff = buyFemalePrices[0].BUY_PRICE - buyFemalePrices[2].BUY_PRICE;
                malePercent = maleDiff / buyMalePrices[2].BUY_PRICE * 100;
                femalePercent = femaleDiff / buyFemalePrices[2].BUY_PRICE * 100;
            } else if (yearormonthorweek === "WEEK") {
                maleDiff = buyMalePrices[0].BUY_PRICE - buyMalePrices[1].BUY_PRICE;
                femaleDiff = buyFemalePrices[0].BUY_PRICE - buyFemalePrices[1].BUY_PRICE;
                malePercent = maleDiff / buyMalePrices[1].BUY_PRICE * 100;
                femalePercent = femaleDiff / buyFemalePrices[1].BUY_PRICE * 100;
            }
        } else if (buyorsell === "SELL") {
            femalePrice = sellFemalePrices[0].SELL_PRICE;
            malePrice = sellMalePrices[0].SELL_PRICE;
            if (yearormonthorweek === "YEAR") {
                maleDiff = sellMalePrices[0].SELL_PRICE - sellMalePrices[3].SELL_PRICE;
                femaleDiff = sellFemalePrices[0].SELL_PRICE - sellFemalePrices[3].SELL_PRICE;
                malePercent = maleDiff / sellMalePrices[3].SELL_PRICE * 100;
                femalePercent = femaleDiff / sellFemalePrices[3].SELL_PRICE * 100;
            } else if (yearormonthorweek === "MONTH") {
                maleDiff = sellMalePrices[0].SELL_PRICE - sellMalePrices[2].SELL_PRICE;
                femaleDiff = sellFemalePrices[0].SELL_PRICE - sellFemalePrices[2].SELL_PRICE;
                malePercent = maleDiff / sellMalePrices[2].SELL_PRICE * 100;
                femalePercent = femaleDiff / sellFemalePrices[2].SELL_PRICE * 100;
            } else if (yearormonthorweek === "WEEK") {
                maleDiff = sellMalePrices[0].SELL_PRICE - sellMalePrices[1].SELL_PRICE;
                femaleDiff = sellFemalePrices[0].SELL_PRICE - sellFemalePrices[1].SELL_PRICE;
                malePercent = maleDiff / sellMalePrices[1].SELL_PRICE * 100;
                femalePercent = femaleDiff / sellFemalePrices[1].SELL_PRICE * 100;
            }
        }

        setMalePrice(malePrice);
        setFemalePrice(femalePrice);
        setMaleDiff(maleDiff);
        setFemaleDiff(femaleDiff);
        setMalePercent(malePercent);
        setFemalePercent(femalePercent);
    }

    const priceBlock = (period : string) => {

        return(
            <div className="period-wrap">
                <div className="period-box">
                    <p className="bar">수 돼지</p>
                    <p className="fs-12 color-gray">{period}</p>
                    <p className="fs-20 bold">{malePrice}만원</p>
                    <p className={maleDiff > 0 ? "fs-12 color-red":"fs-12 color-blue"}>
                    {maleDiff > 0 ? "▲" : "▼"}{maleDiff}만원 ({malePercent.toFixed(1)}%)</p>
                </div>
                <div className="period-box">
                    <p className="bar">암 돼지</p>
                    <p className="fs-12 color-gray">{period}</p>
                    <p className="fs-20 bold">{femalePrice}만원</p>
                    <p className={femaleDiff > 0 ? "fs-12 color-red":"fs-12 color-blue"}>
                    {femaleDiff > 0 ? "▲" : "▼"}{femaleDiff}만원 ({femalePercent.toFixed(1)}%)</p>
                </div>
            </div>
        )
    }

    const priceGraph = (male: any[], female: any[]) => {

        return(
            <LineChart width={410} height={420} data={MergeTwoArray(male, female)}
            margin={{ top: 70, right: 0, left: 20, bottom: 0 }}>
                <Line name="수 돼지" type="monotone" dataKey="malePrice" stroke="#8884d8" />
                <Line name="암 돼지" type="monotone" dataKey="femalePrice" stroke="#82ca9d" />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="date" reversed={true}/>
                <YAxis domain={['datamin', 'auto']} orientation="right"/>
                <Legend />
            </LineChart>
        )
    }
        


    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>평균 경매 시세</h1>
            </header>
        <section>
            <div id="condition-tabs" className="tabs condition-tabs">
                <ul>
                    <li><a href="#condition-1" onClick={()=>setBuyOrSell("BUY")}>아기돼지 살 때</a></li>
                    <li><a href="#condition-2" onClick={()=>setBuyOrSell("SELL")}>큰돼지 팔 때</a></li>
                </ul>
                <div id="condition-1">
                    <div id="period-tab-1" className="tabs period-tabs">
                        <ul>
                            <li><a href="#period-1" onClick={()=>setYearOrMonthOrWeek("YEAR")}>년간</a></li>
                            <li><a href="#period-2" onClick={()=>setYearOrMonthOrWeek("MONTH")}>월별</a></li>
                            <li><a href="#period-3" onClick={()=>setYearOrMonthOrWeek("WEEK")}>주별</a></li>
                        </ul>
                        <div id="period-1">
                            {priceBlock("전년 대비")}
                        </div>
                        <div id="period-2">
                            {priceBlock("전월 대비")}
                        </div>
                        <div id="period-3">
                            {priceBlock("전주 대비")}
                        </div>
                    </div>
                    {priceGraph(buyMalePrices, buyFemalePrices)}
                </div>
                <div id="condition-2">
                    <div id="period-tab-2" className="tabs period-tabs">
                        <ul>
                            <li><a href="#period-4" onClick={()=>setYearOrMonthOrWeek("YEAR")}>년간</a></li>
                            <li><a href="#period-5" onClick={()=>setYearOrMonthOrWeek("MONTH")}>월별</a></li>
                            <li><a href="#period-6" onClick={()=>setYearOrMonthOrWeek("WEEK")}>주별</a></li>
                        </ul>
                        <div id="period-4">
                            {priceBlock("전년 대비")}
                        </div>
                        <div id="period-5">
                            {priceBlock("전월 대비")}
                        </div>
                        <div id="period-6">
                            {priceBlock("전주 대비")}
                        </div>
                    </div>
                    {priceGraph(sellMalePrices, sellFemalePrices)}
                </div>
            </div>
        </section>
        </div>
    )
}

export default MarketPrice;