import React, { useState, useEffect } from 'react';
import { GetImages } from '../../../utils/ImageHandler';
import { useNavigate, useLocation } from 'react-router-dom';
import bluelabel from '../../../images/bluelabel.png';
import arrowDown from '../../../images/arrow_down.png';
import healthCheck from '../../../images/health_check.png';
import healthCheck2 from '../../../images/health_check2.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import arrowRightBK from '../../../images/arrow_right_bk.png';
import { SeperateTrace, addComma } from '../../../utils/StringFormat';
import { useDispatch } from 'react-redux';

const PigDetail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [imageInfo, setImageInfo] = useState<any>([]);
  const [pigInfo, setPigInfo] = useState<any>();
  const maxAge = 24;

  useEffect(() => {
    window.scrollTo(0, 0);

    GetImages('TB_PIG', location.state?.pigID).then((res) => setImageInfo(res));
    GetPigInfo();
  }, []);

  const GetPigInfo = async () => {
    const res = await fetch(process.env.REACT_APP_SERVER_URL + `/pig/${location.state?.pigID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await res.json();
    setPigInfo(data[0]);
  };

  const SelectNavigate = () => {
    dispatch({ type: 'SELECT_FUND', payload: { fundingID: pigInfo?.FUNDING_ID } });
    navigate('/fund/detail/fund');
  };

  return (
    <div>
      <header className='sub'>
        <a className='btn-prev' onClick={() => navigate(-1)}>
          <span>이전</span>
        </a>
      </header>
      <section>
        <div className='farm-detail'>
          <p>
            <img src={bluelabel} alt='' style={{ width: '113px' }} />
          </p>
          <h2 className='mt-20 mb-0'>{pigInfo?.PIG_NM}</h2>
          <p className='fs-16'>{addComma(pigInfo?.TARGET_AMOUNT)}원</p>
          <div>
            <div className='d-flex'>
              <p className='fs-18 bold' style={{ width: '15%' }}>
                {pigInfo?.PIG_AGE}개월
              </p>
              <div className='percent' style={{ width: '80%' }}>
                <div className='bar-bg'>
                  <div className='bar' style={{ width: `${(pigInfo?.PIG_AGE / maxAge) * 100}%` }}></div>
                </div>
              </div>
              {/* <a style={{width: "10%"}} className="text-center"><img src={arrowDown} alt="" style={{width: "14px"}} /></a> */}
            </div>
            <div className='d-flex space-between'>
              <p>
                <strong className='fs-18'>{pigInfo?.PIG_WEIGHT}kg</strong>{' '}
                <span className='fs-12 color-gray'>체중</span>
              </p>
              {/* <a   className="btn-more-gray">더보기</a> */}
            </div>
          </div>
          <div className='bg-gray pd-15'>
            <dl className='space-between fs-15 color-black'>
              <dt className='bold color-black'>축산물이력번호</dt>
              <dd className='medium'>{SeperateTrace(pigInfo?.TRACE_ID)}</dd>
            </dl>
          </div>
        </div>
        <div className='farm-detail'>
          <div className='d-flex space-between align-center'>
            <div className='d-flex space-between align-center'>
              <div className='list-title'>
                <p className='fs-15 bold'>{pigInfo?.FARM_NM}</p>
                <p className='fs-12 color-gray'>{pigInfo?.ADDR1}</p>
              </div>
            </div>
            <div>
              <a className='btn-more-gray' onClick={() => navigate('/fund/detail/farm')}>
                더보기
              </a>
            </div>
          </div>
        </div>
        <div className='farm-detail'>
          <div className='checkup-box'>
            <h3>건강검진</h3>
            <dl>
              <dt>구제역검사</dt>
              {pigInfo?.CHECK_DISEASE === 0 ? (
                <dd>미확인</dd>
              ) : (
                <dd>
                  <img src={healthCheck} alt='' style={{ width: '19px' }} />
                </dd>
              )}
              <dt>블루셀라 검사</dt>
              {pigInfo?.CHECK_BRUCELLA === 0 ? (
                <dd>미확인</dd>
              ) : (
                <dd>
                  <img src={healthCheck} alt='' style={{ width: '19px' }} />
                </dd>
              )}
              <dt>질병검사</dt>
              {pigInfo?.CHECK_ORDINARY === 0 ? (
                <dd>미확인</dd>
              ) : (
                <dd>
                  <img src={healthCheck} alt='' style={{ width: '19px' }} />
                </dd>
              )}
              <dt>부상</dt>
              <dd>{pigInfo?.IS_INJURY ? '치료중' : '이상 없어요!'}</dd>
              <dt>부축</dt>
              <dd>{pigInfo?.IS_SUPPORT ? '치료중' : '이상 없어요!'}</dd>
              <dt>기타</dt>
              <dd>{pigInfo ? '이상 없어요!' : '치료중'}</dd>
            </dl>
            <div className='ultrasound'>
              <dl>
                <dt>초음파검사</dt>
                {pigInfo?.CHECK_SOUND === 0 ? (
                  <dd>미확인</dd>
                ) : (
                  <dd>
                    <img src={healthCheck2} alt='' style={{ width: '19px' }} />
                  </dd>
                )}
              </dl>
            </div>
          </div>
          <Swiper
            className='headerSwiper'
            modules={[Navigation, Pagination]}
            pagination={{
              el: '.headerSwiper .swiper-pagination',
              type: 'fraction',
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            {imageInfo.map((image: any) => (
              <SwiperSlide className='swiper-slide' key={image.IMG_ID}>
                <img src={process.env.REACT_APP_SERVER_URL + image?.SERVER_IMG_PATH} alt='' />
              </SwiperSlide>
            ))}
            <div className='swiper-pagination rb-15'></div>
          </Swiper>
        </div>

        <div className='d-flex space-between pd-22' onClick={SelectNavigate}>
          <p className='fs-18 bold'>펀딩내역</p>
          <a className='btn-detail'>
            {' '}
            <img src={arrowRightBK} alt='' />{' '}
          </a>
        </div>
      </section>
    </div>
  );
};

export default PigDetail;
