import { useState } from 'react';
import '../../css/monitor/Speaker.css';
import axios from 'axios';

interface SpeakerProps {
  useSpeaker: boolean;
}

export default function Speaker(props: SpeakerProps) {
  const [speakerState, setSpeakerState] = useState<number>(0);

  const handleSpeakerClick = (state: number) => {
    if (speakerState === state) {
      setSpeakerState(0);
    } else {
      setSpeakerState(state);
    }
  };

  const handleVoice = (state: number) => {
    if (state) {
      handlePlay(state);
    }
  };

  const handlePlay = async (state: number) => {
    const url = process.env.REACT_APP_SERVER_URL + `/speaker/${state}`;
    try {
      const response = await axios.get(url);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='speakerClass'>
        <div className='speakerText' onClick={() => handleSpeakerClick(1)}>
          <div className='speakerIcon'>
            <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='currentColor' viewBox='0 0 15 15'>
              <path d='M11.536 14.01A8.47 8.47 0 0 0 14.026 8a8.47 8.47 0 0 0-2.49-6.01l-.708.707A7.48 7.48 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303z' />
              <path d='M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.48 5.48 0 0 1 11.025 8a5.48 5.48 0 0 1-1.61 3.89z' />
              <path d='M8.707 11.182A4.5 4.5 0 0 0 10.025 8a4.5 4.5 0 0 0-1.318-3.182L8 5.525A3.5 3.5 0 0 1 9.025 8 3.5 3.5 0 0 1 8 10.475zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06' />
            </svg>
          </div>
          스피커
        </div>
        <div className='speakerDivider' />
        <div className='speakerText' onClick={() => handleSpeakerClick(2)}>
          <div className='speakerIcon'>
            <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='currentColor' viewBox='0 0 15 15'>
              <path d='M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069q0-.218-.02-.431c.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a1 1 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.74.74 0 0 0-.375.562c-.024.243.082.48.32.654a2 2 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595m7.173 3.876a.6.6 0 0 1-.098.21l-.044-.025c-.146-.09-.157-.175-.152-.223a.24.24 0 0 1 .117-.173c.049-.027.08-.021.113.012a.2.2 0 0 1 .064.199m-8.999-.65a.5.5 0 1 1-.276-.96A7.6 7.6 0 0 1 7.964 3.5c.763 0 1.497.11 2.18.315a.5.5 0 1 1-.287.958A6.6 6.6 0 0 0 7.964 4.5c-.64 0-1.255.09-1.826.254ZM5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0' />
            </svg>
          </div>
          특수사료주기
        </div>
      </div>
      {speakerState === 1 && (
        <div className='versionClass'>
          <div className='versionText' onClick={() => handleVoice(1)}>
            안녕~
          </div>
          <div className='versionText' onClick={() => handleVoice(2)}>
            잘자~
          </div>
          <div className='versionText' onClick={() => handleVoice(3)}>
            맛있게 먹어~
          </div>
        </div>
      )}
      {speakerState === 2 && (
        <div className='versionClass2'>
          <div className='versionText2' onClick={() => handleVoice(4)}>
            특수사료를 주세요~
          </div>
        </div>
      )}
    </>
  );
}
