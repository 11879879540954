import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import starPoint from "../../images/star_point.png";
import startPointHalf from "../../images/star_point_half.png";
import ReviewPreview from "./ReivewPreview";

const ReviewList = () => {
    const navigate = useNavigate();
    let arr: Array<any> = [];
    const [reviews, setReviews] = useState(arr);
    const productID = useSelector((state: any) => state.shopSelect);
    const [productInfo, setProductInfo] = useState<any>();

    const GetInfo = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/shop/detail/${productID.productID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        setProductInfo(data[0]);
    }

    const GetReview = async () => {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/shop/review/${productID.productID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json();
        setReviews(data);
    }

    const scrollFix = () => {
        var lastScrollTop = 0;
        var delta = 5;
        var fixBox: HTMLDivElement = document.querySelector('.top')!;
        var fixBoxHeight = fixBox?.offsetHeight;
        var didScroll: boolean;
        
        
        //스크롤 이벤트 
        window.onscroll = function(e) {
            didScroll = true;
        };

        //0.25초마다 스크롤 여부 체크하여 스크롤 중이면 hasScrolled() 호출
        setInterval(function(){
            if(didScroll){
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        function hasScrolled(){
            var nowScrollTop = window.scrollY; //얼마나 스크롤 했는지 반환
            if(Math.abs(lastScrollTop - nowScrollTop) <= delta){
                return;
            }
            if(nowScrollTop > lastScrollTop && nowScrollTop > fixBoxHeight){
                //Scroll down
                fixBox?.classList.add('scroll-top');
            }
            else if(nowScrollTop < delta){
                //Scroll up
                fixBox?.classList.remove('scroll-top');
            }
           
            lastScrollTop = nowScrollTop;
        }
    }

    useEffect(() => {
        GetInfo();
        GetReview();
        scrollFix();
    
      return () => {
      }
    }, [])
    

    return (
        <div>
            <header className="sub shopsub">
                <div className="top">
                    <a className="btn-prev" onClick={()=>navigate(-1)}><span >이전</span></a>
                    <h1>{productInfo?.PRODUCT_NM}</h1>
                    <a href="#" className="btn-cart"><span>장바구니</span></a>
                </div>
            </header>
            
            <section>
                <div>
                    <div className="point-wrap pt-20">
                        <div className=" ">
                            <div>
                                <img src={starPoint} alt="" />
                                <img src={starPoint} alt="" />
                                <img src={starPoint} alt="" />
                                <img src={starPoint} alt="" />
                                <img src={startPointHalf} alt="" />
                                <p className="fs-18 bold">{productInfo?.RATE}<span className="color-gray">/ 5</span> <span className="fs-14 color-gray medium">({reviews.length})</span></p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="scroll-tab">
                        <div id="fixedMenu" className="fixed-menu filter-menu bd-top">
                            <ul>
                                {/* <li><a href="#smenu01" className="smenu01 active">베스트순</a></li> */}
                                <li><a className="smenu01 active">베스트순</a></li>
                                <li><a className="smenu02">최신순</a></li>
                                <li><a className="smenu03">사진리뷰순</a></li>
                            </ul>
                        </div>
                        
                        <div id="smenu01" className="farm-detail bd-0">
                            <div className="review-wrap review">
                                {reviews.map((review: any) => (
                                    <ReviewPreview {...review} key={review.REVIEW_ID}/>
                                ))}
                            </div>
                        </div>
                        
                        <div id="smenu02" className="farm-detail bd-0">
                            
                        </div>
                        <div id="smenu03" className="farm-detail bd-0">
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default ReviewList;