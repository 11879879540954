import { useEffect } from 'react';
import 'jquery-ui/ui/widgets/tabs.js';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Fund.module.css';
import FundView from '../../components/fund/views/FundView';
import FarmView from '../../components/fund/views/FarmView';

const $ = require('jquery');

interface FundProps {
  setActiveLink: (link: string) => void;
  setIsActive: (active: boolean) => void;
}

export default function Fund(props: FundProps) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    props.setActiveLink('menu2');
    props.setIsActive(true);

    $(function () {
      $('#sub-tabs').tabs();
    });

    return () => {
      props.setIsActive(false);
    };
  }, []);

  return (
    <div>
      <header className={styles.header}>
        <div>
          <button className={styles.dibs} onClick={() => navigate('/fund/wishlist')}>
            <img src='./images/ico_keepon.png' alt='찜목록' />
          </button>
        </div>
        <h1 className='fs-20'>펀딩</h1>
      </header>
      <section>
        <div className='main-wrap'>
          <div id='sub-tabs' className='tabs sub-tabs'>
            <ul>
              <li>
                <a href='#subtab-1'>상품</a>
              </li>
              <li>
                <a href='#subtab-2'>농장</a>
              </li>
            </ul>
            <FundView category={location.state?.category || 'all'} />
            <FarmView />
          </div>
        </div>
      </section>
    </div>
  );
}
