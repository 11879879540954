import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetThumbnail } from '../../../../utils/ImageHandler';
import { addComma } from '../../../../utils/StringFormat';
import { handleFundRate } from '../../../../utils/PercentCalculator';
import styles from './FundingPreview.module.css';
import { useCookies } from 'react-cookie';

interface FundingPreviewProps {
  ID: number;
  TARGET_AMOUNT: number;
  CURRENT_AMOUNT: number;
  MIN_AMOUNT: number;
  PIG_AGE: number;
  PIG_ID: number;
}

interface IWishList {
  WISHLIST_ID: number;
  USER_ID: number;
  FUNDING_ID: number;
}

export default function FundingPreview({
  ID,
  PIG_ID,
  MIN_AMOUNT,
  PIG_AGE,
  TARGET_AMOUNT,
  CURRENT_AMOUNT,
}: FundingPreviewProps) {
  const [cookies] = useCookies(['wishList']);
  const [imgPath, setImgPath] = useState('');
  const [dibs, setDibs] = useState(false);

  const navigate = useNavigate();

  const handleFundDetail = () => {
    navigate('/fund/detail/fund', { state: { id: ID, dibs } });
  };

  useEffect(() => {
    GetThumbnail('TB_PIG', PIG_ID).then((response) => setImgPath(response));
    cookies.wishList?.some((item: IWishList) => item.FUNDING_ID === ID) ? setDibs(true) : setDibs(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PIG_ID]);

  return (
    <div className='flex-column' onClick={handleFundDetail}>
      <div>
        <img className={styles.thumbnail} src={imgPath ? imgPath : defaultImage} alt='돼지 이미지' />
      </div>
      <div className='text-left'>
        <p className='slide-title'>{handleFundRate(TARGET_AMOUNT, CURRENT_AMOUNT).toFixed(1)}% 달성</p>
        <div className='progress'>
          <div className='progress-bar' style={{ width: `${handleFundRate(TARGET_AMOUNT, CURRENT_AMOUNT)}%` }}></div>
        </div>
        <p>최소금액 {addComma(MIN_AMOUNT)}원</p>
        <p>{PIG_AGE}개월</p>
      </div>
    </div>
  );
}

const defaultImage = '/images/default.png';
