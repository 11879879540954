import React from "react";
import { useNavigate } from "react-router-dom";

const InqueryNotice = () => {
    const navigate = useNavigate();

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={()=>navigate(-1)}><span>이전</span></a>
                <h1>상품 문의</h1>
            </header>
            
            <section className="one-page">
                <div>
                    <div className="about-query">
                        <div className="mb-30">
                            <h2 className="fs-14 mb-10">상품문의 작성 전 확인해 주세요!</h2>
                            <p> 답변은 영업일 기준 2~3일 소요됩니다. </p>
                            <p> 해당 게시판의 성격과 다른 글은 사전동의 없이 담당 게시판으로 이동될 수 있습니다.</p>
                            <p> 배송관련, 주문(취소/교환/환불)관련 문의 및 요청사항은 스토어 내 1:1 문의에 남겨주세요.</p>
                        </div>
                        <div>
                            <h2 className="fs-14 mb-10">주문취소</h2>
                            <p> 배송 단계별로 주문취소 방법이 상이합니다. <br />
                            [입금확인] 단계 : [스토어 &gt; 마이페이지]에서 직접 취소 가능 <br />
                            [입금확인] 이후 단계 : 상품 문의 &gt; 1:1로 문의</p>
                            <p> 생산이 시작된 [상품 준비중] 이후에는 취소가 제한되는 점 고객님의 양해 부탁드립니다.</p>
                            <p> 주문상품의 부분 취소는 불가능합니다. 전체 주문 취소 후 재구매 해주세요.</p>
                        </div>
                    </div>
                    
                    <div>
                        <a className="btn btn-pink mb-20" onClick={()=>navigate("/inquery")}>상품 문의 쓰기</a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default InqueryNotice;