import { useEffect } from 'react';
import { getKakaoToken, getKakaoUserInfo } from '../../apis/kakao';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const client_id = process.env.REACT_APP_REST_API_KEY;
const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
const code = new URL(window.location.href).searchParams.get('code');

const server_url = process.env.REACT_APP_SERVER_URL;

export default function Oauth() {
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const navigate = useNavigate();

  const handleKakaoUserInfo = async (userInfo: any) => {
    const {
      id,
      properties: { nickname },
    } = userInfo;

    try {
      const response = await fetch(`${server_url}/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          kakao_id: id,
          kakao_nick: nickname,
        }),
      });
      const data = await response.json();
      setCookie('user', { id: data[0].ID, kakao_id: id, nickname });

      navigate('/home');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (client_id && redirect_uri && code) {
      getKakaoToken({ client_id, redirect_uri, code }).then(getKakaoUserInfo).then(handleKakaoUserInfo);
    }
  }, []);

  return <></>;
}
