import { useEffect, useState } from 'react';
import Keypad from '../../Keypad';
import ReactModal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { addComma } from '../../../utils/StringFormat';
import styles from './FundingProcess.module.css';
import { handleFundRate } from '../../../utils/PercentCalculator';
import { fetchWithErrorHandling } from '../../../utils/fetchWithErrorHandling';
import { useCookies } from 'react-cookie';

export default function FundingProcess() {
  const [number, setNumber] = useState('');
  const [allowNext, setAllowNext] = useState(false);
  const [showOverflowModal, setShowOverflowModal] = useState(false);
  const [deposit, setDeposit] = useState({ HOLDING_AMOUNT: 0, VIRTUAL_ACCOUNT_NM: '', VIRTUAL_NUMBER: '' });
  const [showModal, setShowModal] = useState(false);
  const [showLawModal, setShowLawModal] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(1);
  const [showTermDetailModal, setShowTermDetailModal] = useState(false);
  const [showFinalCheckModal, setShowFinalCheckModal] = useState(false);
  const [termContent, setTermContent] = useState('');

  const [cookies] = useCookies(['user']);

  const location = useLocation();
  const navigate = useNavigate();

  const { fundInfo } = location.state;
  const fundRate = handleFundRate(fundInfo.TARGET_AMOUNT, fundInfo.CURRENT_AMOUNT);

  const handleNumberClick = (num: number) => {
    setNumber(number + num.toString());
  };

  const handleMaxClick = () => {
    setNumber('6');
  };

  const handleClearClick = () => {
    setNumber('');
  };

  const keypadProps = {
    onNumberClick: handleNumberClick,
    onMaxClick: handleMaxClick,
    onClearClick: handleClearClick,
    showMax: true,
  };

  const handleLawModal = () => {
    setShowLawModal(!showLawModal);
  };

  const getDeposit = async () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userID: cookies.user.id,
      }),
    };

    const data = await fetchWithErrorHandling(`/deposit`, options);
    setDeposit(data[0]);
  };

  const GetTermContent = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = await fetchWithErrorHandling(`/term/${selectedTerm}`, options);
    setTermContent(data[0].CONTENT);
  };

  useEffect(() => {
    if (number.length > 0) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }

    if (Number(number) * fundInfo.MIN_AMOUNT > fundInfo.TARGET_AMOUNT) {
      setShowOverflowModal(true);
      setTimeout(() => {
        setShowOverflowModal(false);
        setAllowNext(false);
        setNumber('');
      }, 2000);
    }

    // if(Number(number)*fund?.MIN_AMOUNT > deposit?.HOLDING_AMOUNT){
    //     setShowModal(true);
    //     setTimeout(() => {
    //         setShowModal(false);
    //         setAllowNext(false);
    //         setNumber("");
    //     }, 2000);
    // }

    // if (!deposit?.HOLDING_AMOUNT){
    //     setAllowNext(false);
    // }

    // return () => {
    //   dispatch({
    //     type: 'SET_BID',
    //     payload: {
    //       PIG_NM: fund?.PIG_NM,
    //       FUNDING_ID: fund?.FUNDING_ID,
    //       BIDDING_AMOUNT: Number(number) * fund?.MIN_AMOUNT,
    //       BIDDING_COUNT: Number(number),
    //     },
    //   });
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  useEffect(() => {
    getDeposit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetTermContent();
  }, [selectedTerm]);

  // const VirtualNumberRender = () => {
  //   let virtualNumber = deposit
  //     ? ``
  //     : '가상계좌 발급이 필요합니다';

  //   return (
  //
  //   );
  // };

  // const DepositBarrior = () => {
  //   return (
  //     <ReactModal
  //       isOpen={showModal}
  //       onRequestClose={() => setShowModal(false)}
  //       className='modal'
  //       overlayClassName='overlay'
  //       ariaHideApp={false}
  //     >
  //       <div className='balance-alert'>예치금을 충전해주세요!</div>
  //     </ReactModal>
  //   );
  // };

  // const OverflowBarrior = () => {
  //   return (
  //     <ReactModal
  //       isOpen={showOverflowModal}
  //       onRequestClose={() => setShowOverflowModal(false)}
  //       className='modal'
  //       overlayClassName='overlay'
  //       ariaHideApp={false}
  //     >
  //       <div className='balance-alert'>펀딩가능 금액을 초과하였습니다!</div>
  //     </ReactModal>
  //   );
  // };

  const OpenTermModal = (term: number) => {
    setSelectedTerm(term);
    setShowTermDetailModal(true);
  };

  const LawModal = () => {
    return (
      <ReactModal
        isOpen={showLawModal}
        onRequestClose={() => setShowLawModal(false)}
        className='modal'
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        overlayClassName={'Overlay'}
      >
        <div className='agreement'>
          <h1 className='fs-20'>펀딩하시려면 약관 동의가 필요해요</h1>
          <p>
            <span onClick={() => OpenTermModal(5)}>구매회원 이용약관</span>
          </p>
          <p>
            <span onClick={() => OpenTermModal(1)}>서비스 이용약관</span>
          </p>
          <p>
            <span onClick={() => OpenTermModal(2)}>개인정보 처리방침</span>
          </p>
          <p>
            <span onClick={() => OpenTermModal(6)}>사육 및 경매 등 절차</span>
          </p>
          <span className='btn btn-pink' onClick={() => setShowFinalCheckModal(true)}>
            동의하고 시작하기
          </span>
        </div>
      </ReactModal>
    );
  };

  const TermDetailModal = () => {
    return (
      <ReactModal
        isOpen={showTermDetailModal}
        onRequestClose={() => setShowTermDetailModal(false)}
        className='modal'
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        overlayClassName={'Overlay'}
      >
        <section className='join' style={{ height: '100vh', backgroundColor: '#fff' }}>
          <div className='h-100'>
            {selectedTerm === 1 ? <h1 className='fs-20'>서비스 이용약관</h1> : ''}
            {selectedTerm === 2 ? <h1 className='fs-20'>개인정보 처리방침</h1> : ''}
            {selectedTerm === 5 ? <h1 className='fs-20'>구매회원 이용약관</h1> : ''}
            {selectedTerm === 6 ? <h1 className='fs-20'>사육 및 경매 등 절차</h1> : ''}
            <div className='terms'>{termContent}</div>
            <div className='btn-area'>
              <div className='btn btn-pink' onClick={() => setShowTermDetailModal(false)}>
                확인
              </div>
            </div>
          </div>
        </section>
      </ReactModal>
    );
  };

  const FinalCheckModal = () => {
    return (
      <ReactModal
        isOpen={showFinalCheckModal}
        onRequestClose={() => setShowFinalCheckModal(false)}
        className='modal'
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        overlayClassName={'Overlay'}
      >
        <div className='finalCheck'>
          <h1 className='fs-20'>펀딩을 확정하시겠습니까?</h1>
          <div className='btn-container'>
            <span className='btn btn-gray' onClick={() => setShowFinalCheckModal(false)}>
              아니요
            </span>
            <span
              className='btn btn-pink'
              onClick={() => navigate('/fund/end', { state: { fundInfo, number, deposit } })}
            >
              네, 할게요
            </span>
          </div>
        </div>
      </ReactModal>
    );
  };

  return (
    <section>
      <header className={styles.header}>
        <button className={styles.back_btn} onClick={() => navigate(-1)}>
          <img src={arrowLeft} alt='왼쪽 화살표' />
        </button>
        <h1>{fundInfo.PIG_NM}</h1>
      </header>
      <main>
        <div className='d-flex flex-column space-between'>
          <div className='pd-22'>
            <div
              className='circle-progress'
              style={{
                background: `linear-gradient(to bottom, #585C65 ${100 - fundRate}%, #E76B84 ${fundRate}%)`,
              }}
            >
              <div>
                <img src={pig} alt='' />
              </div>
            </div>
            <p className='text-center fs-14 medium mt-15 mb-5'>펀딩가능 6C</p>
            <p className='text-center fs-16 bold'>1C {addComma(fundInfo?.MIN_AMOUNT)}원</p>
            {number.length > 0 ? (
              <p className='text-center fs-25 color-red mt-10'>
                {number}C {addComma(Number(number) * fundInfo.MIN_AMOUNT)}원
              </p>
            ) : (
              <p className='text-center fs-25 color-gray mt-10'>몇 C를 구매하시겠습니까?</p>
            )}
            <div className='balance'>
              <p>예치금잔액</p>
              <p>{addComma(deposit.HOLDING_AMOUNT)}원</p>
            </div>
            <p className='d-flex flex-center color-gray'>
              <span className='mr-5'>{`${deposit.VIRTUAL_ACCOUNT_NM} ${deposit.VIRTUAL_NUMBER}`}</span>
              <button className='d-inline-block'>
                <img src={icoCopy} alt='복사' style={{ width: '14px' }} />
              </button>
            </p>
            <Keypad {...keypadProps} />
          </div>
          <div className='btn-area' onClick={handleLawModal}>
            <div className={allowNext ? 'btn btn-pink' : 'btn btn-gray'}>펀딩하기</div>
          </div>
        </div>
      </main>
      {/* {DepositBarrior()} */}
      {/* {OverflowBarrior()} */}
      {LawModal()}
      {TermDetailModal()}
      {FinalCheckModal()}
    </section>
  );
}

const arrowLeft = '/images/arrow_left.png';
const pig = '/images/pig.png';
const icoCopy = '/images/ico_copy.png';
