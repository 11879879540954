import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetThumbnail } from '../../../../utils/ImageHandler';
import { formatDate } from '../../../../utils/formatDate';
import styles from './NewsPreview.module.css';

interface NewsPreviewProps {
  NEWS_ID: number;
  NEWS_TITLE: string;
  CREATE_DT: string;
}

export default function NewsPreview(props: NewsPreviewProps) {
  const navigate = useNavigate();
  const [imagePath, setImagePath] = useState('');

  const handleClick = () => {
    navigate('/news/detail', { state: { news_id: props.NEWS_ID, thumbnail: imagePath } });
  };

  useEffect(() => {
    GetThumbnail('TB_NEWS', props.NEWS_ID).then((res) => setImagePath(res));
  }, []);

  return (
    <div className='flex-column' onClick={handleClick}>
      <div>
        <img className={styles.thumbnail} src={imagePath ? imagePath : defaultImage} alt='' />
      </div>
      <div className='text-left'>
        <p className='slide-title fs-14 ellipsis-2'>{props.NEWS_TITLE}</p>
        <p>{formatDate(props.CREATE_DT)}</p>
      </div>
    </div>
  );
}

const defaultImage = '/images/default.png';
