const server_url = process.env.REACT_APP_SERVER_URL;
const img_server_url = process.env.REACT_APP_IMG_SERVER_URL;

export const GetThumbnail = async (table: string, id: number) => {
  const response = await fetch(`${server_url}/imageinfo/${table}/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  return data.length === 0 ? '' : `${img_server_url}/${data[0].SERVER_IMG_PATH}`;
};

export const GetImages = async (table: string, id: number) => {
  try {
    const response = await fetch(`${server_url}/imageinfo/${table}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    return (
      data.map((elem: any) => ({
        IMG_ID: elem.IMG_ID,
        SERVER_IMG_PATH: `${img_server_url}/${elem.SERVER_IMG_PATH}`,
      })) || []
    );
  } catch (error) {
    console.log(error);
  }

  // if (data.length === 0) return null;

  // data.sort((a: any, b: any) => {
  //   return a.IMAGE_ORDER - b.IMAGE_ORDER;
  // });

  // data.forEach((element: any) => {
  //   element.SERVER_IMG_PATH = process.env.REACT_APP_SERVER_URL + element.SERVER_IMG_PATH;
  // });
};
