import React, { useState, useEffect, useRef  } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ReactModal from "react-modal";

const InputAccountNumber = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state: any) => state.user);

    const inputRef = useRef<HTMLInputElement>(null);

    let [showModal, setShowModal] = useState<boolean>(false);
    const [buttonColor, setButtonColor] = useState<string>("btn btn-gray");

    const CheckBankAccount = () => {
        if (buttonColor.includes("btn-pink")) {
            navigate("/mybank/withdrawAccount/StandBy", {state: {bankName: location.state.bankName, accountNumber: inputRef.current?.value}});
        }
        else {
            setShowModal(true);
        }
    }

    const HandleChange = () => {
        if (inputRef.current?.value === "") {
            setButtonColor("btn btn-gray");
        } else {
            setButtonColor("btn btn-pink");
        }
    };

    useEffect(() => {
        
    }, [])

    return (
        <div>
            <header className="sub">
                <a className="btn-prev" onClick={() => navigate(-1)}><span>이전</span></a>
            </header>
            <section>
                <div className="pd-side mt-15">
                    <p className="fs-20 bold"><strong className="d-block" id="bankName">{location.state.bankName}은행</strong> 계좌번호를 입력해주세요</p>
                    <div className="mt-30">
                        <input type="number" placeholder="-없이 입력해주세요" ref={inputRef} onChange={HandleChange}/>
                    </div> 
                    <p className="mt-30"><a className={buttonColor} onClick={() => CheckBankAccount()}>확인</a></p>
                </div>
            </section>

            <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)} className="modal" overlayClassName="overlay" ariaHideApp={false}>

                <div className="background"></div>
                <div className="popup">
                    <div>
                        <p className="fs-20 bold">계좌번호를 확인해주세요.</p>
                        <p className="mt-15 medium">{user.KAKAO_NICK}님의 계좌가 맞는지 확인할 수 없어요.</p>
                    </div>
                    <div className="text-right">
                        <p><a className="fs-18 bold" onClick={() => setShowModal(false)}>확인</a></p>
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}

export default InputAccountNumber;